import React from 'react'
import Header from '../UI/Header'
import { Grid, Typography, TextField, Avatar, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { StyledButton } from '../UI/StyledButton'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
function stringAvatar(name) {
  return {
    children: `${name.charAt(0).toUpperCase()}`,
  }
}
const ProfiloUtente = () => {
  const theme = useTheme()

  return (
    <>
      <Header
        showHome
        header={
          <>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              justifyContent="start"
              alignContent="start"
            >
              <Typography
                sx={{ flexGrow: 1 }}
                xs={12}
                item
                alignItems="center"
                mb={'1rem'}
                color={theme.palette.black.main}
                variant="h1"
              >
                PROFILO UTENTE
              </Typography>
            </Grid>
          </>
        }
      ></Header>
      <Box component="form" noValidate ml="20em" sx={{ mt: 20, width: '75%' }}>
        <Grid
          xs={12}
          container
          padding="30px"
          spacing={{ xs: 2, md: 1 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          justifyItems="center"
        >
          <Grid item xs={6}>
            <Avatar
              sx={{
                mb: 4,
                width: 50,
                height: 50,
                bgcolor: '#235F7B',
                alignContent: 'center',
              }}
              {...stringAvatar(JSON.parse(localStorage.getItem('user')))}
            ></Avatar>
            <Typography fullWidth variant="h1" name="username" id="username">
              {JSON.parse(localStorage.getItem('user'))}
            </Typography>
            <StyledButton
              sx={{ margin: 'auto', marginTop: '2em' }}
              component={Link}
              to="/modifica-password"
            >
              <Typography variant="h5">MODIFICA PASSWORD</Typography>
            </StyledButton>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ProfiloUtente
