import { customAxios } from './customAxios'
export function postLogin(password, user,code,codeVerifier) {
  const config = {
    headers: {
      AuthToken: JSON.parse(localStorage.getItem('token')),
    },
  }

  const body = {
    password: password,
    userId: user,
    code: code,
    codeVerifier: codeVerifier,
  }
  const login = customAxios.post(
    process.env.REACT_APP_BACKEND_HOST + `/`+process.env.REACT_APP_PATH_NEW_PREFIX+`/login?`,

    body,
    config,
  )

  return login
}

