import { customAxios } from "./customAxios"
export function searchFilters(tipo,stato,dataChiusuraDa,dataChiusuraA,dataPraticaDa,dataPraticaA,pratiche90gg) {
    const config = {
        headers: {
            Authtoken: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json'
        }
    }

    const data = JSON.stringify({
        "tipoInserimento": tipo,
        "statoPratica": stato,
        "dataChiusuraDal": dataChiusuraDa,
        "dataChiusuraAl": dataChiusuraA,
        "dataPraticaDal": dataPraticaDa,
        "dataPraticaAl": dataPraticaA,
        "flag": pratiche90gg
        

    })
    const searchRequest = customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/"+process.env.REACT_APP_PATH_NEW_PREFIX+"/searchFilter", data, config)
    return searchRequest
}