import React, { useState, useContext, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getListaUtenzeAssegnatari } from '../services/getListaUtenzeAssegnatari'
import TransitionComponentSnackbar from '../UI/CustomSnackbar.jsx'
import {
  Box,
  Typography,
  TextField,
  Container,
  createTheme,
  Button, CircularProgress,
} from '@mui/material'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Header from '../UI/Header'
import { useTheme } from '@mui/material/styles'
import { StyledButton } from '../UI/StyledButton'
import { postLogin } from '../services/login.js'
import { getListe } from '../services/getListe.js'
import { AuthContext } from '../App'
import LogoDividerContainer from '../containers/LogoDividerContainer'
import { ThemeProvider } from '@mui/material/styles'
import homepageLogoADV from '../assets/logo-bancaprogetto.png'
import homepageLogoComplify from '../assets/adv_complify.png'
import simboloBP from '../assets/logobp.png'

const loginTheme = createTheme({ typography: { fontSize: 8 } })

// Seleziona il logo in base all'ambiente
const logo = process.env.NODE_ENV !== 'production' ? homepageLogoComplify : homepageLogoADV

const Login = ({ isAdmin,isProcessingOktaLogin}) => {
  const canShowBasicLogin = process.env.NODE_ENV !== 'production'


  const location = useLocation()
  const theme = useTheme()
  const navigate = useNavigate()

  const [emailIsValid, setEmailIsValid] = useState(true)
  const [passwordIsValid, setPasswordIsValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorCode, setErrorCode] = useState()
  const [oktaErrorMessage, setOktaErrorMessage] = useState('');


  const { dispatch } = useContext(AuthContext)
  const [listaUtentiDaAssegnare, setListaUtentiDaAssegnare] = useState(false)
  const initialState = {
    email: '',
    password: '',
    isSubmitting: false,
    errorMessage: null,
  }
  const [data, setData] = useState(initialState)

  // Colore di background basato sul logo
  const backgroundColor = logo === homepageLogoComplify ? '#19668C' : theme.palette.background.default
  //const backgroundColor = theme.palette.background.default;

  const {
    data: utenzeAssegnatari,
    isFetching: FetchingUtenzeAssegnatari,
    isLoading: LoadingUtenzeAssegnatari,
    isFetched: FetchedUtenzeAssegnatari,
  } = useQuery(['DaAssegnareA'], () => getListaUtenzeAssegnatari())

  const [simulatedToken, setSimulatedToken] = useState('')
  const [simulatedUsername, setSimulatedUsername] = useState('')
  useEffect(() => {
    if (listaUtentiDaAssegnare) {
      localStorage.setItem('DaAssegnareA', JSON.stringify(utenzeAssegnatari !== undefined ? utenzeAssegnatari : []))
    }
  }, [utenzeAssegnatari, listaUtentiDaAssegnare])

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleFormSubmit = event => {
    setListaUtentiDaAssegnare(true)
    event.preventDefault()
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    })
    postLogin(data.password, data.email, null, null)
      .then(response => {
        if (response.data.code === 200) {
          dispatch({
            type: 'LOGIN',
            payload: response.data.body,
          })
          getListe().then(response => {
            if (response.data.code === 200) {
              // Salva i dati nel localStorage
              localStorage.setItem('TipologiaCliente', JSON.stringify(response.data.body.TIPOLOGIA_CLIENTE))
              localStorage.setItem('TipologiaDiRichiesta', JSON.stringify(response.data.body.TIPOLOGIA_DI_RICHIESTA))
              localStorage.setItem('DocumentiOpzionaliVari', JSON.stringify(response.data.body.DOCUMENTI_OPZIONALI_VARI))
              localStorage.setItem('VerificaRafforzataInRelazioneA', JSON.stringify(response.data.body.VERIFICA_RAFFORZATA_IN_RELAZIONE_A))
            }
          })
          navigate('/')
        }
      })
      .catch(error => {
        localStorage.setItem('resmes', error.response.data.message)
        localStorage.setItem('rescode', error.response.data.code)
        setData({
          email: '',
          password: '',
          isSubmitting: false,
          isAuthenticated: false,
          errorMessage: 'Username o Password errata',
        })
      })
  }
  // Simula il login Okta
  const showOktaLogin = process.env.REACT_APP_SHOW_OKTA_LOGIN === 'true'
  const generateCodeVerifier = () => {
    const array = new Uint8Array(32) // Usa 32 byte per creare una stringa di lunghezza adeguata
    window.crypto.getRandomValues(array)

    return btoa(String.fromCharCode(...array))
      .replace(/\+/g, '-')    // Sostituisci + con -
      .replace(/\//g, '_')    // Sostituisci / con _
      .replace(/=+$/, '')    // Rimuovi padding
  }

  const generateCodeChallenge = async (verifier) => {
    const encoder = new TextEncoder()
    const data = encoder.encode(verifier)
    const hash = await window.crypto.subtle.digest('SHA-256', data)
    return btoa(String.fromCharCode(...new Uint8Array(hash)))
      .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  }
  const handleOktaLogin = async () => {
    const codeVerifier = generateCodeVerifier() // Genera code_verifier
    const codeChallenge = await generateCodeChallenge(codeVerifier)

    localStorage.setItem('code_verifier', codeVerifier)

    const clientId = process.env.REACT_APP_OKTA_CLIENT_ID
    const redirectUri = process.env.REACT_APP_REDIRECT_URI  // Callback URI corretto
    const responseType = 'code'
    const scope = 'openid profile email groups'
    const state = 'MdXrGikS5LACsWs2HZFqS7IC9zMC6F9thOiWDa5gxKRqoMf7bCkTetrrwKw5JIAA'

    const oktaAuthUrl = `https://sa.bancaprogetto.it/oauth2/v1/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=${scope}`

    window.location.href = oktaAuthUrl

  }

  useEffect(() => {
    debugger
    if (isProcessingOktaLogin) {
      const query = new URLSearchParams(location.search)
      const code = query.get('code')
      const codeVerifier = localStorage.getItem('code_verifier')

      if (code && codeVerifier) {
        handleOktaCallback(code, codeVerifier)
      } else {
        console.error('Code o codeVerifier mancanti')
      }
    }
  }, [isProcessingOktaLogin, location])

  const handleOktaCallback = async (code, codeVerifier) => {
    //debugger
    setListaUtentiDaAssegnare(true)
    try {
      setData(prevData => ({ ...prevData, isSubmitting: true }));
      setOktaErrorMessage('')
      const response = await postLogin(null, null, code, codeVerifier);

      if (response.data.code === 200) {
        dispatch({
          type: 'LOGIN',
          payload: response.data.body,
        });

        // Recupera le liste dopo il login
        getListe().then(response => {
          if (response.data.code === 200) {
            localStorage.setItem('TipologiaCliente', JSON.stringify(response.data.body.TIPOLOGIA_CLIENTE));
            localStorage.setItem('TipologiaDiRichiesta', JSON.stringify(response.data.body.TIPOLOGIA_DI_RICHIESTA));
            localStorage.setItem('DocumentiOpzionaliVari', JSON.stringify(response.data.body.DOCUMENTI_OPZIONALI_VARI));
            localStorage.setItem('VerificaRafforzataInRelazioneA', JSON.stringify(response.data.body.VERIFICA_RAFFORZATA_IN_RELAZIONE_A));
          }
        });
        navigate('/'); // Reindirizza alla home page
      }
    } catch (error) {
      localStorage.setItem('resmes', error.response.data.message)
      localStorage.setItem('rescode', error.response.data.code)
      setOktaErrorMessage("Errore nell'autenticazione: se il problema persiste, contattare gli amministratori."); // Imposta il messaggio di errore
      setData(prevData => ({ ...prevData, isSubmitting: false }));

    }
  };

  return (
    <>
      <div style={{ position: 'relative', backgroundColor: backgroundColor, minHeight: '100vh' }}>
        <TransitionComponentSnackbar resmes={localStorage.getItem('resmes') || ''}
                                     rescode={localStorage.getItem('rescode') || ''} />
        <Header
          header={
            <Typography
              sx={{ flexGrow: 1, color: theme.palette.black.main }}
              xs={12}
              item
              alignItems="center"
              variant="h1"
            >
              ADEGUATA VERIFICA
            </Typography>
          }
        />
        <LogoDividerContainer showLogo={true}>
          <Box
            component="form"
            noValidate
            onSubmit={handleFormSubmit}
            ml="20em"
            sx={{ width: '75%' }}
          >
            {(canShowBasicLogin || isAdmin) && (<TextField
              sx={{ marginTop: '40px' }}
              margin="normal"
              required
              fullWidth
              value={data.email}
              variant="standard"
              helperText="Email di login"
              id="email"
              name="email"
              autoComplete="email"
              onChange={handleInputChange}
              autoFocus
            />)}
            {(canShowBasicLogin || isAdmin) && (<TextField
              margin="normal"
              helperText="Password"
              required
              value={data.password}
              fullWidth
              variant="standard"
              name="password"
              type="password"
              id="password"
              onChange={handleInputChange}
              autoComplete="current-password"
            />)}

            {(canShowBasicLogin || isAdmin) && data.errorMessage && (
              <span className="form-error">{data.errorMessage}</span>
            )}
            {(canShowBasicLogin || isAdmin) && (<div>
              <a href={process.env.REACT_APP_ALFRESCO_RESET_PASSWORD} target="_blank" rel="noopener noreferrer"
                 style={{ color: 'black' }}>
                Password dimenticata?
              </a>
            </div>)}
            {(canShowBasicLogin || isAdmin) && (<Container
              sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}
            >
              <StyledButton
                type="submit"
                sx={{ mt: 15, mb: 2 }}
                disabled={data.isSubmitting || (!emailIsValid && passwordIsValid)}
              >
                {data.isSubmitting ? 'Loading...' : 'LOGIN'}
              </StyledButton>

            </Container>)}
            {(!canShowBasicLogin && !isAdmin) && (
              <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8, width: '100%' }}>
                {showOktaLogin && (
                  <>
                    {/*<Typography variant="h2" sx={{ mb: 2, color: '#19668C' }}>Accedi con</Typography>
                    */}
                    <StyledButton
                      type="button"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                        borderRadius: '20px',
                        fontSize: '1.05rem',
                        backgroundColor: 'white',
                        color: '#19668C',
                        boxShadow: '6px 6px 12px rgba(0, 0, 0, 0.15)',
                        transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out', // transizione per effetto hover
                        '&:hover': {
                          backgroundColor: 'white',
                          boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.3)',
                          transform: 'translateY(-2px)',
                          color: '#19668C',
                          //fontSize: '1.10rem'

                        },
                      }}
                      disabled={data.isSubmitting || (!emailIsValid && passwordIsValid)}
                      onClick={handleOktaLogin}
                    >
                      {data.isSubmitting
                        ? (
                          <>
                            <CircularProgress size={24} />
                            <Typography sx={{ color: '#9e0930', marginLeft: 1, fontWeight: 'bold' }}>
                              Autenticazione in corso...
                            </Typography>

                          </>
                        )
                        : (
                          <>
                            ACCEDI CON&nbsp;
                            <img src={simboloBP} alt="Simbolo Banca Progetto"
                                 style={{ width: '30px', height: 'auto', verticalAlign: 'middle' }} />
                            &nbsp;BANCA PROGETT<span style={{ color: '#9e0930' }}>O</span>
                          </>
                        )
                      }
                    </StyledButton>
                    {oktaErrorMessage && (
                      <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                        {oktaErrorMessage}
                      </Typography>
                    )}
                  </>
                )}
              </Container>
            )}
            <ThemeProvider theme={loginTheme}>
            <Typography
                sx={{ position: 'fixed', bottom: '10px', marginTop: '6em' }}
              >
                COMPLIFY è un marchio Esquad s.r.l. con sede legale in via XXIV Maggio 16, Cosenza CF/PI 10229340962
              </Typography>
            </ThemeProvider>
          </Box>
        </LogoDividerContainer>
      </div>
    </>
  )
}

export default Login
