import React, { useState, useEffect } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { IconButton, Stack, Box } from '@mui/material'
import { uploadDocument } from '../../services/uploadDocument'
import CustomModal from '../../UI/CustomModal'

function UploadDocument({ idDocumento, disabled, onUpload }) {
    const [selectedDocument, setSelectedDocument] = useState()
    const [loading, setLoading] = useState(false)
    const [infoMessage, setInfoMessage] = useState('')
    const [isFileSelected, setIsFileSelected] = useState(false)
    const [selectedId, setSelectedId] = useState('')
    const [error, setError] = useState(false)

    const fileInputRef = React.useRef();

    //seleziona file e idDocumento per invio al backend
    const handleSelection = event => {
        event.preventDefault()
        setLoading(true)
        let document = event.target.files[0]
        setSelectedDocument(document)
        setIsFileSelected(true)
        setSelectedId(idDocumento)
    }

    // chiamata fetch vincolata al documento selezionato
    useEffect(() => {
        setInfoMessage('')
        if (selectedDocument !== undefined) {
            const file = new FormData();
            file.append('file', selectedDocument);
            uploadDocument(file, selectedId, selectedDocument.name)
                .then(response => {
                    setLoading(false);
                    setError(false);
                    if (response.status === 200) {
                        setInfoMessage('Upload Effettuato');
                        setTimeout(function () {
                            onUpload();
                            setIsFileSelected(false);
                        }, 1000);
                    }
                })
                .catch(error => {
                    setInfoMessage("Errore nell'upload del documento");
                    setLoading(false);
                    setError(true);
                    if (error.response) {
                        setTimeout(function () {
                            setIsFileSelected(false);
                        }, 4000);
                    } else {
                        setTimeout(function () {
                            setIsFileSelected(false);
                            setInfoMessage("Errore nell'upload del documento. Verifica la dimensione del file.");
                        }, 4000);
                    }
                });
        }
    }, [selectedDocument, isFileSelected,selectedId])

    const handleIconClick = () => {
        fileInputRef.current.click();
    }

    return (
      <>
        <Stack direction="row" alignItems="center">
          <IconButton
            disabled={disabled}
            component="span"
            onClick={handleIconClick}
            //sx={{ zIndex: 1000 }}  // Imposta un livello basso
          >
            <input
              accept="application"
              hidden
              type="file"
              ref={fileInputRef}
              name="file"
              id={idDocumento}
              onClick={(event) => (event.target.value = null)}
              onChange={(e) => handleSelection(e)}
            />
            <CloudUploadIcon />
          </IconButton>
        </Stack>

        {/* Modale fuori dallo Stack per sovrapposizione */}
        <CustomModal
          hideBackdrop
          open={isFileSelected}
          loading={loading}
          error={error}
          message={infoMessage}
          //sx={{ zIndex: 4000 }}  // Modale con livello elevato
        />
      </>
    );
}

export default UploadDocument
