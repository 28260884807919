import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { getStatisticheMonitoraggio } from '../services/getStatisticheMonitoraggio';
import Header from '../UI/Header';
import * as XLSX from 'xlsx';
import { StyledButton } from '../UI/StyledButton'


const StatisticheMonitoraggio = () => {
  const [statistiche, setStatistiche] = useState(null);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getStatisticheMonitoraggio()
      .then((data) => {
        setStatistiche(data);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  const columns = [
    { field: 'ndg', headerName: 'NDG', flex: 0 },
    { field: 'codiceFiscale', headerName: 'Codice Fiscale', flex: 0,minWidth: 150 },
    { field: 'ragioneSociale', headerName: 'Ragione Sociale', flex: 1,minWidth: 180 },
    { field: 'praticheAperte', headerName: 'Aperte', flex: 0, align: 'center',headerAlign: 'center' },
    { field: 'praticheChiuse', headerName: 'Chiuse', flex: 0, align: 'center',headerAlign: 'center' },
    { field: 'praticheAdvInCorso', headerName: 'ADV in Corso', flex: 0,minWidth: 130, align: 'center',headerAlign: 'center' },
    { field: 'creatoAdv', headerName: 'Creato ADV', flex: 0, align: 'center',headerAlign: 'center' },
  ];

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(statistiche?.elencoMonitoraggi.map(item => ({
      NDG: item.ndg,
      'Codice Fiscale': item.codiceFiscale,
      'Ragione Sociale': item.ragioneSociale,
      'Pratiche Aperte': item.praticheAperte,
      'Pratiche Chiuse': item.praticheChiuse,
      'Pratiche ADV in Corso': item.praticheAdvInCorso,
      'Creato ADV': item.creatoAdv,
    })));

    const range = XLSX.utils.decode_range(ws['!ref']);
    const colWidths = [];

    for (let col = range.s.c; col <= range.e.c; col++) {
      let maxWidth = 0;

      for (let row = range.s.r; row <= range.e.r; row++) {
        const cell = ws[XLSX.utils.encode_cell({ r: row, c: col })];
        if (cell && cell.v) {
          const cellValue = cell.v.toString();
          maxWidth = Math.max(maxWidth, cellValue.length);
        }
      }

      colWidths.push({ wpx: Math.max(maxWidth * 8, 80) });
    }

    ws['!cols'] = colWidths;

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Statistiche Monitoraggio');

    const globalStats = [

      { Statistica: 'Totale Monitoraggi', Valore: statistiche.totaleMonitoraggi },
      { Statistica: 'Monitoraggi Chiusi', Valore: statistiche.monitoraggiChiusi },
      { Statistica: 'Monitoraggi Aperti', Valore: statistiche.monitoraggiAperti },
      { Statistica: 'Clienti in Monitoraggio', Valore: statistiche.posizioniUnivocheAperte },
    ];

    const wsGlobalStats = XLSX.utils.json_to_sheet(globalStats);
    const rangeGlobalStats = XLSX.utils.decode_range(wsGlobalStats['!ref']);
    const colWidthsGlobalStats = [];

    for (let col = rangeGlobalStats.s.c; col <= rangeGlobalStats.e.c; col++) {
      let maxWidth = 0;

      for (let row = rangeGlobalStats.s.r; row <= rangeGlobalStats.e.r; row++) {
        const cell = wsGlobalStats[XLSX.utils.encode_cell({ r: row, c: col })];
        if (cell && cell.v) {
          const cellValue = cell.v.toString();
          maxWidth = Math.max(maxWidth, cellValue.length);
        }
      }

      colWidthsGlobalStats.push({ wpx: Math.max(maxWidth * 8, 80) });
    }

    wsGlobalStats['!cols'] = colWidthsGlobalStats;
    XLSX.utils.book_append_sheet(wb, wsGlobalStats, 'Statistiche Globali');
    XLSX.writeFile(wb, 'Statistiche_Monitoraggio.xlsx');
  };


  const rows = statistiche?.elencoMonitoraggi.map((monitoraggio, index) => ({
    id: index,
    ...monitoraggio,
  }));

  return (
    <div>
      <Stack>
        <Header
          showHome={true}
          showRichiesta={false}
          showStatistiche={false}
          showMonitoraggio={true}
          header={
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography
                  sx={{ flexGrow: 1 }}
                  mb={'1rem'}
                  color={theme.palette.black.main}
                  variant="h1"
                >
                  Accounting Monitoraggio
                </Typography>
              </Grid>
            </Grid>
          }
        />
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
        <Box sx={{ marginTop: '100px', marginLeft: '2.5%',marginRight: '2.5%' }}>
          <Typography variant="h3" style={{ marginBottom: '10px' }}>
            Statistiche Globali:
          </Typography>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} md={10}>
              <div style={{ paddingLeft: '10px', paddingBottom: '5px' }}>
                <strong>Totale Monitoraggi: </strong>{statistiche.totaleMonitoraggi}
              </div>
              <div style={{ paddingLeft: '10px', paddingBottom: '5px' }}>
                <strong>Monitoraggi Chiusi: </strong>{statistiche.monitoraggiChiusi}
              </div>
              <div style={{ paddingLeft: '10px', paddingBottom: '5px' }}>
                <strong>Monitoraggi Aperti: </strong>{statistiche.monitoraggiAperti}
              </div>
              <div style={{ paddingLeft: '10px', paddingBottom: '5px' }}>
                <strong>Clienti in Monitoraggio: </strong>{statistiche.posizioniUnivocheAperte}
              </div>
            </Grid>

            <Grid item xs={12} md={2} style={{ display: 'flex',alignItems: 'flex-end', textAlign: 'right' , justifyContent: 'flex-end'}}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={exportToExcel}
                //style={{ marginTop: '100px' }}
              >
                Esporta in Excel
              </StyledButton>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              overflowX: 'auto',
              marginTop: '20px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '100%',
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                pagination
                disableSelectionOnClick
                autoHeight
                sx={{
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: theme.palette.grey[200],
                  },
                  '& .MuiDataGrid-cell': {
                    fontSize: '14px',
                    textAlign: 'center',
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        )}
      </Stack>
    </div>
  );
};

export default StatisticheMonitoraggio;
