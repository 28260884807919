import React, { useState, useEffect } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, TextField, Typography, Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { StyledButton } from '../../UI/StyledButton'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const EditableAccordion = ({ attivitaSvolteList = [], onUpdateAttivita , canAddAttivita}) => {
  const theme = useTheme()
  const [localAttivitaList, setLocalAttivitaList] = useState(attivitaSvolteList)
  const [newAttivita, setNewAttivita] = useState({ data: '', attivita: '', note: '' })
  const [addingAttivita, setAddingAttivita] = useState(false)
  const [showButtonAggiungiAttivita, setShowButtonAggiungiAttivita] = useState(true)

  // Sincronizza localAttivitaList con attivitaSvolteList quando quest'ultimo cambia
  useEffect(() => {
    setLocalAttivitaList(attivitaSvolteList)
  }, [attivitaSvolteList])

  // Aggiorna lo stato locale e chiama la callback
  const handleUpdateAttivita = (updatedList) => {
    if (onUpdateAttivita) {
      onUpdateAttivita(updatedList) // Callback per aggiornare il genitore
    }
  }

  // Aggiungi una nuova attività
  const handleAddAttivita = () => {
    setAddingAttivita(true)
    setShowButtonAggiungiAttivita(false)
    const today = new Date().toISOString().split('T')[0] // Data corrente (senza ora)
    setNewAttivita({ data: today, attivita: '', note: '' }) // Imposta lo stato con un'attività vuota
  }

  // Gestisce il cambiamento nei campi dell'attività
  const handleInputChange = (field, value) => {
    if (field === 'data') {
      // Usa la data così com'è senza convertirla
      setNewAttivita((prev) => ({ ...prev, [field]: value }));
    } else {
      setNewAttivita((prev) => ({ ...prev, [field]: value }));
    }
  }

  // Salva la nuova attività
  const handleSaveNewAttivita = () => {
    if (newAttivita.attivita.trim()) {
      const updatedList = [...localAttivitaList, {
        id: Date.now(),
        ...newAttivita,
      }]
      handleUpdateAttivita(updatedList) // Invia l'elenco aggiornato
      setNewAttivita({ data: '', attivita: '', note: '' }) // Reset dei campi dopo salvataggio
      setAddingAttivita(false)
      setShowButtonAggiungiAttivita(true)
    } else {
      alert('L\'attività è obbligatoria!')
    }
  }

  return (
    <Accordion disableGutters elevation={0} sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        sx={{
          backgroundColor: theme.palette.yellow.middle,
          width: '100%'
        }}
      >
        <Typography>ATTIVITÀ SVOLTE</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ paddingLeft: '40px' }}>
          {attivitaSvolteList.length===0 && (        <Typography>Nessuna attività presente.</Typography>
          )}
          <Box sx={{ mb: 2 }}>
            <ul style={{ listStyleType: 'disc', paddingLeft: '0px', margin: 0 }}>
              {localAttivitaList.map((att, index) => (
                <li
                  key={att.id}
                  style={{
                    listStyle: 'none', // Rimuove il bullet point, opzionale
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: index % 2 === 0 ? theme.palette.grey['300'] : theme.palette.grey['200'], // Alterna colori
                      padding: 2,
                      borderRadius: 1,
                      mb: 0,
                    }}
                  >
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <div style={{ fontWeight: 'bold', fontStyle: 'italic', display: 'inline' }}>
                        Data:
                      </div>
                      <div style={{ display: 'inline', marginLeft: '4px' }}>
                        {att.data || '---'}
                      </div>
                      <div style={{ marginLeft: '16px', marginRight: '16px' }}>-</div>
                      <div style={{ fontWeight: 'bold', fontStyle: 'italic', display: 'inline' }}>
                        Attività:
                      </div>
                      <div style={{ display: 'inline', marginLeft: '4px' }}>
                        {att.attivita || '---'}
                      </div>
                    </Box>

                    <Box sx={{ paddingLeft: '20px' }}>
                      <div style={{ fontWeight: 'bold', fontStyle: 'italic', display: 'inline' }}>
                        Note:
                      </div>
                      <div style={{ display: 'inline', marginLeft: '4px' }}>
                        {att.note || 'N.D.'}
                      </div>
                    </Box>
                  </Box>
                </li>
              ))}
            </ul>
          </Box>

          {addingAttivita && (
            <Box
              sx={{
                backgroundColor: 'white',
                padding: 3,
                //borderRadius: 1,
                boxShadow: 1,
                mb: 2,
                borderRadius: '12px',
                width: '90%',
                margin: '0 auto',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  mb: 3,
                }}
              >
                Aggiungi una Nuova Attività
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 3,
                  mb: 2,
                }}
              >
                <TextField
                  label="Data"
                  type="date"
                  value={newAttivita.data}
                  onChange={(e) => handleInputChange('data', e.target.value)}
                  variant="outlined"
                  //InputLabelProps={{ shrink: true }}
                  sx={{ width: '180px', marginTop: 2 }} // Occupa solo lo spazio necessario
                />
                <TextField
                  label="Attività"
                  value={newAttivita.attivita}
                  onChange={(e) => handleInputChange('attivita', e.target.value)}
                  fullWidth
                  variant="outlined"
                  sx={{ flexGrow: 1 }} // Occupa tutto lo spazio rimanente
                />
              </Box>

              <TextField
                label="Note"
                value={newAttivita.note}
                onChange={(e) => handleInputChange('note', e.target.value)}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                sx={{ mb: 2 }}
              />

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <StyledButton
                  onClick={handleSaveNewAttivita}
                  color="primary"
                  variant="contained"
                  sx={{ fontWeight: 'bold' }}
                >
                  Aggiungi
                </StyledButton>
                <Button
                  onClick={() => {
                    setNewAttivita({ data: '', attivita: '', note: '' });
                    setAddingAttivita(false);
                    setShowButtonAggiungiAttivita(true);
                  }}
                  color="secondary"
                  variant="outlined"
                  sx={{ fontWeight: 'bold' }}
                >
                  Annulla
                </Button>
              </Box>
            </Box>
          )}



          {(showButtonAggiungiAttivita && canAddAttivita) &&(
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <StyledButton
                onClick={handleAddAttivita}
                color="primary"
                variant="contained"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  fontWeight: 'bold',
                  /*'&:hover': {
                    backgroundColor: theme => theme.palette.primary.dark,
                  },*/
                }}
              >
                Nuova Attività
              </StyledButton>
            </Box>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default EditableAccordion
