import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export const customAxios = axios.create(
    {
        baseURL: process.env.REACT_APP_BACKEND_HOST,
    }
)

customAxios.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.status === 511 && window.location.pathname !== '/sessione-scaduta') {
            window.location.href = '/sessione-scaduta';
        }/*else if (!error.response) {
          // Errori di rete generici (es. server non raggiungibile)
          window.location.href = '/error'
          localStorage.setItem('errorCode', 503)
          localStorage.setItem('errorMessage', "Server non raggiungibile. Controlla la connessione o riprova più tardi.")
        }*/ else {
          // Altri errori (es. errori durante la configurazione della richiesta)
          console.error('Errore nella richiesta:', error.message)
        }
        return Promise.reject(error);
    });