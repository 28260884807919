import { customAxios } from './customAxios'
//import { StatiPratica } from '../utils/StatiPratica'
export function updateFolder(formValues) {
  const config = {
    headers: {
      AuthToken: JSON.parse(localStorage.getItem('token')),
    },
  }
  const data = {
    citta: formValues.Citta,
    codiceFiscale: formValues.codiceFiscale,
    idNodoPratica: formValues.idNodoPratica,
    indirizzo: formValues.indirizzo,
    nazione: formValues.nazione,
    ndg: formValues.NDG,
    nomePratica: formValues.nomePratica,
    ragioneSociale: formValues.ragioneSociale,
    statoRichiesta: formValues.statoRichiesta,
    tipoInserimento: formValues.tipoInserimento,
    tipologiaCliente: formValues.tipologiaCliente,
    tipologiaDiRichiesta: formValues.tipologiaDiRichiesta,
    tipologiaOperativita: formValues.tipologiaOperativita,
    relazione: formValues.relazione,
    rafforzataAutomatica: formValues.rafforzataAutomatica,
    perConto: formValues.perConto,
    documentiOpzionaliVari: formValues.documentiOpzionaliVari,
    motivazioneRichiesteOpzionali: formValues.motivazioneRichiesteOpzionali,
    dettaglioRelazione: formValues.dettaglioRelazione,
    daAssegnare: formValues.daAssegnare,
    dettaglioKey: formValues.dettaglioKey,
    gruppoUtenteCreatore:formValues.gruppoUtenteCreatore,
  }

  const updateFolderRequest = customAxios.post(
    process.env.REACT_APP_BACKEND_HOST + '/'+process.env.REACT_APP_PATH_NEW_PREFIX+'/updateFolder',
    data,
    config,
  )
  return updateFolderRequest
}
