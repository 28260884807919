const ReportAccountingTable = props => {

  const tableStyle = {
    width: '50%', 
    borderCollapse: 'collapse', 
  };

  const cellStyle = {
    border: '1px solid black', 
 
    padding: '2px', 
    textAlign: 'center',
  };


  const reportInitialState =
    [{
      AutomaticaAltoRischio: 0,
      AutomaticaMedioRischio: 0,
      Manuale: 0
    }];


  return (
    <>
      <div>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={cellStyle}>Automatica Alto Rischio</th>
              <th style={cellStyle}>Automatica Medio Rischio</th>
              <th style={cellStyle}>Manuale</th>
            </tr>
          </thead>
          <tbody>
            {props.reportAccounting?.map((item, index) => (
              <tr key={index}>
                <td style={cellStyle}>{item?.['Automatica Alto Rischio'] || 0}</td>
                <td style={cellStyle}>{item?.['Automatica Medio Rischio'] || 0}</td>
                <td style={cellStyle}>{item?.Manuale || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
export default ReportAccountingTable;