import { customAxios } from './customAxios'

export function validateToken() {
  const config = {
    headers: {
      Accept: "*/*",
      AuthToken: JSON.parse(localStorage.getItem('token')),
    }
  }

  const ValidationToken = customAxios.post(process.env.REACT_APP_BACKEND_HOST + `/`+process.env.REACT_APP_PATH_NEW_PREFIX+`/validateToken?`, null, config);

  return ValidationToken
}