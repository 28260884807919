import * as React from 'react'
import {
  RadioGroup,
  Radio,
  Stack,
  Typography,
  textarea,
  FormGroup,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import { useEffect } from 'react'
import { getEsitoADV } from '../../services/getEsitoADV'
import { getApprovazioneADV } from '../../services/getApprovazioneADV'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function ApprovazioneAdeguataVerificaSalvata(props) {
  const [idNodoPratica, setIdNodoPratica] = React.useState('')
  const theme = useTheme()

  const [checkOperatore, setCheckOperatore] = React.useState({})
  const [valueNote1, setValueNote1] = React.useState('')
  const [noteMotivazione, setNoteMotivazione] = React.useState('')
  const [noteUtenteAutorizzativo, setNoteUtenteAutorizzativo] =
    React.useState('')

  const handleChangeNote1 = event => {
    setValueNote1(event.target.value)
  }
  const [approvazioneADV, setApprovazioneADV] = React.useState({})

  useEffect(() => {
    //setIdNodoPratica(props.idNodoPratica)
    // //debugger
    try {
      getEsitoADV(props.idNodoPratica).then(response => {
        //console.log(response.noteAzioniDaPorreInEssere)
        // //debugger
        setCheckOperatore(response)
      })
    } catch (error) { }
  }, [props.idNodoPratica])

  useEffect(() => {
    try {
      getApprovazioneADV(props.idNodoPratica).then(response => {
        // //debugger

        setNoteMotivazione(response.motivazione)
        // console.log('NOTE MOTIVAZIONE', noteMotivazione)
        // console.log('RESPONSE NOTE UTENTE', response.noteutente)
        // //debugger
      })
    } catch (error) { }
  }, [props.idNodoPratica])
  // console.log(noteMotivazione)

  return (
    <FormGroup>
      <Typography sx={{ mr: 2, mb: 2, mt: 2 }}>Esito ADVR</Typography>
      <textarea
        style={{ margin: "2px", minWidth: "100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%", minHeight: "60px" }}
        minrows={4}
        disabled={true}
        defaultValue={checkOperatore.noteAzioniDaPorreInEssere}
      ></textarea>
      {/* <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={approvazioneADV.approvazione}
      >
        <Typography sx={{ mr: 2, mb: 2, mt: 2 }}>Approvazione</Typography>

        <Stack direction="row">
          {approvazioneADV.approvazione === 'Chiusa' && (
            <FormControlLabel
              value="Chiusa"
              control={<Radio checked={true} disabled={true} />}
              label={<Typography variant="caption">SI</Typography>}
            />
          )}
        </Stack>
      </RadioGroup> */}
      <Typography sx={{ mr: 2, mb: 2, mt: 2 }}>Motivazione</Typography>

      <textarea
         style={{ margin: "2px",minWidth:"100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%",minHeight:"60px" }}
        minrows={4}
        disabled={true}
        defaultValue={noteMotivazione}
      ></textarea>
    </FormGroup>
  )
}

export default ApprovazioneAdeguataVerificaSalvata
