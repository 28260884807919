import { customAxios } from './customAxios'

export function logout() {
  const config = {
    headers: {
      Accept: "*/*",
      AuthToken: JSON.parse(localStorage.getItem('token')),
    }
  }

  const sessionLogout = customAxios.delete(process.env.REACT_APP_BACKEND_HOST + `/`+process.env.REACT_APP_PATH_NEW_PREFIX+`/logout`, config);

  return sessionLogout
}