import { customAxios } from './customAxios'
export function getGroupsForRichiestaSupporto(groupType) {
    const config = {
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }

    const uri = process.env.REACT_APP_BACKEND_HOST + `/`+process.env.REACT_APP_PATH_NEW_PREFIX+`/getGroupsForRichiestaSupporto?groupType=`+groupType;
    const getGroupsForRichiestaSupporto = customAxios.get(uri, config).then(response => response.data.body)
    return getGroupsForRichiestaSupporto;
}