import React from 'react'
import { Grid, FormControl, Box } from '@mui/material'
import LogoComponent from '../UI/LogoComponent'
import { Divider } from '@mui/material'


const LogoDividerContainer = (props) => {
    return (
        <Grid container item direction='row' sx={{ marginTop: '2%' }} alignContent='center' justifyContent='start'>
            {props.showLogo ? 
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <LogoComponent />
                </Grid> : <Grid item xs={12} sm={6} md={6} lg={6} />}
            <Grid item xs={0} sm={1} md={1} lg={1} />
            <Divider orientation='vertical' flexItem />

            <Grid item xs={12} sm={5} md={4} lg={4}>
                <FormControl fullWidth>
                    <Grid container component="main" sx={{ height: '100%', mt: '2%' }}>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                {props.children}
                            </Box>
                        </Grid>
                    </Grid>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default LogoDividerContainer
