import {
  Box,
  FormGroup,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Checkbox,
  Stack,
} from '@mui/material'
import { Container } from '@mui/system'
import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { StyledButton } from './StyledButton'
import { getGroupsForRichiestaSupporto } from '../services/getGroupsForRichiestaSupporto'
import { useQuery } from '@tanstack/react-query'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const style = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 6,
  width: '600px',
  height: '280px'
}

const SendModal = props => {

  const theme = useTheme()
  const [noteCampo, setNoteCampo] = useState('')
  const [gruppiRichiestaSupportoSelected, setGruppiRichiestaSupportoSelected] =
    useState([])
  const [groupType, setGroupType] = useState(
    props.groupType ? props.groupType : '',
  )

  const {
    data: gruppiSupporto,
    isFetching: isGruppiSupportoFetching,
    isFetched: isGruppiSupportoFetched,
  } = useQuery(
    ['gruppiSupporto', props.groupType],
    () => getGroupsForRichiestaSupporto(props.groupType),
    { refetchOnMount: 'always' },
  )

  const [formValues, setFormValues] = useState({
    idNodoPratica: props.idNodoPratica,
    message: '',
    groupsId: [],
    richiedenteSupportoConsulenziale: props.richiedenteSupportoConsulenziale,
  })
 
  const handleChangeNoteCampo = event => {
    event.preventDefault()
    const {
      target: { value },
    } = event
    setNoteCampo(value)
    setFormValues({ ...formValues, message: value })
  }

  const handleMultiChangeGruppiRichiestaSupporto = event => {
    const {
      target: { value },
    } = event
    setGruppiRichiestaSupportoSelected(value)
    setFormValues({ ...formValues, groupsId: value })
  }

  return (
    <Modal open={props.open}>
      <Stack  style={style} sx={{ backgroundColor: "background.paper", height: "100%" }} direction={'column'}>
        <Stack 
        justifyContent="center"
        alignItems={'center'}
           direction={'row'} >
            <Typography variant="h3" >
            {props.titleSendModal ? props.titleSendModal : ''}
          </Typography>
          <StyledButton
            sx={{ borderRadius: 1,marginLeft:"52%",width:"40px",marginTop:"1%" }}
            onClick={props.onClick}> <CloseIcon />
          </StyledButton>
        </Stack>

        <Stack direction={'column'} spacing={2} margin={2}>
          <textarea
           style={{ margin: "2px",minWidth:"100%", width: "100%", maxWidth: "100%", height: "100px", maxHeight:"100px",minHeight:"100px" }}
            placeholder={props.rispostaSupporto ? 'Inserire la risposta alla richiesta...' : 'Inserire la motivazione della richiesta...'}
            minrows={3}
            onChange={handleChangeNoteCampo}
          ></textarea>
        </Stack>
        <Stack  justifyContent={'flex-end'} direction={'row'} alignItems={'baseline'}>
        {props.rispostaSupporto === false && (
        
            <TextField
              select
              label="Seleziona i gruppi a cui inviare la richiesta"
              variant="standard"
              size="none"
              sx={{ width: '85%', borderColor: "black" }}
              id="richiesta-supporto-multiple-checkbox"
              MenuProps={menuProps}
              SelectProps={{
                multiple: true,
                value: gruppiRichiestaSupportoSelected,
                onChange: handleMultiChangeGruppiRichiestaSupporto,
                renderValue: selected => selected.join(', '),
              }}
            >
              {isGruppiSupportoFetched &&
                gruppiSupporto &&
                gruppiSupporto.map(name => (
                  <Tooltip
                    placement="left"
                    key={name}
                    title={name}
                    value={name}
                  >
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={
                          gruppiRichiestaSupportoSelected.indexOf(name) >
                          -1
                        }
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  </Tooltip>
                ))}
            </TextField>
   
        )}
       
          <StyledButton
            onClick={() => props.onSubmit(formValues)}
            sx={{ borderRadius: '5px' }}
            disabled={
              noteCampo.trim().length === 0 ||
              (gruppiRichiestaSupportoSelected.length === 0 &&
                props.rispostaSupporto === false)
            }
          >
            Invia
          </StyledButton>
        </Stack>

      </Stack>

    </Modal >
  )
}

export default SendModal
