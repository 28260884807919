import React from 'react'
import { Container, Typography } from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import { getChartData } from '../services/getChartData'
import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { CircularProgress } from '@mui/material'

const GraficoPie2 = props => {
  const [chartData, setChartData] = useState([])
  const { data, isFetching, isError, refetch, isFetched } = useQuery(
    ['grafico-tipo-inserimento'],
    () => getChartData('tipoinserimento', props.searchPraticheChiuse),
  )

  useEffect(() => {
    refetch()
  }, [props.searchPraticheChiuse])
  useEffect(() => {
    try {
      if (data !== undefined) {
        setChartData(
          data?.map(val => {
            return { id: val.label, value: val.count, label: val.label }
          }),
        )
      }
    } catch (error) {
      // console.log(error)
    }
  }, [data])

  return (
    <Container
      sx={{
        height: { xs: '20rem', xl: '25rem' },
        width: { xs: '100%', xl: '80%' },
      }}
    >
      {isError && <Typography>{'Errore nella ricezione dei dati'}</Typography>}
      {isFetching ? (
        <CircularProgress />
      ) : (
        <ResponsivePie
          data={
            chartData !== [] ? chartData : [{ id: '', value: 0, label: '' }]
          }
          margin={{ top: 20, right: 90, bottom: 90, left: 90 }}
          
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          colors={props.colors}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          arcLinkLabelsStraightLength={6}
          arcLinkLabelsTextOffset={2}
          arcLinkLabelsDiagonalLength={4}
          arcLinkLabelsSkipAngle={1}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
          }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={[
            {
              anchor: 'bottom-left',
              direction: 'column',
              justify: false,
              translateX: -80,
              translateY: 80,
              itemWidth: 100,
              itemHeight: 20,
              itemsSpacing: 0,
              symbolSize: 10,
              itemDirection: 'left-to-right',
            },
          ]}
        />
      )}
    </Container>
  )
}

export default GraficoPie2

{
  /* <Box >
                      <Container>
                          <ResponsivePie
                              data={[
                                  {
                                      "id": "NO",
                                      "label": "NO",
                                      "value": data.tipologyPieChart != null ? Math.round((data.tipologyPieChart["1"] / data.totalSize) * 100) : 0,
                                      "color": "hsl(120, 100%, 20%)"
                                  },
                                  {
                                      "id": "MENSILE",
                                      "label": "MENSILE",
                                      "value": data.tipologyPieChart != null ? Math.round((data.tipologyPieChart["2"] / data.totalSize) * 100) : 0,
                                      "color": "hsl(149, 55%, 36%)"
                                  },
                                  {
                                      "id": "TREMESTRALE",
                                      "label": "TREMESTRALE",
                                      "value": data.tipologyPieChart != null ? Math.round((data.tipologyPieChart["3"] / data.totalSize) * 100) : 0,
                                      "color": "hsl(136, 100%, 30%)"
  
                                  },
                                  {
                                      "id": "SEMESTRALE",
                                      "label": "SEMESTRALE",
                                      "value": data.tipologyPieChart != null ? Math.round((data.tipologyPieChart["6"] / data.totalSize) * 100) : 0,
                                      "color": "hsl(147, 50%, 47%)"
                                  },
                                  {
                                      "id": "ANNUALE",
                                      "label": "ANNUALE",
                                      "value": data.tipologyPieChart != null ? Math.round((data.tipologyPieChart["9"] / data.totalSize) * 100) : 0,
                                      "color": "hsl(120, 61%, 50%)"
                                  }
                              ]}
                              margin={{ top: 30, right: 0, bottom: 30, left: 0 }}
                              innerRadius={0.5}
                              padAngle={0.7}
                              cornerRadius={3}
                              activeOuterRadiusOffset={5}
                              activeInnerRadiusOffset={10}
                              colors={{ datum: 'data.color' }}
                              borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
  
                              enableArcLabels={false}
                              // isInteractive={false}
                              enableArcLinkLabels={false}
                              arcLinkLabelsSkipAngle={10}
                              arcLinkLabelsTextColor="#333333"
                              arcLinkLabelsThickness={2}
                              arcLinkLabelsColor={{ from: 'color' }}
                              arcLabelsSkipAngle={10}
                              arcLabelsTextColor="#333333"
                              tooltip={({ datum: { id, value, color, label } }) => (
                                  <div
                                      style={{
                                          padding: 5,
                                          color: '#006600',
                                          background: '#ffffff',
                                      }}
                                  >
                                      <strong>
                                          {label}
                                      </strong>
                                  </div>
                              )}
  
                              defs={[
                                  {
                                      id: 'dots',
                                      type: 'patternDots',
                                      background: 'inherit',
                                      color: 'rgba(255, 255, 255, 0.3)',
                                      size: 4,
                                      padding: 1,
                                      stagger: true
                                  },
                                  {
                                      id: 'lines',
                                      type: 'patternLines',
                                      background: 'inherit',
                                      color: 'rgba(255, 255, 255, 0.3)',
                                      rotation: -45,
                                      lineWidth: 6,
                                      spacing: 10
                                  }
                              ]}
                              fill={[
                                  {
                                      match: {
                                          id: 'ruby'
                                      },
                                      id: 'dots'
                                  },
                                  {
                                      match: {
                                          id: 'c'
                                      },
                                      id: 'dots'
                                  },
                                  {
                                      match: {
                                          id: 'go'
                                      },
                                      id: 'dots'
                                  },
                                  {
                                      match: {
                                          id: 'python'
                                      },
                                      id: 'dots'
                                  },
                                  {
                                      match: {
                                          id: 'scala'
                                      },
                                      id: 'lines'
                                  },
                                  {
                                      match: {
                                          id: 'lisp'
                                      },
                                      id: 'lines'
                                  },
                                  {
                                      match: {
                                          id: 'elixir'
                                      },
                                      id: 'lines'
                                  },
                                  {
                                      match: {
                                          id: 'javascript'
                                      },
                                      id: 'lines'
                                  }
                              ]}
                              legends={[
                                  {
                                      anchor: 'bottom-left',
                                      direction: 'column',
                                      justify: false,
                                      translateX: -5,
                                      translateY: 0,
                                      itemWidth: 100,
                                      itemHeight: 10,
                                      itemsSpacing: 15,
                                      symbolSize: 20,
                                      itemDirection: 'left-to-right',
                                      effects: [
  
                                      ]
                                  }
                              ]}
  
  
                          />
                      </Container>
                  </Box> */
}
