import * as React from 'react'
import { useState } from 'react'
import CustomModal from '../UI/CustomModal'
import { Grid, Typography } from '@mui/material'
import { addAdeguataVerifica } from '../services/addAdeguataVerifica'
import { useNavigate, useLocation } from 'react-router-dom'

import Header from '../UI/Header'

import { useTheme } from '@mui/material/styles'
import AdeguataVerificaForm from './components/AdeguataVerificaForm'

//rimosso campo : "Apertura rapporto continuativo"

const NuovaRichiestaForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const [openCustomModal, setOpenCustomModal] = useState(false)
  const [disabilitaBottoneSalva, setDisabilitaBottoneSalva] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [errorCF, setErrorCF] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [error, setError] = useState(false)

  // controllo modale nuova richiesta
  const handleSubmit = (event, params) => {
    setInfoMessage('') // pulisco i messaggi di errori se riclicco Salva
    //debugger
    if (
      params.NDG === '' ||
      params.ragioneSociale === '' ||
      params.codiceFiscale === '' ||
      // params.Citta === '' ||
      // params.indirizzo === '' ||
      // params.nazione === '' ||
      params.tipologiaDiRichiesta === '' ||
      params.daAssegnare === '' ||
      params.daAssegnare === null
    ) {
      setOpenCustomModal(true)
      setError(true)
      setInfoMessage('COMPLETARE CAMPI OBBLIGATORI')
      setTimeout(function () {
        setInfoMessage('')
        setDisabilitaBottoneSalva(false)
        setOpenCustomModal(false)
      }, 4000)
    }
    //relazione = Motivazione dell'adeguata verifica rafforzata---> tipo stringa
    else if (params.relazione === '' || undefined) {
      setOpenCustomModal(true)
      setError(true)
      setInfoMessage('Inserire almeno una motivazione')
      setTimeout(function () {
        setInfoMessage('')
        setDisabilitaBottoneSalva(false)
        setOpenCustomModal(false)
      }, 4000)
    } else if (
      params.relazione.includes('Altro') &&
      (params.dettaglioRelazione === '' || undefined)
    ) {
      setOpenCustomModal(true)
      setError(true)
      setInfoMessage('Completare Dettaglio relazione')
      setTimeout(function () {
        setInfoMessage('')
        setDisabilitaBottoneSalva(false)
        setOpenCustomModal(false)
      }, 4000)
    } 
    else if ( params.codiceFiscale === '' ) 
    {
      setOpenCustomModal(true)
      setErrorCF(true)
      setError(true)
      setInfoMessage('Inserire Codice Fiscale')
      setTimeout(function () {
        setInfoMessage('')
        setDisabilitaBottoneSalva(false)
        setOpenCustomModal(false)
      }, 4000)
    } else if (
      params.ragioneSociale !== '' &&
      params.ragioneSociale.length > 100
    ) {
      setOpenCustomModal(true)
      setError(true)
      setInfoMessage('Ragione Sociale non può contenere più di 100 caratteri')
      setTimeout(function () {
        setInfoMessage('')
        setDisabilitaBottoneSalva(false)
        setOpenCustomModal(false)
      }, 4000)
    } else if (
      params.documentiOpzionaliVari !== undefined &&
      params.documentiOpzionaliVari.length !== 0 &&
      (params.motivazioneRichiesteOpzionali === '' || undefined)
    ) {
      setOpenCustomModal(true)
      setError(true)
      setInfoMessage('Completare Motivazioni richeste opzionali')
      setTimeout(function () {
        setInfoMessage('')
        setDisabilitaBottoneSalva(false)
        setOpenCustomModal(false)
      }, 4000)
    } else {
      setInfoMessage('')
      setError(false)
      setLoadingModal(true)
      setOpenCustomModal(true)
      setDisabilitaBottoneSalva(true)

      addAdeguataVerifica(params)
        .then(response => {
          if (response.data.code === 200) {
            setError(false)
            setLoadingModal(false)
            setInfoMessage(response.data.message)
            setTimeout(function () {
              navigate('/')
            }, 4000)
          }
        })
        .catch(error => {
          if (error.response.data.messageType === "ERROR")
           {
           
            setInfoMessage(error.response.data.message)
            setLoadingModal(true)
            setError(true)
            setDisabilitaBottoneSalva(true)
            setOpenCustomModal(true)
            setTimeout(function () {
              setInfoMessage('')
              setDisabilitaBottoneSalva(false)
              setOpenCustomModal(false)
            }, 6000)
          } else {
            setLoadingModal(false)
            setError(true)
            setInfoMessage('Campi inseriti non validi!')
            setTimeout(function () {              
              setDisabilitaBottoneSalva(false)
              setOpenCustomModal(false)
              setError(false)
              setInfoMessage('')
            }, 4000)
          }
        })
    }
  }

  return (
    <>
      <Header
        showHome
        showRichiesta={false}
        header={
          <>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              justifyContent="start"
              alignContent="start"
            >
              <Typography
                sx={{ flexGrow: 1 }}
                xs={12}
                item
                alignItems="center"
                mb={'1rem'}
                color={theme.palette.black.main}
                variant="h1"
              >
                NUOVA RICHIESTA ADVR
              </Typography>
            </Grid>
          </>
        }
      />
      <AdeguataVerificaForm
        onClick={(event, params) => handleSubmit(event, params)}
        errorState={error}
        responseMessage={responseMessage}
        disabilitaBottoneSalva={disabilitaBottoneSalva}
      />
      <CustomModal
        open={openCustomModal}
        loading={loadingModal}
        error={error}
        message={infoMessage}
      />
    </>
  )
}

export default NuovaRichiestaForm
