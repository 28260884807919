import React, { useState,useRef } from 'react'
import {
    Box,
    Avatar,
    Badge
} from '@mui/material'
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import RichiesteSupportoContainer from '../pages/components/RichiesteSupportoContainer';
import './FooterMonitoraggio.css'


const FooterMonitoraggio = ({ idNodoPratica }) => {

  const [slideDownValue, setSlideDownValue] = useState(false);
  const [showRichiesteSupporto, setShowRichiesteSupporto] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [userLogged] = useState(JSON.parse(localStorage.getItem('user')));
  const [rispostaSupporto, setRispostaSupporto] = useState(false);

  const openRichiesteSupporto = (rispostaSupporto) => {
    setSlideDownValue(true);
    setRispostaSupporto(rispostaSupporto);
    setTimeout(function () {
      setShowRichiesteSupporto(true);
    }, 250);
    
    if(notificationCount > 0){
      // setRichiestaSupportoAsViewed(idNodoPratica, userLogged);
      setNotificationCount(0);
    }
  };

  const closeRichiesteSupporto = () => {
    setSlideDownValue(false);
    setShowRichiesteSupporto(false);
  };

  return(
    <>
      { /* Remove conditional rendering based on listRichiesteSupporto */ }
      <Box
        className={'footer ' + (slideDownValue === true ? 'animationSlideDownAndUp' : '')} 
        sx={{ alignSelf: 'end', marginRight: '3%', position: 'fixed', bottom: '0px', right: '0px' }}
      >
        {showRichiesteSupporto && 
          <RichiesteSupportoContainer
            showRichiesteSupporto={showRichiesteSupporto}
            closeRichiesteSupporto={closeRichiesteSupporto}
            idNodoPratica={idNodoPratica}
            // listRichiesteSupporto={listRichiesteSupporto} // Remove this prop
            rispostaSupporto={rispostaSupporto}
          />
        }

        {/* {!showRichiesteSupporto && 
          <Badge badgeContent={notificationCount ? notificationCount : 0} invisible={!notificationCount || notificationCount <= 0} sx={{ marginY: '2em' }} >
            <Avatar
              onClick={openRichiesteSupporto}
              sx={{ margin: 'auto', backgroundColor: '#0073B1', textAlign: 'center', padding: '20px', width: '65px', height: '65px' }}
            >
              <ContactSupportIcon sx={{ fontSize: 'xxx-large' }} />
            </Avatar>
          </Badge>
        }    */}
      </Box>
    </>
  );
};

export default FooterMonitoraggio;