import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline } from '@mui/material'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
      
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
        <CssBaseline />
      </QueryClientProvider>
    </BrowserRouter>
 
)
