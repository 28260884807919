import { createTheme } from '@mui/material'
import React from 'react'

export const mainTheme = createTheme({
    palette: {
        blue: {
            main: '#1F6A95',
            dark: '#3C4982',
            middle: '#BDD1E4',
            light: '#D5E1F1'

        },
        grey: {
            dark: '#C9C9C7',
            light: '#EBEBEA',
        },
        yellow: {
            dark: '#A49670',
            middle: '#D6CAA5',
            light: '#ef6c00',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        success: { main: '#51BA7F', contrastText: '#FFFFFF', dark: '#3D9B66' },
        error: { main: '#CB3C2E' },
        warning: { main: '#F0CE34' },
        info: { main: '#1F85BB' },
        greyDarker: { main: '#9AA3AE', contrastText: '#FFFFFF', dark: '#7E8997' },
        greyLighter: { main: '#F4F4F4' },
        black: { main: '#353B4A', contrastText: '#FFFFFF', light: '#454B59' },
    },
    typography: {
        fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
        color: '#353B4A',
        button: {
            fontSize: '0.6875rem',
            fontWeight: 700,
            letterSpacing: '0.0625rem',
        },
        h1: {
            fontSize: '1.75rem',
            lineHeight: '2.5rem',
            fontWeight: 400,
        },
        h2: {
            fontSize: '1.5rem',
            lineHeight: '2.125rem',
            fontWeight: 700,
        },
        h3: {
            fontSize: '1.25rem',
            lineHeight: '1.8125rem',
            fontWeight: 700,
        },
        h4: {
            fontSize: '1rem',
            lineHeight: '1.4375rem',
            fontWeight: 700,
        },
        h5: {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            fontWeight: 700,
        },
        h6: {
            fontSize: '0.75rem',
            lineHeight: '1rem',
            fontWeight: 700,
        },
        body1: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        body2: {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
        },
        body3: {
            fontSize: '0.6875rem',
            lineHeight: '1rem',
        },
        label1: {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '0.0625rem',
        },
        label2: {
            fontSize: '0.6875rem',
            lineHeight: '1rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '0.0625rem',
        },
    },
    transitions: { duration: { enteringScreen: 0 } },
    components: {
        // app global styles
        MuiCssBaseline: {
            styleOverrides: theme => ({
                '::-webkit-scrollbar': {
                    backgroundColor: 'transparent',
                    width: '16px',
                },
                '::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                },
                '::-webkit-scrollbar-thumb': {
                    backgroundColor: '#babac0',
                    borderRadius: '16px',
                    border: '5px solid rgba(0,0,0,0)',
                    backgroundClip: 'padding-box',
                },
                '::-webkit-scrollbar-button': {
                    display: 'none',
                },
                ':root': {
                    '--toastify-color-success': theme?.palette?.secondary?.main,
                    '--toastify-color-warning': theme?.palette?.warning?.main,
                    '--toastify-color-error': '#EBADA7',
                    '--toastify-toast-width': '750px',
                    '--toastify-toast-min-height': '42px',
                    '--toastify-toast-max-height': '800px',
                    '--toastify-text-color-error': theme?.palette?.error?.main,
                },
                'body, #root': {
                    height: '100vh',
                    width: '100vw',

                }
            }),
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                disableFocusRipple: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    height: '35px',
                    minWidth: '170px',
                },
                sizeSmall: {
                    fontSize: '0.625rem',
                    height: '23px',
                    minWidth: 'auto',
                    padding: '3px 15px',
                },
                outlined: ({ ownerState: { color = 'inherit' }, theme }) => {
                    const textColor =
                        color === 'greyDarker'
                            ? { color: theme.palette?.greyDarker?.dark }
                            : {}

                    return {
                        ...textColor,
                        borderColor: theme.palette[color].main,
                        '&:hover': {
                            backgroundColor: theme.palette[color].dark,
                            borderColor: theme.palette[color].dark,
                            color: theme.palette[color].contrastText,
                        },
                    }
                },
                text: ({ ownerState: { color = 'inherit' }, theme }) => {
                    const textColor =
                        color === 'greyDarker'
                            ? { color: theme.palette?.greyDarker?.dark }
                            : {}

                    return {
                        ...textColor,
                        minWidth: 'auto',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme.palette[color].dark,
                        },
                    }
                },
            },
        },
        MuiButtonGroup: {
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
                disableFocusRipple: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                icon: React.createElement('div'),
                checkedIcon: React.createElement('div'),
                disableRipple: true,
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundClip: 'content-box, padding-box',
                    border: `1px solid ${theme.palette?.greyDarker?.main}`,
                    borderRadius: 0,
                    boxSizing: 'border-box',
                    height: '16px',
                    padding: '3px',
                    marginRight: '3px',
                    width: '16px',
                    '&.Mui-checked:before': {
                        content: '""',
                        display: 'inline-block',
                        transform: 'rotate(45deg)',
                        height: '12px',
                        width: '16px',
                        margin: '0 1px 2px 0',
                        borderBottom: `3px solid ${theme.palette?.primary?.main}`,
                        borderRight: `3px solid ${theme.palette?.primary?.main}`,
                    },
                }),
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // width: '100%', -> commentato per fixare bug larghezza sul bottone nel filtra del DataGrid
                    // explicitly set MuiFormControl-root otherwise it matched only for same input type (ie. textfield + textfield)
                    '&+.MuiFormControl-root': {
                        marginTop: '16px',
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },


                label: ({ theme }) => ({
                    color: theme.palette?.black?.main,
                    fontSize: '14px',
                    marginLeft: '8px',
                }),
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette?.black?.main,
                    fontWeight: 700,
                    fontSize: '11px',
                    textTransform: 'uppercase',
                    '&.Mui-focused': {
                        color: theme.palette?.black?.main,
                    },
                }),
            },
        },

        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                root: {
                    width: '100%',
                },
                input: {
                    padding: '6px 12px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderWidth: '1px',
                    borderColor: theme.palette?.greyDarker?.main,
                    borderStyle: 'solid',
                    fontSize: '14px',
                    'label+&': {
                        marginTop: '20px !important',
                    },
                }),
                error: ({ theme }) => ({
                    borderColor: theme.palette?.error?.main,
                }),
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    transform: 'none',
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                icon: React.createElement('div'),
                checkedIcon: React.createElement('div'),
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundClip: 'content-box, padding-box',
                    border: `1px solid ${theme.palette?.greyDarker?.main}`,
                    boxSizing: 'border-box',
                    height: '16px',
                    marginRight: '3px',
                    padding: '3px',
                    width: '16px',
                    '&.Mui-checked': {
                        backgroundImage: `linear-gradient(to bottom, ${theme?.palette?.primary?.main} 0%, ${theme?.palette?.primary?.main} 100%), linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)`,
                    },
                }),
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
                InputLabelProps: {
                    marginTop: '20px !important',
                    shrink: true,
                },
                SelectProps: {
                    MenuProps: {
                        PaperProps: {
                            elevation: 4,
                            sx: theme => ({
                                borderRadius: '0px',
                                // boxShadow: 'none',
                                border: `1px solid ${theme?.palette?.greyDarker?.main}`,
                            }),
                        },
                        MenuListProps: {
                            variant: 'menu',
                            sx: theme => ({
                                '& .MuiMenuItem-root': {
                                    fontSize: '14px',
                                    '&:hover': {
                                        backgroundColor: theme?.palette?.blue?.main,
                                        color: '#fff',
                                    },
                                },
                                '& .MuiMenuItem-root.Mui-selected, & .MuiMenuItem-root.Mui-selected:focus':
                                {
                                    backgroundColor: 'transparent',
                                    color: 'inherit',
                                    '&:hover': {
                                        backgroundColor: theme?.palette?.blue?.middle,
                                        color: '#fff',
                                    },
                                },
                            }),
                        },
                    },
                },
            },
        },
        MuiSwitch: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiChip: {
            styleOverrides: {
                root: ({ theme }) => ({
                    py: 0.25,
                    borderRadius: 0,
                }),
            }
        }
    }
}
)