import CircleIcon from '@mui/icons-material/Circle';
import { green, grey, red, yellow } from '@mui/material/colors';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { Tooltip } from '@mui/material';


function DataTable({indicatoriPratica}){
    // debugger
    var totalCol = []
    var rows =[]
    var valueRows ={}
    const codificaColori = (color) => {
        if(color ==='Gray' ||color ==='Grey'){
          return grey[500]
    
        }
        else if(color ==='Green'){
          return green[500]
    
        }
        else if(color ==='Yellow'){
          return yellow[500]
    
        }
        else if(color==='Red'){
          return red[500]
          
        }
    }   
        const columns1 = [{ field: 'INDICATORI', headerName: 'INDICATORI', width:200 , renderCell: (params) => (<Tooltip title={params.value}><span style={{whiteSpace:"nowrap", overflow: "hidden",
          textOverflow: "ellipsis"}}>{params.value}</span></Tooltip>)} ,{field: 'ACCOUNT', headerName: 'ACCOUNT', width: 130,
        renderCell: (params) => (
        <CircleIcon sx={{ color: codificaColori(params.value) }} />
      )}]
    const columns2 = indicatoriPratica.contacts?.map(c => ({ field: c.oploncodicefiscalec, headerName: c.name, width: 130,
    renderCell: (params) => (
        <CircleIcon sx={{ color: codificaColori(params.value) }} />
      ) }));

        totalCol = columns1.concat(columns2);
        if(columns2){
            Object.keys(indicatoriPratica?.indicatori).map((name,i)=> {
                valueRows = Object.assign({['id']:i}) 
                for (var z = 0; z < totalCol?.length; z++) {                                            
                    // debugger                                           
                        if(totalCol[z].field==='ACCOUNT'){
                            valueRows = Object.assign(valueRows, {[totalCol[z].field]:indicatoriPratica?.account.listIndicatori[i]} ) 
                            
                        }
                        else if(totalCol[z].field==='INDICATORI'){
                            valueRows = Object.assign(valueRows, {[totalCol[z].field]:indicatoriPratica?.indicatori[name]} )
                        }
                        else if(totalCol[z].field!=='INDICATORI' && totalCol[z].field!=='ACCOUNT'){ 
                                valueRows = Object.assign(valueRows, {[totalCol[z].field]:indicatoriPratica?.contacts[z-2].indicatori[i]} )                                                      
                        }
                              
                }
                rows.push(valueRows)
                //debugger
                //console.log(rows)
            })
            //console.log(rows)
        }                            
    return (
        <DataGrid
        rows={rows}
        columns={totalCol}
        disableColumnFilter={true}
        headerHeight={30} autoHeight={true}
        disableColumnMenu={true} hideFooter={true}
        hideFooterPagination={true}/>
            );
};
export default DataTable; 