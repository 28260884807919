import { customAxios } from "./customAxios";

export function getOptionalFile(path) {
    const config = {
        headers: {
            Authtoken: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json'
        },
        params: {
            path: path
        },
        responseType: 'blob' // Assicurati che la risposta sia in formato blob per il download
    };

    return customAxios.get(process.env.REACT_APP_BACKEND_HOST + "/adv/monitoraggio/documenti-opzionali/file", config);
}
