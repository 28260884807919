import { useState } from 'react';
import { Typography, Stack, IconButton, Box } from '@mui/material';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { ZoomIn, ZoomOut, PlayArrow, Download, Close } from '@mui/icons-material';

export default function PDFViewerMonitoraggio({ open, onClose, document, title }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  function handleZoomIn(event) {
    event.preventDefault();
    setScale(scale + 0.3);
  }

  function handleZoomOut(event) {
    event.preventDefault();
    setScale(scale - 0.3);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    const newPageNumber = pageNumber + offset;
    if (newPageNumber > 0 && newPageNumber <= numPages) setPageNumber(newPageNumber);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function handleDownload() {
    const linkSource = `data:application/pdf;base64,${document}`;
    const globalDocument = window.document; // Usa un alias per il document globale
    const downloadLink = globalDocument.createElement('a');    downloadLink.href = linkSource;
    downloadLink.download = title;
    downloadLink.click();
  }

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
      <Stack sx={{ alignItems: 'center', position: 'relative' }}>
        <Stack
          direction="row"
          sx={{
            position: 'fixed',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'rgba(255, 255, 255, 0.8)',
            padding: '10px',
            borderRadius: '10px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
            zIndex: 1000,
          }}
        >
          <IconButton sx={{ paddingX: '5px' }} onClick={previousPage} disabled={pageNumber <= 1}>
            <PlayArrow sx={{ transform: 'scaleX(-1)' }} />
          </IconButton>
          <IconButton onClick={handleZoomOut}>
            <ZoomOut />
          </IconButton>
          <IconButton onClick={handleDownload}>
            <Download />
          </IconButton>
          <IconButton onClick={handleZoomIn}>
            <ZoomIn />
          </IconButton>
          <IconButton sx={{ paddingX: '5px' }} onClick={nextPage} disabled={pageNumber >= numPages}>
            <PlayArrow />
          </IconButton>
          <Typography variant="caption" sx={{ alignSelf: 'center', paddingLeft: '10px' }}>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <Box sx={{ width: '100%', height: 'calc(100vh - 60px)', overflow: 'auto', mt: '60px' }}>
          <Stack sx={{ alignItems: 'center' }}>
            <Typography>{title}</Typography>
            <Document
              file={`data:application/pdf;base64,${document}`}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page height={1000} pageNumber={pageNumber} scale={scale} />
            </Document>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
