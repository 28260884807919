import { Grid, Typography } from '@mui/material'
import Header from '../UI/Header'
import { useTheme } from '@mui/material/styles'
import React, { useEffect } from 'react'


function NotFound() {
  const theme = useTheme()
  const errorCode = localStorage.getItem('errorCode') || 404
  const errorMessage = localStorage.getItem('errorMessage') || 'Il contenuto ricercato non è stato trovato.'

  useEffect(() => {
    // Funzione per svuotare l'errore dal localStorage quando si esce dalla pagina
    return () => {
      localStorage.removeItem('errorCode')
      localStorage.removeItem('errorMessage')
    }
  }, [])

  return (
    <>
      <Header
        showHome
        header={
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            justifyContent="start"
            alignContent="start"
          >
            <Typography
              sx={{ flexGrow: 1 }}
              xs={12}
              item
              alignItems="center"
              mb={'1rem'}
              color={theme.palette.black.main}
              variant="h1"
            >
              {errorCode === 404 ? 'NOT FOUND' : 'ERRORE'}
            </Typography>
          </Grid>
        }
      />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        mt={14}
        justifyContent="center"
        alignContent="center"
      >
        <Grid item xs={4} justifyItems="center" alignItems="center">
          <Typography margin="auto" variant="h1">
            {errorCode}
          </Typography>
          <Typography variant="h4">
            {errorMessage}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default NotFound
