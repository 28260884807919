import { customAxios } from "./customAxios";

export function deleteOptionalFile(idMonitoraggio, path) {
    const config = {
        headers: {
          Authtoken: JSON.parse(localStorage.getItem('token')),
          'Content-Type': 'application/json'
        }
      };
    
      const data = {
        idMonitoraggio: idMonitoraggio,
        path: path
      };
    
      return customAxios.delete(process.env.REACT_APP_BACKEND_HOST + "/adv/monitoraggio/documenti-opzionali/file", {
        headers: config.headers,
        data: data
      });
}
