import { customAxios } from './customAxios'
export function annullamentoPratica(idNodoPratica) {
    const config = {
        params:  {
            idNodoPratica: idNodoPratica
        },
        headers: {
            "AuthToken": JSON.parse(localStorage.getItem('token')),
        }
    }
    const annullaPratica =  customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/"+process.env.REACT_APP_PATH_NEW_PREFIX+"/annullamentoPratica?", null, config)
    return annullaPratica
}