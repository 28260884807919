import { customAxios } from './customAxios'
export function addAdeguataVerifica(formValues) {
    const config = {
        headers: {
            AuthToken: JSON.parse(localStorage.getItem('token')),
        },
    }
    const data = {
        citta: formValues.Citta,
        codiceFiscale: formValues.codiceFiscale,
        idNodoPratica: '',
        indirizzo: formValues.indirizzo,
        nazione: formValues.nazione,
        ndg: formValues.NDG,
        nomePratica: formValues.nomePratica,
        ragioneSociale: formValues.ragioneSociale,
        statoRichiesta: '',
        tipologiaCliente: formValues.tipologiaCliente,
        tipologiaDiRichiesta: formValues.tipologiaDiRichiesta,
        tipologiaOperativita: formValues.tipologiaOperativita,
        relazione: formValues.relazione,
        rafforzataAutomatica: formValues.rafforzataAutomatica,
        daAssegnare: formValues.daAssegnare,
        documentiOpzionaliVari: formValues.documentiOpzionaliVari,
        motivazioneRichiesteOpzionali: formValues.motivazioneRichiesteOpzionali,
        dettaglioRelazione: formValues.dettaglioRelazione,
    }

    const adeguataVerificaRequest = customAxios.post(
        process.env.REACT_APP_BACKEND_HOST + '/'+process.env.REACT_APP_PATH_NEW_PREFIX+'/addAdeguataVerifica',
        data,
        config,
    )
    return adeguataVerificaRequest
}
