import React from 'react'
import { Box } from '@mui/material'

import homepageLogoADV from '../assets/logo-bancaprogetto.png'
import homepageLogoComplify from '../assets/adv_complify.png'

const logo = process.env.NODE_ENV !== 'production' ? homepageLogoComplify : homepageLogoADV;

const LogoComponent = () => {
    return (
        <>
            <Box
                ml="20%"
                component="img"
                sx={{
                    width: {
                        xs: '90%',   
                        sm: '80%',   
                        md: '70%',   
                        lg: '60%',   
                    },
                    maxWidth: '500px', 
                    height: 'auto',   
                    objectFit: 'contain',
                    paddingTop: '5px',
                }}
                alt="Logo"
                src={logo}
            />
        </>
    )
}

export default LogoComponent
