import React, { useEffect } from 'react'
import { useState, useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Tooltip, Typography } from '@mui/material'
import { useMemo } from 'react'
import {Box}  from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { LOCALE_TEXT_ITA } from './LOCALE_TEXT_ITA'
import { AuthContext } from '../App'

const ResultsTableAccounting = props => {
  const { dispatch } = useContext(AuthContext)
  const theme = useTheme()

  const columns = useMemo(
    () => [
      {
        field: 'folder_model:NDG',
        headerName: 'NDG',
        type: 'string',
        minWidth: 10,

        valueGetter: params => params.row.entry.properties['folder_model:NDG'],
        renderCell: params => (
          <Tooltip
            title={params.row.entry.properties['folder_model:NDG']}
            followCursor
          >
            <Typography className="table-cell-trucate">
              {params.row.entry.properties['folder_model:NDG']}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'folder_model:codiceFiscale',
        headerName: 'Codice Fiscale',
        minWidth: 120,
        flex: 0,
        valueGetter: params =>
          params.row.entry.properties['folder_model:codiceFiscale'],

          renderCell: params => (
            <Tooltip
              title={params.row.entry.properties['folder_model:codiceFiscale']}
              followCursor
            >
              <Typography className="table-cell-trucate">
                {params.row.entry.properties['folder_model:codiceFiscale']}
              </Typography>
            </Tooltip>
          ),
      },
      {
        field: 'folder_model:ragioneSociale',
        headerName: 'Ragione Sociale',
        minWidth: 250,
        flex: 1,
        valueGetter: params =>
          params.row.entry.properties['folder_model:ragioneSociale'],

        renderCell: params => (
          <Tooltip
            title={params.row.entry.properties['folder_model:ragioneSociale']}
            followCursor
          >
            <Typography className="table-cell-trucate">
              {params.row.entry.properties['folder_model:ragioneSociale']}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'daAsssegnare',
        headerName: 'Sale/Field Analyst',
        minWidth: 90,
        sortable: false,
        flex: 0.5,
        valueGetter: params =>
          params.row.entry.properties['folder_model:daAssegnare'],

          renderCell: params => (
            <Tooltip
              title={params.row.entry.properties['folder_model:daAssegnare']}
              followCursor
            >
              <Typography className="table-cell-trucate">
                {params.row.entry.properties['folder_model:daAssegnare']}
              </Typography>
            </Tooltip>
          ),
      },
      {
        field: 'dataUltimaPratica',
        type: 'date',
        headerName: 'Data Apertura',
        minWidth: 40,
        flex: 0,
        valueGetter: params =>
          params?.row.entry.createdAt,
          renderCell: params => (
            <Tooltip
              title={new Date(params?.row.entry.createdAt).toLocaleDateString()}
              followCursor
            >
              <Typography className="table-cell-trucate">
                {new Date(params?.row.entry.createdAt).toLocaleDateString()}
              </Typography>
            </Tooltip>
          ),
      },
      {
        field: 'folder_model:statorichiesta',
        headerName: 'Stato Pratica',
        minWidth: 80,
        sortable: false,
        flex: 0.5,
        valueGetter: params =>
          params.row.entry.properties['folder_model:statorichiesta'],
          renderCell: params => (
            <Tooltip
              title={params.row.entry.properties['folder_model:statorichiesta']}
              followCursor
            >
              <Typography className="table-cell-trucate">
                {params.row.entry.properties['folder_model:statorichiesta']}
              </Typography>
            </Tooltip>
          ),
      },

      {
        field: 'Data Chiusura',
        headerName: 'Data Chiusura',
        minWidth: 70,
        sortable: false,
        flex: 0.5,
        valueGetter: params =>
          params.row.entry.properties['folder_model:dataChiusura'],
          renderCell: params => (
            <Tooltip
  title={
    params?.row.entry.properties['folder_model:dataChiusura']
      ? new Date(params.row.entry.properties['folder_model:dataChiusura']).toLocaleDateString()
      : ""
  }
  followCursor
>
              <Typography className="table-cell-truncate">
  {params?.row.entry.properties['folder_model:dataChiusura']
    ? new Date(params.row.entry.properties['folder_model:dataChiusura']).toLocaleDateString()
    : ""
  }
</Typography>

            </Tooltip>
          ),
      },
      {
        field: 'tipoInserimento',
        headerName: 'Tipo Inserimento',
        minWidth: 70,
        sortable: false,
        flex: 0.5,
        valueGetter: params =>
          params.row.entry.properties['folder_model:tipoInserimento'],
          renderCell: params => (
            <Tooltip
              title={params.row.entry.properties['folder_model:tipoInserimento']}
              followCursor
            >
              <Typography className="table-cell-trucate">
                {params.row.entry.properties['folder_model:tipoInserimento']}
              </Typography>
            </Tooltip>
          ),
      },
    ],
    [],
  )

  const arrayForTable = props.isFetchedListaPratiche && props.listaPratiche
  const rowsForTable = arrayForTable && arrayForTable
  // console.log(rowsForTable)

  useEffect(() => {
    props.refetchListaPratiche()
  }, [])

  return (
    <Box
    sx={{
      height: 526,
      width: '100%',
      '& .utenteBase': {
        backgroundColor: theme.palette.grey.light,
        color: theme.palette.black,
      },
      '& .fieldAnalyst': {
        backgroundColor: theme.palette.blue.light,
        color: theme.palette.black,
      },
    }}
  >
    <DataGrid
      disableSelectionOnClick
      sx={{ cursor: 'url(hand.cur), pointer' }}
      rows={rowsForTable ? rowsForTable : []}
      columns={columns}
      pageSize={10}
      loading={props.isFetchingListaPratiche}
      rowsPerPageOptions={[10]}
      getRowId={row => row.entry.id}
      localeText={LOCALE_TEXT_ITA}
      onRowClick={props.onRowClick}
      sortingOrder={['asc', 'desc']}
  />
    </Box>
  )
}

export default ResultsTableAccounting
