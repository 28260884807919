import { customAxios } from './customAxios'
export function getListaPratiche() {
    const config = {
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }
    const fetchListaPratiche = customAxios.get(process.env.REACT_APP_BACKEND_HOST + "/"+process.env.REACT_APP_PATH_NEW_PREFIX+"/getListaPratiche", config).then(res => res.data.body)
    return fetchListaPratiche
}