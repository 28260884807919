import { customAxios } from './customAxios'
export function getIndicatoriAdvAutomatica(idNodoPratica) {
  const config = {
    params: { idNodoPratica: idNodoPratica.toString() },
    headers: {
      Accept: '*/*',
      AuthToken: JSON.parse(localStorage.getItem('token')),
    },
  }
  const getIndicatoriAdvAutomatica = customAxios
    .get(
      process.env.REACT_APP_BACKEND_HOST +
        `/adv/getIndicatoriADVAutomatica`,
      config,
    )
    .then(response => response.data.body)
  return getIndicatoriAdvAutomatica
}
