import React, { useState, useEffect } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IconButton, Stack, Box } from '@mui/material';
import { uploadDocumentMonitoraggio } from '../../services/uploadDocumentMonitoraggio';
import CustomModal from '../../UI/CustomModal';

function UploadDocumentMonitoraggio({ idMonitoraggio, idTipologia, disabled, onUpload }) {
    const [selectedDocument, setSelectedDocument] = useState();
    const [loading, setLoading] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [error, setError] = useState(false);
    const [uploading, setUploading] = useState(false); // Stato per tracciare se la chiamata è in corso
    const [isUploadComplete, setIsUploadComplete] = useState(false); // Stato per tracciare se l'upload è completo

    const handleSelection = (event) => {
        event.preventDefault();
        const document = event.target.files[0];
        setSelectedDocument(document);
        setIsFileSelected(true);
        setIsUploadComplete(false); // Resetta lo stato di upload completato
    };

    useEffect(() => {
        if (selectedDocument !== undefined && !uploading && !isUploadComplete) {
            setUploading(true); // Imposta lo stato di caricamento
            setLoading(true);
            const file = new FormData();
            file.append('file', selectedDocument);
            uploadDocumentMonitoraggio(file, idMonitoraggio, idTipologia)
                .then((response) => {
                    setLoading(false);
                    setError(false);
                    if (response.status === 200) {
                        setInfoMessage('Upload Effettuato');
                        setIsUploadComplete(true); // Imposta lo stato di upload completato
                        setTimeout(() => {
                            setIsFileSelected(false);
                            setSelectedDocument(undefined); // Rimuovi il documento selezionato per evitare trigger del useEffect
                            onUpload();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setInfoMessage("Errore nell'upload del documento");
                    setLoading(false);
                    setError(true);
                    if (error.response) {
                        setTimeout(() => {
                            setIsFileSelected(false);
                            setSelectedDocument(undefined); // Rimuovi il documento selezionato per evitare trigger del useEffect
                        }, 4000);
                    } else {
                        setTimeout(() => {
                            setIsFileSelected(false);
                            setSelectedDocument(undefined); // Rimuovi il documento selezionato per evitare trigger del useEffect
                            setInfoMessage("Errore nell'upload del documento. Verifica la dimensione del file.");
                        }, 4000);
                    }
                })
                .finally(() => {
                    setUploading(false); // Reset lo stato di caricamento
                });
        }
    }, [selectedDocument, idMonitoraggio, idTipologia, onUpload, uploading, isUploadComplete]);

    return (
        <>
            <Stack direction="row" alignItems="center">
                <IconButton disabled={disabled} component="label">
                    <input
                        accept="application"
                        hidden
                        type="file"
                        onClick={(event) => (event.target.value = null)}
                        onChange={(e) => handleSelection(e)}
                    />
                    <CloudUploadIcon />
                </IconButton>
                <Box>
                    <CustomModal
                        hideBackdrop
                        open={isFileSelected}
                        loading={loading}
                        error={error}
                        message={infoMessage}
                    />
                </Box>
            </Stack>
        </>
    );
}

export default UploadDocumentMonitoraggio;
