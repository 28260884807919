import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)(
    {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 'bold',
        height: 'fit-content',
        width: 'fit-content',
        margin: '4px 4px 10px',
        padding: '5px 10px',
        color: '#FFFFFF',
        border: '1px solid',
        borderRadius: '0px 5px 5px 0px',
        lineHeight: 1,
        backgroundColor: '#235F7B',
        borderColor: '#235F7B',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#235F7B',
            borderColor: '#FFFFFF',
            boxShadow: 'none',
            color: '#EEEEEE'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#235F7B',
            borderColor: '#235F7B',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    }
)

