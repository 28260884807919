import { customAxios } from "./customAxios"
export function getAllDocs(idMonitoraggio) {
    const config = {
        headers: {
            Authtoken: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json'
        }
    };

    const url = `${process.env.REACT_APP_BACKEND_HOST}/adv/monitoraggio/documenti-opzionali?idMonitoraggio=${idMonitoraggio}`;

    const getAllDocsRequest = customAxios.get(url, config)
    return getAllDocsRequest
}