import { customAxios } from "./customAxios";

export function getAllElement() {
    const config = {
        headers: {
            Authtoken: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json'
        }
    };

    return customAxios.get(process.env.REACT_APP_BACKEND_HOST + "/config/ui-element", config)
        .then(response => {
            // Salva statiList e livelliDiRischioList nel localStorage
            localStorage.setItem('statiList', JSON.stringify(response.data.statiList));
            localStorage.setItem('livelliDiRischioList', JSON.stringify(response.data.livelliDiRischioList));
            return response;
        })
        .catch(error => {
            console.error("There was an error fetching the elements!", error);
            throw error;
        });
}
