import { customAxios } from './customAxios'
export function getRiepilogoPdfMonitoraggio(idMonitoraggio) {
    const config = {
        params: { idMonitoraggio: idMonitoraggio.toString() },
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token')),
           
        },
    }

    const getRiepilogo =
        customAxios.get(process.env.REACT_APP_BACKEND_HOST + `/`+process.env.REACT_APP_PATH_NEW_PREFIX+`/getRiepilogoPdfMonitoraggio?`, config
        ).then(response => response)
        
    return getRiepilogo
}