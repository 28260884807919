import { Container, Box } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import homepageLogoADV from '../assets/logo-bancaprogetto.png'
import homepageLogoComplify from '../assets/complify-logo.png'

const logo = process.env.NODE_ENV !== 'production' ? homepageLogoComplify : homepageLogoADV;

function TopLogoDiv() {
    const theme = useTheme();
    return (
        <Container sx={{ position: 'absolute', left: 0 }}>
            <Box
                sx={{
                    position: 'sticky',
                    height: 'auto',
                    width: '10rem', 
                    borderRadius: '0px 0px 10px 10px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingBottom: '1rem',
                }}
            >
                <Box
                    component="img"
                    sx={{
                        maxWidth: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                        marginBottom: '0.5rem',
                    }}
                    alt="Logo"
                    src={logo}
                />
            </Box>
        </Container>
    );
}

export default TopLogoDiv;
