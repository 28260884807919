import { customAxios } from './customAxios'
export function getListaPraticheOnboardingOrMonitoraggio() {
    const config = {
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }
    const listaPraticheOnboardingOrMonitoraggio = customAxios.get(process.env.REACT_APP_BACKEND_HOST + "/"+process.env.REACT_APP_PATH_NEW_PREFIX+"/getListaPraticheOnboardingOrMonitoraggio", config).then(res => res.data.body)
    return listaPraticheOnboardingOrMonitoraggio
}