import { customAxios } from './customAxios'

export function downloadDocument(idDocument, attachment) {
  const config = {
    params: { fileId: idDocument, attachment : attachment},
    headers: {
        Accept: "*/*",
        'AuthToken': JSON.parse(localStorage.getItem('token'))
    },
  }
  const downloadDocument = customAxios.get(process.env.REACT_APP_BACKEND_HOST + `/`+process.env.REACT_APP_PATH_NEW_PREFIX+`/downloadDocument?`, config).then(response => response.data.body)
  return downloadDocument
}

