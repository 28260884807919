import { customAxios } from "./customAxios";

export function setRichiestaSupportoAsViewed(idNodoPratica, username) {
    const config = {
        headers: {
            "AuthToken": JSON.parse(localStorage.getItem('token')),
        }
    }
    const data = {
        "idNodoPratica": idNodoPratica,
        "username": username
    }
    
    const setRichiestaSupportoAsViewedResponse = customAxios.post("/"+process.env.REACT_APP_PATH_NEW_PREFIX+"/setRichiestaSupportoAsViewed", data ,config)
    return setRichiestaSupportoAsViewedResponse;
}