import { useContext, useReducer } from 'react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material'
import Login from '../src/pages/Login'
import { createContext } from 'react'
import HomePage from './pages/HomePage'
import { mainTheme } from './Theme'
import Authorize from './pages/Authorize'
import { Routes, Route, Navigate } from 'react-router-dom'
import homepageLogoComplify from './assets/adv_complify.png'
import homepageLogoADV from './assets/logo-bancaprogetto.png'

export const AuthContext = createContext()

const initialState = {
  isAuthenticated: localStorage.getItem('token') ? true : false,
  user: null,
  token: null,
  functions: null,
}


const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('user', JSON.stringify(action.payload.entry.userId))
      localStorage.setItem('token', JSON.stringify(action.payload.entry.id))
      localStorage.setItem('functions',JSON.stringify(action.payload.entry.functionalities))
      localStorage.setItem("passwordExpired",action.payload.entry.passwordExpired)
      localStorage.setItem("isPasswordExpiring",action.payload.entry.isPasswordExpiring)
      // console.table(action.payload)
      return {
        ...state,
        isAuthenticated: localStorage.getItem('token') ? true : false,
        user: action.payload.entry.userId,
        token: action.payload.entry.id,
        functions: action.payload.entry.functionalities,
        passwordExpired: action.payload.entry.passwordExpired
      }
    case 'LOGOUT':
      localStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      }
    default:
      return state
  }
}

// Componente ProtectedRoute
const ProtectedRoute = ({ children }) => {
  const { state } = useContext(AuthContext)
  return state.isAuthenticated ? children : <Navigate to="/login" />
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState)
  // console.log(state)
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={mainTheme}>
{/*        <div
          style={{
            padding: 0,
            border: '0px solid red',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        ></div>*/}
          <CssBaseline />
           {/*{state.isAuthenticated ? <HomePage /> : <Login isAdmin={false}/>}*/}

        <Routes>
          <Route path="/login" element={<Login isAdmin={false} isProcessingOktaLogin={false} /> }/>
          <Route path="/admin" element={<Login isAdmin={true} isProcessingOktaLogin={false} /> }/>
          <Route path="/authorize" element={<Login isAdmin={false} isProcessingOktaLogin={true} /> }/>
          {/*<Route path="/authorize" element={<Authorize />} />
          */}
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />
          </Routes>
      </ThemeProvider>
    </AuthContext.Provider>
  )
}

export default App
