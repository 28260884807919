import { customAxios } from './customAxios'
export function getFoldersTree(idNodoPratica) {
    const config = {
        params: { idNodoPratica: idNodoPratica },
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }
    const getFoldersTreeRequest =
        customAxios.get(process.env.REACT_APP_BACKEND_HOST + `/`+process.env.REACT_APP_PATH_NEW_PREFIX+`/getFoldersTree?`, config).then(response => response.data.body)
    return getFoldersTreeRequest
}