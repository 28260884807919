import React, {  } from 'react'
import { Grid, IconButton } from '@mui/material'
import {
    MainContainer, 
    ChatContainer, 
    MessageList, 
    Message, 
    ConversationHeader,
    MessageSeparator,
    MessageGroup
 } from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import styles from '../../UI/Footer.css'
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns'

const RichiesteSupportoContainer = ({ idNodoPratica, closeRichiesteSupporto, listRichiesteSupporto, rispostaSupporto }) => {

  const messaggioRichiesta = "ha richiesto supporto con il seguente messaggio: "
  const utentiRichiesta = "Inviato ai gruppi: "
  const messaggioRisposta = "ha risposto alla richiesta di supporto con il seguente messaggio: "
  const utenteRisposta = "Inviato all'utente: "

    return(
        <Grid marginLeft={1} marginBottom={'5px'} height={'700px'} width={'550px'} >
            <MainContainer >
                <ChatContainer >
                    <ConversationHeader>
                        <ConversationHeader.Content
                            userName="Richieste di supporto"
                        >
                        </ConversationHeader.Content>
                        <ConversationHeader.Actions>     
                            <IconButton sx={{ paddingX: '5px' }} type="button"  onClick={closeRichiesteSupporto}>
                                <CloseIcon
                                    title="Chiudi Richieste di supporto"
                                ></CloseIcon>
                            </IconButton>
                        </ConversationHeader.Actions>
                    </ConversationHeader>   
                    <MessageList >
                        
                    {listRichiesteSupporto.map((richiesta,i) => (
                           <MessageGroup direction={richiesta.rispostaSupporto ? 'outgoing' : 'incoming'}>
                                <MessageGroup.Messages key={'richiestasupporto-'+i}>
                                    <MessageSeparator className="messageSeparator" content={format(new Date(richiesta.dateRequest), 'dd/MM/yyyy HH:mm')} as="h2" />
                                    <Message 
                                         className={richiesta.rispostaSupporto ? 'risposta' : 'richiesta'} 
                                        // style= {{maxWidth: '450px'}}
                                          model={{
                                            message: `<b>${richiesta.fromName}</b> ${richiesta.rispostaSupporto === true ? messaggioRisposta : messaggioRichiesta } <br><br>"${richiesta.message}"`,
                                            direction: `${richiesta.rispostaSupporto === true ? 'outgoing' : 'incoming' }`
                                          }}/> 
                                    
                                </MessageGroup.Messages>
                                <MessageGroup.Footer style={{flexDirection: richiesta.rispostaSupporto ? "row-reverse" : "row"}}>{(richiesta.rispostaSupporto ? utenteRisposta : utentiRichiesta) + richiesta.groupsTo.join(', ')}</MessageGroup.Footer>
                            </MessageGroup>
                        ))
                    }
                    </MessageList>   
                </ChatContainer>
            </MainContainer>
        </Grid>
    ) 
}

export default RichiesteSupportoContainer
