import { customAxios } from './customAxios';

export function getMonitoraggioByAdv(idAdv) {
    const config = {
        params: { idADV: idAdv },
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    };

    const getMonitoraggioByAdvRequest = customAxios.get(`${process.env.REACT_APP_BACKEND_HOST}/adv/monitoraggio/search-monitoraggiobyadv`, config)
    .then(response => {
        debugger
        // Estrarre inputInizialeList e dataApertura per ciascun elemento della risposta
        return response.data.map(item => ({
            inputInizialeList: item.inputInizialeList, // Estrai inputInizialeList
            dataApertura: item.dataApertura // Estrai dataApertura
        }));
    });
    
    return getMonitoraggioByAdvRequest;
}
