import React, { useRef } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import ProfiloUtente from './ProfiloUtente'
import NuovaRichiesta from './NuovaRichiesta'
import Dashboard from './Dashboard'
import NotFound from './NotFound'
import ModificaPassword from './ModificaPassword'
import SessioneScaduta from './SessioneScaduta'
import { AuthContext } from '../App'
import Login from './Login'
import OnlyWithFunction from '../OnlyWithFunctions'
import { useContext, useEffect } from 'react'
import Dettaglio from './Dettaglio'
import NuovaRichiestaForm from './NuovaRichiestaForm'
import StatistichePanel from './StatistichePanel'
import Accounting from './Accounting'
import { validateToken } from '../services/validateToken'
import TransitionComponentSnackbar from '../UI/CustomSnackbar';
import Monitoring from './Monitoring'
import DettaglioMonitoraggio from './DettaglioMonitoraggio'
import StatisticheMonitoraggio from './StatisticheMonitoraggio'

const HomePage = () => {
  const navigate = useNavigate()
  const { state: AuthState, dispatch } = useContext(AuthContext)
  const tokenValidationTimeoutRef = useRef(null);
  // console.table({ AuthState })

/*  useEffect(() => {
    if (AuthState.isAuthenticated === false) {
      navigate("/login")
    }
  }, [AuthState.isAuthenticated])*/
  // Effettua la verifica del token all'avvio
  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      dispatch({ type: 'LOGOUT' }); // Usa il dispatch per gestire il logout
      navigate("/login");
    }
  }, [navigate, dispatch]);
  // console.log('render')

  // Funzione per validare il token
  const checkToken = async () => {
    try {
      await validateToken();
    } catch (error) {
      console.error("Errore imprevisto nella convalida del token:", error);
    }
  };

// Imposta il timeout per la validazione del token
  useEffect(() => {
    tokenValidationTimeoutRef.current = setTimeout(checkToken, 3600000);

    return () => {
      clearTimeout(tokenValidationTimeoutRef.current);
    };
  }, [dispatch]);

/*  setTimeout(function () {
    validateToken().then(res => {
      if (res.data.body !== "OK") {
        AuthState.isAuthenticated = false
        localStorage.clear()
        window.location.reload()
        //navigate("/login")

      }
    })
  }, 3600000)*/


  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      AuthState.isAuthenticated = false
      navigate("/login")
    }
  }, [localStorage])

  return (
    <div style={{ position: "relative" }}>
      <TransitionComponentSnackbar />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        {/*<Route path="/login" element={<Login isAdmin={false} />} />*/}
        <Route path="dettaglio" element={<Dettaglio />} />
        <Route path="dettaglioMonitoraggio" element={<DettaglioMonitoraggio />} />

        <Route
          path="nuova-richiesta"
          element={
            <OnlyWithFunction acceptedFunction="NUOVA_ADV">
              <NuovaRichiesta />
            </OnlyWithFunction>
          }
        />
        <Route
          path="nuova-richiesta-form"
          element={
            <OnlyWithFunction acceptedFunction="NUOVA_ADV">
              <NuovaRichiestaForm />
            </OnlyWithFunction>
          }
        />
        <Route
          path="/statistiche"
          element={
            <StatistichePanel />
          }
        />
        <Route
          path="/accounting"
          element={
            <Accounting />
          }
        />
        <Route
          path="/sintesiAccounting"
          element={
            <Accounting onlySintesi={true} />
          }
        />
        <Route
          path="/monitoring"
          element={
            <Monitoring />
          }
        />
        <Route
          path="/accounting-monitoraggio"
          element={
            <StatisticheMonitoraggio />
          }
        />
        <Route path="/error" element={<NotFound />} />
        {/*<Route path="/*" element={<NotFound />} />*/}
        <Route path="/profilo-utente" element={<ProfiloUtente />} />
        <Route path="/modifica-password" element={<ModificaPassword />} />
        <Route path="/sessione-scaduta" element={<SessioneScaduta />} />
      </Routes>
    </div>
  )
}

export default HomePage
