import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  Stack,
  Grid,
  Box,
  Typography,
  Tooltip,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Modal,
  Fade,
  Button,
  FormControlLabel,
  Checkbox, TextField, TextareaAutosize, Container,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useTheme } from '@mui/material/styles'
import { StatiPratica } from '../../utils/StatiPratica'
import EsitoMonitoraggio from './EsitoMonitoraggio'
import { StyledButton } from '../../UI/StyledButton'
import { getAllDocs } from '../../services/getAllDocs'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import VisibilityIcon from '@mui/icons-material/Visibility'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import DeleteIcon from '@mui/icons-material/Delete'
import PrintIcon from '@mui/icons-material/Print'
import UploadDocumentMonitoraggio from './UploadDocumentMonitoraggio'
import SummarizeIcon from '@mui/icons-material/Summarize'
import PDFViewerMonitoraggio from '../../Charts/PDFViewerMonitoraggio'
import { getOptionalFile } from '../../services/getOptionalFile'
import { deleteOptionalFile } from '../../services/deleteOptionalFile'
import { getRiepilogoPdfMonitoraggio } from '../../services/getRiepilogoPdfMonitoraggio'
import { OpenPrintPreviewFromByteArray } from '../../utils/OpenPrintPreviewFromByteArray'
import { searchById } from '../../services/searchById'
import { isValid } from 'date-fns'
import { isValidUser } from '../../OnlyWithFunctions'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getAllElement } from '../../services/getAllElement'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { saveMonitoraggio } from '../../services/saveMonitoraggio'
import { closeMonitoraggio } from '../../services/closeMonitoraggio'
import EditIcon from '@mui/icons-material/Edit'
import { BoltOutlined } from '@mui/icons-material'
import CustomModal from '../../UI/CustomModal'
import CloseIcon from '@mui/icons-material/Close'


const formatDate = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleDateString('it-IT') // Formatta la data in formato italiano
}


const AccordionDettaglioMonitoraggio = ({ dettaglioPratica: initialDettaglioPratica }) => {
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [documentiOpzionali, setDocumentiOpzionali] = useState([])
  const [folders, setFolders] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [pdfData, setPdfData] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const theme = useTheme()
  const [dettaglioPratica, setDettaglioPratica] = useState(initialDettaglioPratica)
  const [hasFetchedDetails, setHasFetchedDetails] = useState(false)
  const [isUploading, setIsUploading] = useState(false) // Stato per tracciare se un upload è in corso
  const [hasFetchedFolders, setHasFetchedFolders] = useState(false) // Stato per tracciare se i documenti sono stati caricati
  const [isSaving, setIsSaving] = useState(false)
  const [isYellowSectionExpanded, setIsYellowSectionExpanded] = useState(false) // Controlla l'espansione della sezione gialla
  const [errorMessage, setErrorMessage] = useState('') // Stato per memorizzare il messaggio di errore
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('') // Stato per memorizzare il messaggio di successo
  const [openSuccessModal, setOpenSuccessModal] = useState(false) // Stato per il modale di successo
  const [motivazioneObbligatoria, setMotivazioneObbligatoria] = useState(true)
  const [motivazione, setMotivazione] = useState('')
  const showModalBasedOnState = (dettaglioPratica.status !== 'CHIUSA' && dettaglioPratica.status !== 'APERTA_IN_ATTESA_APPROVAZIONE_DOCUMENTI' && dettaglioPratica.status !== 'APERTA_IN_ATTESA_DOCUMENTI' && dettaglioPratica.status !== 'ADV_IN_CORSO')
  const [infoMessage, setInfoMessage] = useState('')
  const [save, setSave] = useState(false)
  const [loadingRespAzioneRapida,setLoadingRespAzioneRapida]=useState(false)

  const fetchData = async (idMonitoraggio) => {
    if (hasFetchedFolders) return // Evita chiamate multiple
    setLoading(true) // Imposta lo stato di caricamento all'inizio della funzione
    try {
      const response = await getAllDocs(idMonitoraggio)
      const optionalDocsFolder = response.data.children.find(child => child.name.includes('Documenti opzionali'))
      if (optionalDocsFolder) {
        const folderDetails = optionalDocsFolder.children
          .filter(folder => folder.idTipologia && folder.idTipologia !== 0)
          .map(folder => {
            const nameParts = folder.name.split('/')
            const folderName = nameParts[nameParts.length - 2] // Assuming the second-to-last part is the folder name
            return {
              idTipologia: folder.idTipologia,
              name: folderName,
              children: folder.children,
            }
          })
        setFolders(folderDetails)

      }
    } catch (err) {
      console.error('Error fetching folders:', err)
      setError(err.message)
    } finally {
      setLoading(false) // Imposta lo stato di caricamento a false alla fine della funzione
      setHasFetchedFolders(true) // Imposta lo stato di caricamento documenti a true
    }
  }

  useEffect(() => {
    //debugger
    if (!hasFetchedDetails && dettaglioPratica && dettaglioPratica.idMonitoraggio) {
      handleUploadSuccess()
      setHasFetchedDetails(true)
    }
  }, [hasFetchedDetails, dettaglioPratica])

  useEffect(() => {
    //debugger
    if (dettaglioPratica && dettaglioPratica.status) {
      setStatus(dettaglioPratica.status)
    }

    if (dettaglioPratica && dettaglioPratica.idMonitoraggio && !hasFetchedFolders) {
      fetchData(dettaglioPratica.idMonitoraggio)
    }
  }, [dettaglioPratica, hasFetchedFolders])

  const handleUploadSuccess = async () => {
    //debugger
    if (isUploading) return
    setIsUploading(true)

    try {
      if (dettaglioPratica && dettaglioPratica.idMonitoraggio) {
        const newDetails = await searchById(dettaglioPratica.idMonitoraggio)
        console.log(newDetails) // Per verificare cosa viene restituito
        //debugger
        // Inizializza le azioni e pre-seleziona quelle presenti in monitoraggioAzioni
        const azioniFromMonitoraggio = newDetails.monitoraggioAzioni.map((azione) => azione.id)
        setAzioni(newDetails.monitoraggioAzioni)
        setSelectedActions(azioniFromMonitoraggio) // Pre-seleziona le azioni già presenti
        if (azioniFromMonitoraggio.some((value) => value === 8)) {
          setMotivazioneObbligatoria(false);
        } else {
          setMotivazioneObbligatoria(true);
        }
        setStatus(newDetails.status)
        setDettaglioPratica(newDetails)
        setHasFetchedFolders(false)
      }
    } catch (error) {
      console.error('Errore nel caricamento dei dettagli della pratica:', error)
    } finally {
      setIsUploading(false)
    }
  }


  const handleClick = (file) => {
    setSelectedFile(selectedFile === file ? null : file)
  }

  const handleView = async (file) => {
    try {
      const response = await getOptionalFile(file.path)
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64data = reader.result.split(',')[1]
        setPdfData(base64data)
        setOpenModal(true)
      }
      reader.readAsDataURL(blob)
    } catch (error) {
      console.error('Error viewing file:', error)
    }
  }

  const handleClose = () => {
    setOpenModal(false)
    setPdfData(null)
  }

  const handleDownload = async (file) => {
    try {
      const response = await getOptionalFile(file.path)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', file.name)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  const handleDelete = async (file) => {
    try {
      const response = await deleteOptionalFile(dettaglioPratica.idMonitoraggio, file.path)
      const optionalDocsFolder = response.data.children.find(child => child.name.includes('Documenti opzionali'))
      if (optionalDocsFolder) {
        const folderDetails = optionalDocsFolder.children
          .filter(folder => folder.idTipologia && folder.idTipologia !== 0)
          .map(folder => {
            const nameParts = folder.name.split('/')
            const folderName = nameParts[nameParts.length - 2] // Assuming the second-to-last part is the folder name
            return {
              idTipologia: folder.idTipologia,
              name: folderName,
              children: folder.children,
            }
          })
        setFolders(folderDetails)
        const newDetails = await searchById(dettaglioPratica.idMonitoraggio)
        console.log(newDetails) // Aggiungi questo per il debugging

        // Aggiorna lo stato con i nuovi dettagli della pratica
        setStatus(newDetails.status)
        setDettaglioPratica(newDetails)

      }
    } catch (error) {
      console.error('Error deleting file:', error)
    } finally {
      setLoading(false)
    }
  }

  const getPdf = event => {
    event.stopPropagation() // Impedisce la propagazione dell'evento di clic

    try {
      setLoading(true)
      event.preventDefault()
      event.stopPropagation()
      getRiepilogoPdfMonitoraggio(dettaglioPratica.idMonitoraggio).then(res => {
        if (res.data.code === 200) {
          OpenPrintPreviewFromByteArray(res.data.body)
          setLoading(false)
        } else setLoading(false)
      })
    } catch (error) {
      alert(error)
      setLoading(false)
    }
  }

  const handleUpdatePratica = async (newDetails) => {
    try {
      // Aggiorna lo stato della pratica e lo stato corrente
      setStatus(newDetails.status)
      setDettaglioPratica(newDetails)

      // Forza il ricaricamento dei documenti opzionali
      setHasFetchedFolders(false) // Fa sì che fetchData venga richiamato

      // Esegui altre azioni legate agli aggiornamenti degli indicatori, se necessario
      // Ad esempio, puoi ricaricare altri dati specifici se necessario
    } catch (error) {
      console.error('Errore durante l\'aggiornamento dei dettagli:', error)
    }
  }
  // Stato per gestire l'espansione
  // Stati per gestire l'espansione delle due liste
  const [expandedEsito, setExpandedEsito] = useState(false)
  const [expandedInputIniziale, setExpandedInputIniziale] = useState(false)
  const [openModalIndex, setOpenModalIndex] = useState(null)  // Per tracciare quale modale è aperto
  const [azioni, setAzioni] = useState([])  // Per memorizzare le azioni caricate dall'API
  const [loadingAzioni, setLoadingAzioni] = useState(false)
  const [selectedActions, setSelectedActions] = useState([])  // Per tracciare le azioni selezionate
  const [isGestorePratica,setIsGestorePratica] = useState(false)
  // Funzioni per fare toggle dell'espansione
  const toggleExpandedEsito = (event) => {
    setExpandedEsito(!expandedEsito)
    event.stopPropagation()
  }

  const toggleExpandedInputIniziale = (event) => {
    setExpandedInputIniziale(!expandedInputIniziale)
    event.stopPropagation()
  }

  // Funzione per aprire il modale
  const handleOpenModal = async (index) => {
    //debugger
    setOpenModalIndex(index)  // Imposta il modale aperto
    setLoadingAzioni(true)    // Mostra il caricamento
    //debugger
    try {
      const response = await getAllElement(index)  // Chiama l'API per ottenere le azioni
      let filteredAzioni
      if (dettaglioPratica.status === 'ADV_OBBLIGATA') {
        filteredAzioni = response.data.azioneList.filter(
          (azione) => azione.id === 8 || azione.id === 10,
        )
/*        if(dettaglioPratica.idAzioneList.some(id => id === 8))
          setMotivazioneObbligatoria(false)*/
      } else if (dettaglioPratica.status === 'ADV_IN_CORSO') {
        filteredAzioni = response.data.azioneList.filter(
          (azione) => azione.id === 1,
        )
      } else if (dettaglioPratica.status === 'CHIUSA') {
        filteredAzioni = response.data.azioneList

      }else if(dettaglioPratica.status === 'INFO_COMPLETE'){
        filteredAzioni = response.data.azioneList.filter(
          (azione) => azione.id !== 8 && azione.id !==10
        )
      } else {
        filteredAzioni = response.data.azioneList.filter(
          (azione) => azione.id !== 8 && azione.id !== 10 && azione.id !== 9
        )
      }
      setMotivazione(dettaglioPratica.motivazione)
      setAzioni(filteredAzioni)  // Salva le azioni nel tuo stato
    } catch (error) {
      console.error('Errore durante il caricamento delle azioni:', error)
    } finally {
      setLoadingAzioni(false)  // Nascondi il caricamento
    }
  }

  // Funzione per chiudere il modale
  const handleCloseModal = (event) => {
    setOpenModalIndex(null) // Chiudi tutti i modali
    event.stopPropagation()
  }
// Funzione per gestire il cambiamento dello stato delle checkbox
  const handleCheckboxChange = (azioneId) => {
    //debugger
    let newSelectedActions = [...selectedActions];

    if (azioneId === 8) {
      // Se selezioniamo o deselezioniamo 8
      if (newSelectedActions.includes(8)) {
        // Se 8 è già selezionato, lo deselezioniamo e impostiamo setMotivazioneObbligatoria a true
        newSelectedActions = [];
        setMotivazioneObbligatoria(true);
      } else {
        // Selezioniamo solo 8 e impostiamo setMotivazioneObbligatoria a false
        newSelectedActions = [8];
        setMotivazioneObbligatoria(false);
      }
    } else if (azioneId === 10) {
      // Se selezioniamo o deselezioniamo 10
      if (newSelectedActions.includes(10)) {
        // Se 10 è già selezionato, lo deselezioniamo
        newSelectedActions = [];
      } else {
        // Selezioniamo solo 10
        newSelectedActions = [10];
      }
      setMotivazioneObbligatoria(true); // Sempre true quando si interagisce con 10
    } else {
      if (dettaglioPratica.status === 'INFO_COMPLETE') {
        // Stato INFO_COMPLETE: mantieni l'azione 9 e consenti la selezione di un'altra azione
        if (azioneId !== 9 && !newSelectedActions.includes(azioneId)) {
          // Aggiungi l'azione selezionata mantenendo 9 selezionato
          newSelectedActions = [9, azioneId];
        } /*else if (azioneId === 9 && newSelectedActions.includes(azioneId)) {
          // Deseleziona 9 se è già selezionata
          newSelectedActions = newSelectedActions.filter(id => id !== 9);
        }*/
      } else {
        // Per altri stati: consenti solo la selezione di un'azione alla volta
        if (newSelectedActions.includes(azioneId)) {
          // Deseleziona tutto
          newSelectedActions = [];
        } else {
          // Seleziona solo l'azione corrente
          newSelectedActions = [azioneId];
        }
      }
      setMotivazioneObbligatoria(true);
    }

    // Imposta il nuovo array di azioni selezionate
    setSelectedActions(newSelectedActions);
  };
  const handleMotivazioneChange = (event) => {
    setMotivazione(event.target.value)
    //setFormData(prevFormData => ({ ...prevFormData, motivazione: event.target.value }))
  }
// Funzione per determinare se un checkbox deve essere visibile
  const isCheckboxVisible = (azioneId) => {
    // Nascondi i checkbox diversi da 8 e 10 solo se lo status è ADV_OBBLIGATA
    if (status === 'ADV_OBBLIGATA') {
      if (selectedActions.includes(8) || selectedActions.includes(10)) {
        // Se 8 o 10 è selezionato, mostra solo 8 e 10
        return azioneId === 8 || azioneId === 10
      }
    }
    // Altrimenti, mostra tutti i checkbox
    return true
  }
  useEffect(() => {
    if (dettaglioPratica && dettaglioPratica.gestore !== null && dettaglioPratica.gestore !== '') {
      try {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser === dettaglioPratica.gestore) {
          setIsGestorePratica(true);
        } else {
          setIsGestorePratica(false);
        }
      } catch (error) {
        console.error("Errore nel parsing del user da localStorage:", error);
        setIsGestorePratica(false); // Imposta a false se c'è un errore
      }
    } else {
      setIsGestorePratica(false);
    }
    console.log("isGestorePratica:", isGestorePratica);
  }, [dettaglioPratica.gestore]);

  const handleSaveAndClose = async (event) => {
    //debugger
    event.stopPropagation()
    setSave(true)
    setError(false)
    setLoadingRespAzioneRapida(true)
    setInfoMessage('Chiusura monitoraggio in corso...')
    /*
    setIsSaving(true)
    setErrorMessage('')
    setSuccessMessage('')
    setOpenErrorModal(false)
*/
    try {
      //debugger
      const saveData = {
        idMonitoraggio: dettaglioPratica.idMonitoraggio,
        idAzioneList: selectedActions,
        isAzioneRapida: true,
        motivazione: motivazione
      }
      let saveResponse = null;
      let saveRequired = true;
      if(saveData.idAzioneList.some(id => id === 8) )
        saveRequired = false
      else
        saveResponse = await saveMonitoraggio(saveData)


      if (!saveRequired || saveResponse.status === 200) {
        //console.log('Salvataggio riuscito')
        const closeResponse = await closeMonitoraggio(dettaglioPratica.idMonitoraggio)
        if (closeResponse.status === 200) {
          setInfoMessage('Monitoraggio chiuso correttamente.')
          setLoadingRespAzioneRapida(false)
          setTimeout(async ()=> {
            setIsSaving(false)
            const newDetails = await searchById(dettaglioPratica.idMonitoraggio)
            setDettaglioPratica(newDetails)
            setHasFetchedFolders(false)
            window.location.reload()
          }, 3000)

          //setSuccessMessage('La pratica è stata chiusa correttamente.')
          //setOpenSuccessModal(true) // Mostra il modale di successo
        } /*else {
          setErrorMessage('Errore durante la chiusura del monitoraggio.')
          setOpenErrorModal(true)  // Mostra il popup di errore
        }*/
      }/* else {
        setErrorMessage(saveResponse.data.message || 'Errore durante il salvataggio.')
        setOpenErrorModal(true)  // Mostra il popup di errore
      }*/
    } catch (error) {
      console.error('Errore:', error)
      //setErrorMessage(error.response?.data?.message || 'Errore imprevisto durante il salvataggio.')
      //setOpenErrorModal(true)
      setLoadingRespAzioneRapida(false)
      setError(true)
      setTimeout(function () {
        setSave(false)
      }, 5000)
      if (error.response) {
        //alert(error.response.status)
        setInfoMessage(error.response.data.message)
      }
    } finally {



    }
  }


  return (
    <Box sx={{ width: '100%' }}>
      <Accordion
        elevation={0}
        disableGutters
        square={true}
        sx={{
          width: '1800px',
          backgroundColor: theme.palette.grey.dark,
          ml: '20px',
        }}
      >
        <AccordionSummary
          sx={{ backgroundColor: theme.palette.grey.dark }}
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >

          <Grid container display="contents" justifyContent="center" alignItems="center">
            <Stack sx={{ width: '9%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="standard">
                {`NDG`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.ndg}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center" justifyItems={'start'}>
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`CODICE FISCALE`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.codiceFiscale}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`RAGIONE SOCIALE`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.ragioneSociale}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`LIVELLO DI RISCHIO`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.livelloDiRischio}
              </Typography>
            </Stack>

            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`DATA APERTURA`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {formatDate(dettaglioPratica.dataApertura)}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`PRESENZA VARIAZIONI CERVED`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.esitoSintetico}
              </Typography>
            </Stack>
            <Stack sx={{ width: '50%', mr: '2px' }} alignItems="center">
              <Accordion style={{ backgroundColor: 'rgba(255,255,255,0.3)' }}
                         expanded={expandedEsito} // Controlla l'espansione del primo accordion
                         onChange={toggleExpandedEsito}
                         sx={{
                           width: '300px',
                           backgroundColor: theme.palette.grey.dark,
                           ml: '20px',
                         }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="esito-content"
                  id="esito-header"
                >
                  <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                    SEGNALAZIONI CERVED
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List sx={{ listStyleType: 'disc', pl: 2 }}> {/* Imposta lo stile per i puntini */}
                    {dettaglioPratica.esitoSinteticoList.map((item, index) => (
                      <ListItem key={index} sx={{
                        display: 'list-item',
                        textAlign: 'left',
                        padding: 0,
                      }}> {/* Ogni ListItem viene trattato come un elemento di una lista */}
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>

              </Accordion>
            </Stack>
            <Stack sx={{ width: '40%', ml: '10px', mr: '10px' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`STATUS`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.status.replace(/_/g, ' ')}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Accordion style={{ backgroundColor: 'rgba(255,255,255,0.3)' }}
                         expanded={expandedInputIniziale} // Controlla l'espansione del primo accordion
                         onChange={toggleExpandedInputIniziale}
                         sx={{
                           width: '300px',
                           backgroundColor: theme.palette.grey.dark,
                           ml: '20px',
                         }}>

                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="input-content"
                  id="input-header"
                >
                  <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                    INPUT INIZIALI
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List sx={{ listStyleType: 'disc', pl: 2 }}> {/* Imposta lo stile per i puntini */}
                    {dettaglioPratica.inputInizialeList.map((item, index) => (
                      <ListItem key={index} sx={{
                        display: 'list-item',
                        textAlign: 'left',
                        padding: 0,
                      }}> {/* Ogni ListItem viene trattato come un elemento di una lista */}
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>

              </Accordion>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`DATA STATUS`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {formatDate(dettaglioPratica.dataStatus)}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`PRESENZA CHECK USO FONDI`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.esitoMonitoraggioFondi}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`GESTORE`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.gestore}
              </Typography>
            </Stack>
            {showModalBasedOnState && (
              <Stack sx={{ width: '20%' }} alignItems="center">
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation() // Impedisce l'apertura dell'accordion
                    handleOpenModal(dettaglioPratica.idMonitoraggio)  // Apre il modale
                  }}
                  aria-label="Apri Azioni"
                >
                  <BoltOutlined />
                </IconButton>

                {/* Modale per le azioni */}
                <Modal
                  open={openModalIndex === dettaglioPratica.idMonitoraggio}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                  closeAfterTransition={false}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 500,
                      bgcolor: 'background.paper',
                      border: '2px solid #000',
                      boxShadow: 24,
                      borderRadius: 2,
                      overflow: 'hidden',
                    }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {/* Pulsante X per chiudere il Modale */}
                    <IconButton
                      onClick={handleCloseModal}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        color: 'black',
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    {/* Titolo del Modale a larghezza piena */}
                    <Box
                      sx={{
                        width: '100%',
                        backgroundColor: theme.palette.grey.dark,
                        color: 'black',
                        p: 2,
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h2" id="modal-title">
                        GESTIONE RAPIDA
                      </Typography>
                      {!isGestorePratica && <Typography sx={{color: 'red'}}>
                        ATTENZIONE: Solo il gestore della pratica può chiuderla.
                      </Typography>}
                    </Box>

                    {/* Integrazione di CustomModal per gestire errore, conferma e caricamento */}
                    <Box sx={{ p: 4 }}> {/* Padding per il contenuto principale */}
                      <CustomModal
                        open={save}
                        loading={loadingRespAzioneRapida}
                        error={error}
                        message={infoMessage}
                      />

                      {/* Contenuto del Modale */}
                      {azioni.map((azione) => (
                        isCheckboxVisible(azione.id) && (
                          <Grid container alignItems="center" key={azione.id} spacing={2}>
                            <Grid item xs={1}>
                              <Checkbox
                                value={azione.label}
                                checked={selectedActions.includes(azione.id)}
                                disabled={!isGestorePratica}
                                onChange={() => handleCheckboxChange(azione.id)}
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={11}>
                              <Typography variant="caption" sx={{ fontSize: '1rem' }}>
                                {azione.label}
                              </Typography>
                            </Grid>
                          </Grid>
                        )
                      ))}

                      {/* Textarea per la motivazione */}
                      <Grid container alignItems="flex-start" spacing={2} sx={{ mt: 2 }}> {/* Aggiunge spaziatura sopra */}
                        <Grid item xs={12}>
                          <Typography variant="caption" sx={{
                            fontWeight: 'bold',
                            //fontSize: '1rem'
                          }}>
                            MOTIVAZIONE
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextareaAutosize
                            onChange={handleMotivazioneChange}
                            disabled={
                              dettaglioPratica.status === 'APERTA_IN_ATTESA_DOCUMENTI' ||
                              dettaglioPratica.status === 'APERTA_IN_ATTESA_APPROVAZIONE_DOCUMENTI' ||
                              !isGestorePratica
                            }
                            value={motivazione}
                            placeholder={motivazioneObbligatoria ? 'Motivazione obbligatoria' : 'Motivazione non obbligatoria'}
                            minRows={3}
                            style={{
                              width: '100%',  // Fa sì che il Textarea occupi l'intera larghezza del Grid item
                              maxHeight: '150px', // Limita l'altezza massima
                              fontSize: '1rem', // Uniforma la dimensione del testo con il resto
                            }}
                          />
                        </Grid>
                      </Grid>

                      {/* Bottone per chiudere il modale */}
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <StyledButton
                          sx={{
                            backgroundColor: theme.palette.yellow.dark,
                            borderRadius: '5px',
                            border: 'none',
                          }}
                          onClick={handleSaveAndClose}
                          disabled={!isGestorePratica}
                        >
                          Chiudi
                        </StyledButton>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              </Stack>
            )}




          </Grid>

          <Stack sx={{ width: '9%' }}>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'fixed',  // Fisso sopra tutto
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  zIndex: 9999,  // Per assicurarsi che sia sopra altri elementi
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',  // Opzionale: Aggiungi uno sfondo semitrasparente
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Tooltip key={'Stampa Riepilogo'} title="Stampa Riepilogo">
                <IconButton onClick={getPdf}>
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            )}

          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion
            expanded={isYellowSectionExpanded} // Usa lo stato per controllare l'espansione
            onChange={() => setIsYellowSectionExpanded(!isYellowSectionExpanded)} // Cambia lo stato solo se l'utente interagisce
            elevation={0} defaultExpanded={false} disableGutters square={true}
          >
            <AccordionSummary
              sx={{
                backgroundColor: theme.palette.yellow.middle,
                width: '100%',
              }}
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: '24px' }}>SINTESI MONITORAGGIO</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: theme.palette.yellow.middle,
                width: '100%',
                alignItems: 'center',
              }}
            >
              <EsitoMonitoraggio status={status} dettaglioPratica={dettaglioPratica}
                                 onUpdatePratica={handleUpdatePratica} />
              <AccordionActions
                sx={{
                  width: '100%',
                  alignItems: 'center',
                  padding: 0,
                }}
              ></AccordionActions>
            </AccordionDetails>
          </Accordion>

        </AccordionDetails>
        <Accordion elevation={0} disableGutters square={true}
                   sx={{
                     backgroundColor: theme.palette.grey.dark,
                     width: '100%',
                   }}>
          <AccordionSummary
            sx={{
              backgroundColor: theme.palette.grey.dark,
              width: '100%',
            }}
            onClick={() => {
              setStatus(status)
            }}
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontWeight: 'bold', fontSize: '24px', marginLeft: '15px' }}>DOCUMENTI
              MONITORAGGIO</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: theme.palette.grey.light }}>
            {loading ? (
              <CircularProgress />
            ) : folders.length === 0 ? (
              <Typography sx={{ ml: 2 }}>Nessun documento disponibile</Typography>
            ) : (
              folders.map((folder, index) => (
                <List key={index}>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <Typography sx={{ fontWeight: 'bold' }}>{folder.name}</Typography>
                    </Grid>
                    <Grid item xs={3} container justifyContent="flex-end">
                      {isValidUser('UTENTE_INTERNO') && (
                        <UploadDocumentMonitoraggio
                          idMonitoraggio={dettaglioPratica.idMonitoraggio}
                          idTipologia={folder.idTipologia}
                          onUpload={handleUploadSuccess}
                          disabled={dettaglioPratica.status === 'CHIUSA'}
                        />)}
                    </Grid>
                  </Grid>
                  <AccordionDetails sx={{ backgroundColor: theme.palette.grey.light }}>
                    <Grid container spacing={4}>
                      {folder.children && folder.children.length > 0 ? (
                        folder.children.map((file, fileIndex) => (
                          <Grid item key={fileIndex}>
                            <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
                              <Tooltip title={file.name}>
                                <IconButton onClick={() => handleClick(file)}>
                                  <SummarizeIcon />
                                </IconButton>
                              </Tooltip>
                              {selectedFile === file && (
                                <Stack direction="row" spacing={1} sx={{ ml: 2 }}>
                                  <Tooltip title="Visualizza">
                                    <IconButton onClick={() => handleView(file)}>
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Scarica">
                                    <IconButton onClick={() => handleDownload(file)}>
                                      <FileDownloadIcon />
                                    </IconButton>
                                  </Tooltip>
                                  {isValidUser('UTENTE_INTERNO') && (
                                    <Tooltip title="Elimina">
                                      <IconButton onClick={() => handleDelete(file)}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>)}
                                </Stack>
                              )}
                            </ListItem>
                          </Grid>
                        ))
                      ) : (
                        <Typography sx={{ marginTop: 2, ml: 2 }}>Nessun file presente</Typography>
                      )}
                    </Grid>
                  </AccordionDetails>
                </List>
              ))
            )}
          </AccordionDetails>
        </Accordion>
      </Accordion>
      <Modal
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
          >
            {pdfData && (
              <PDFViewerMonitoraggio
                open={openModal}
                onClose={handleClose}
                document={pdfData}
                title={selectedFile.name}
              />
            )}
          </Box>
        </Fade>
      </Modal>

    </Box>
  )
}

export default AccordionDettaglioMonitoraggio
