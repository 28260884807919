import { useState, useEffect } from 'react';
import { Grid, CircularProgress, Stack, TextField, Tooltip, InputAdornment, Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import AccordionDettaglioMonitoraggio from './components/AccordionDettaglioMonitoraggio';
import Footer from '../UI/FooterMonitoraggio';
import { searchByCf } from '../services/searchByCf'; // Importa il servizio
import Header from '../UI/Header';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DettaglioMonitoraggio = () => {
  const location = useLocation();
  const [listaPratiche, setListaPratiche] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(false);

  // Funzione per eseguire la chiamata API appena il componente viene montato
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await searchByCf(location.state?.selectedRowData.codiceFiscale); // Chiama il servizio con il codice fiscale
        console.log('Risultato di searchByCf:', response); // Log per verificare il risultato
        setListaPratiche(response); // Imposta la lista delle pratiche con i dati ottenuti
      } catch (error) {
        console.error('Errore durante la chiamata searchByCf:', error);
        setErrorState(true); // Gestione dell'errore
      } finally {
        setLoading(false); // Termina il caricamento
      }
    };

    if (location.state?.selectedRowData.codiceFiscale) {
      fetchData(); // Chiama fetchData solo se c'è un codice fiscale
    }
  }, [location.state?.selectedRowData.codiceFiscale]); // Esegui la chiamata quando cambia il codice fiscale

  return (
    <>
      <Header
        showHome
        showRichiesta={false}
        showDashMonitoraggio={true}
        showSintesiAccountingMonitoraggio={true}
        idNodoPratica={location.state?.selectedRowData.idMonitoraggio}
        praticaProperties={location.state?.selectedRowData}
        header={
          <Box
            sx={{
              height: 70,
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                marginBottom: '1rem',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  flexDirection: 'row',
                  width: '80%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  mr: '10px',
                  ml: '155px'
                }}
              >
                <Tooltip title={location.state?.selectedRowData.ragioneSociale}>
                  <Box item xs={3} sx={{ flexDirection: 'row' }}>
                    <TextField
                      disabled
                      value={location.state?.selectedRowData.ragioneSociale}
                      id="standard-basic"
                      helperText={<Typography variant="caption">Ragione Sociale</Typography>}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                            <ContentCopyIcon onClick={() => navigator.clipboard.writeText(location.state?.selectedRowData.ragioneSociale)} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      sx={{ width: '95%' }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title={location.state?.selectedRowData.codiceFiscale}>
                  <Box sx={{ flexDirection: 'row' }}>
                    <TextField
                      disabled
                      value={location.state?.selectedRowData.codiceFiscale}
                      id="standard-basic"
                      helperText="Codice Fiscale"
                      variant="standard"
                      sx={{ width: '95%' }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title={location.state?.selectedRowData.gestore}>
                  <Box sx={{ flexDirection: 'row' }}>
                    <TextField
                      disabled
                      value={location.state?.selectedRowData.gestore}
                      id="standard-basic"
                      helperText="Gestore"
                      variant="standard"
                      sx={{ width: '95%' }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title={location.state?.selectedRowData.ndg}>
                  <Box sx={{ flexDirection: 'row' }}>
                    <TextField
                      disabled
                      value={location.state?.selectedRowData.ndg}
                      id="standard-basic"
                      helperText="NDG"
                      variant="standard"
                      sx={{ width: '95%' }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        }
      />

      <Grid item container xs={12} direction="row" justifyItems="space-between" alignItems="flex-start" mt={14}>
        {loading && <CircularProgress />} {/* Mostra il caricamento */}
        {errorState && <p>Errore nel caricamento delle pratiche</p>} {/* Mostra un errore se presente */}

        <Grid item xs={7}>
          <Stack sx={{ alignItems: 'center' }} spacing={2}> {/* Spacing per dare spazio tra i componenti */}
            {listaPratiche.map((pratica, index) => (
              <AccordionDettaglioMonitoraggio
                key={pratica.idMonitoraggio}
                idNodoPratica={pratica.idMonitoraggio}
                dettaglioPratica={pratica} // Passa ogni pratica come dettaglio
              />
            ))}
          </Stack>
        </Grid>
        <Footer idNodoPratica={location.state?.selectedRowData.idMonitoraggio} />
      </Grid>
    </>
  );
};

export default DettaglioMonitoraggio;
