import React from 'react'
import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

import Header from '../UI/Header'
import {
    Grid,
    Typography,
    TextField,
    Avatar,
    Box,
    CircularProgress,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { StyledButton } from '../UI/StyledButton'
import { changePassword } from '../services/changePassword'
import useValidatePassword from '../utils/hooks/useValidatePassword'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const ModificaPassword = () => {
    const initialState = {
        oldpassword: '',
        newpassword: '',
        confirmpassword: '',
    }

    const theme = useTheme()
    const [data, setData] = useState(initialState)
    const [responseMessage, setResponseMessage] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [validPasswords, setValidPasswords] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    // console.log(data)

    function stringAvatar(name) {
        return {
            children: `${name.charAt(0).toUpperCase()}`,
        }
    }
    const handleInputChange = event => {
        event.preventDefault()
        setData({ ...data, [event.target.name]: event.target.value })
    }

    const handleSubmit = event => {
        event.preventDefault()
        setLoading(true)
        // console.log(data)
        try {
            changePassword(data)
                .then(response => {
                    if (response.data.code === 200) {
                        setLoading(false)
                        setResponseMessage(response.data.message)
                        setData(initialState)
                    }
                })
                .catch(error => {
                    // console.log({ error })
                    setLoading(false)
                    setResponseMessage(error.response.data.message)
                })
        } catch (error) {
            alert(error)
        }
    }

    const {
        passwordIsValid: newPasswordIsValid,
        setPasswordiIsValid: setNewPasswordIsValid,
    } = useValidatePassword(data.newpassword)
    const {
        passwordIsValid: confirmPasswordIsValid,
        setPasswordiIsValid: setConfirmPasswordIsValid,
    } = useValidatePassword(data.confirmpassword)

    // console.table({ newPasswordIsValid, confirmPasswordIsValid, validPasswords })

    //validazione password
    useEffect(() => {
        if (newPasswordIsValid && confirmPasswordIsValid) {
            setValidPasswords(true)
            setErrorMessage('')
        } else {
            setValidPasswords(false)
            setErrorMessage(
                'La nuova password deve essere almeno 8 caratteri e deve contenere una maiuscola ed un carattere speciale (!@#$%&*.)',
            )
        }
    }, [
        data.newpassword,
        data.confirmpassword,
        validPasswords,
        newPasswordIsValid,
        confirmPasswordIsValid,
    ])

    return (
        <>
            <Header
                showHome
                header={
                    <>
                        <Grid
                            container
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            justifyContent="start"
                            alignContent="start"
                        >
                            <Typography
                                sx={{ flexGrow: 1 }}
                                xs={12}
                                item
                                alignItems="center"
                                mb={'1rem'}
                                color={theme.palette.black.main}
                                variant="h1"
                            >
                                MODIFICA PASSWORD
                            </Typography>
                        </Grid>
                    </>
                }
            ></Header>
            <Box component="form" noValidate sx={{ mt: 20, width: '75%' }}>
                <Grid
                    container
                    padding="30px"
                    spacing={{ xs: 2, md: 1 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    justifyContent="center"
                >
                    <Grid justifyItems="center" item xs={3}>
                        <Avatar
                            sx={{
                                mb: 4,
                                width: 50,
                                height: 50,
                                bgcolor: '#235F7B',
                                alignContent: 'center',
                            }}
                            {...stringAvatar(JSON.parse(localStorage.getItem('user')))}
                        />

                        <TextField
                            name={'oldpassword'}
                            margin="normal"
                            helperText="Vecchia Password"
                            required
                            fullWidth
                            value={data.oldpassword}
                            variant="standard"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleInputChange}
                        />
                        <TextField
                            onChange={handleInputChange}
                            name={'newpassword'}
                            value={data.newpassword}
                            margin="normal"
                            helperText="Nuova Password"
                            required
                            type="password"
                            fullWidth
                            variant="standard"
                            autoComplete="new-password"
                        />
                        <TextField
                            onChange={handleInputChange}
                            name={'confirmpassword'}
                            margin="normal"
                            type="password"
                            value={data.confirmpassword}
                            helperText="Conferma Password"
                            required
                            fullWidth
                            variant="standard"
                            autoComplete="confirm-password"
                        />
                        {errorMessage !== ' ' ? (
                            <Typography color="red" variant="caption">
                                {errorMessage}
                            </Typography>
                        ) : null}
                        <Grid alignContent="flex-end" item xs={12}>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <StyledButton
                                    disabled={!validPasswords}
                                    onClick={handleSubmit}
                                    sx={{ margin: 'auto', marginY: '2em' }}
                                >
                                    SALVA
                                </StyledButton>
                            )}
                            {!loading && responseMessage !== undefined ? (
                                <Typography>{responseMessage}</Typography>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default ModificaPassword
