import React from 'react'
import { useState, useEffect } from 'react'
const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*\.])(?=.{8,})");

export default function useValidatePassword(passwordDaValidare) {
    const [passwordIsValid, setPasswordIsValid] = useState(false)

    useEffect(() => {
        try {
            if (passwordDaValidare.match(regex)) {
                setPasswordIsValid(true)
            } else {
                setPasswordIsValid(false)
            }
        } catch (error) {
            // console.log(error)
        }

        return () => setPasswordIsValid(false)
    }, [passwordDaValidare])

    return { passwordIsValid, setPasswordIsValid }
}