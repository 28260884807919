import React from 'react'
import { useState } from 'react'
import {
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  Grid,
  Switch,
  Stack,
  FormControlLabel,
  Typography, CircularProgress
} from '@mui/material'
import { StyledButton } from '../UI/StyledButton'
import Header from '../UI/Header'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import OnlyWithFunction from '../OnlyWithFunctions'
import { useQuery } from '@tanstack/react-query'
import { getListaUtenzeAssegnatari } from '../services/getListaUtenzeAssegnatari'
import { StatiPratica } from '../utils/StatiPratica'
import ResultsTableAccounting from '../Charts/ResultsTableAccounting'
import { searchFilters } from '../services/searchFilters'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { searchSintesiAccounting } from '../services/searchSintesiAccounting';
import ReportAccountingTable from '../Charts/ReportAccountingTable'

import dayjs from 'dayjs';
import 'dayjs/locale/it'; // Importa la localizzazione italiana per Dayjs

import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.locale('it');

const Accounting = (props) => {

  const theme = useTheme()
  const dataAperturaStyle = { display: 'flex', flexDirection: 'row', width: '100%' }
  const dataAperturaStyleOnlySintesi = { display: 'flex', flexDirection: 'row', width: '100%', marginBottom: "2%" }
  const navigate = useNavigate()
  const initialState = {}
  const isUtente = JSON.parse(localStorage.getItem('functions'));
  const [pratiche90gg, setPratiche90gg] = useState(false)
  const tipoInserimento = ['Automatica Alto Rischio', 'Automatica Medio Rischio', 'Manuale'];
  const [annulla, setAnnulla] = useState(false)
  const [fetchingData, setFetchingData] = useState(false)
  const [mostraPratiche90gg, setMostraPratiche90gg] = useState(false) // TO DO Implementare stato chiuse utilizzando stringa in funzione handle search
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [dataChiusuraDa, setDataChiusuraDa] = useState(null);
  const [dataChiusuraA, setDataChiusuraA] = useState(null);
  const [dataPraticaDa, setDataPraticaDa] = useState(null);
  const [dataPraticaA, setDataPraticaA] = useState(null);
  const [stato, setStato] = useState(null);
  const [tipo, setTipo] = useState('');
  const [isStatoEnabled, setIsStatoEnabled] = useState(true);
  const [isTipoInserimentoEnabled, setIsTipoInserimentoEnabled] = useState(true);
  const [isChiusuraEnabled, setIsChiusuraEnabled] = useState(true);
  const [isDataPraticaEnabled, setIsDataPraticaEnabled] = useState(true);

  //fetch reperimento lista utenze assegnatari
  const {
    data: utenzeAssegnatari,
    isFetching: FetchingUtenzeAssegnatari,
    isLoading: LoadingUtenzeAssegnatari,
    isFetched: FetchedUtenzeAssegnatari,
  } = useQuery(['DaAssegnareA'], () => getListaUtenzeAssegnatari())

  // listaPratiche ha la lista visualizzata nella Table, sia della ricerca, sia ad apertura della pagina
  const [listaPratiche, setListaPratiche] = useState([])
  const [listaPraticheInAttesaToShow, setListaPraticheInAttesaToShow] = useState([])
  //chiamata al backend contente tutte le pratiche
  const {
    data: dataListaPratiche,
    isFetching: isFetchingListaPratiche,
    isFetched: isFetchedListaPratiche,
    // status: statusListaPratiche,
    refetch: refetchListaPratiche,
  } = useQuery(['searchFilters'], () =>
    searchFilters().then(res => {
      setListaPratiche(res)
      return res
    }),
    {
      enabled: false, // Impedisce l'esecuzione automatica al mount del componente
    }
  )

  function handleMostraPratiche90gg() {
    // 
    setMostraPratiche90gg(!mostraPratiche90gg)
    console.log(mostraPratiche90gg)
  }

  const formatToISOString = (date) => {
    return date?.toISOString() || '';
  };

  const handleDateChangeChiusuraDa = (date) => {

    const formattedDate = formatToISOString(date) // Formatta la data nel formato richiesto
    console.log(formattedDate);
    setDataChiusuraDa(formattedDate);
  };
  const handleDateChangeChiusuraA = (date) => {
    const formattedDate = formatToISOString(date) // Formatta la data nel formato richiesto
    console.log(formattedDate);
    setDataChiusuraA(formattedDate);
  };
  const handleDateChangePraticaDa = (date) => {
    const formattedDate = formatToISOString(date) // Formatta la data nel formato richiesto
    console.log(formattedDate);
    setDataPraticaDa(formattedDate);
  };
  const handleDateChangePraticaA = (date) => {
    const formattedDate = formatToISOString(date) // Formatta la data nel formato richiesto
    console.log(formattedDate);
    setDataPraticaA(formattedDate);
  };
  const handleToggleStato = () => {
    setIsStatoEnabled(!isStatoEnabled);
    setStato("");
  };
  const handleToggleTipoInserimento = () => {
    setIsTipoInserimentoEnabled(!isTipoInserimentoEnabled);
    setTipo("");
  };
  const handleToggleChiusura = () => {
    setIsChiusuraEnabled(!isChiusuraEnabled);
    setDataChiusuraA(null)
    setDataChiusuraDa(null)

  };

  const handleToggleDataPratica = () => {
    setIsDataPraticaEnabled(!isDataPraticaEnabled);
    setDataPraticaDa(null)
    setDataPraticaA(null)
  };

  const handleSelectChangeStato = (event) => {
    setStato(event.target.value);
  };

  const handleSelectChangeTipo = (event) => {

    setTipo(event.target.value);
  };

  function handleSearch(event, tipo, stato, dataChiusuraDa, dataChiusuraA, dataPraticaDa, dataPraticaA, pratiche90gg) {
    //event.preventDefault();
    setFetchingData(true)
    searchFilters(tipo, stato, dataChiusuraDa, dataChiusuraA, dataPraticaDa, dataPraticaA, pratiche90gg)
      .then(response => {
        console.log(response.data)
        setFetchingData(false)
        setListaPratiche(response.data.body)
        setSearchPerformed(true);
      })
      .catch(error => {
        setFetchingData(false)
        console.error('Errore durante la ricerca:', error);
      });
  }

  const [reportAccounting, setReportAccounting] = useState(
    [{
      AutomaticaAltoRischio: 0,
      AutomaticaMedioRischio: 0,
      Manuale: 0
    }]
  );

  function handleCustomSearch(event, tipo, dataPraticaDa, dataPraticaA) {
    setFetchingData(true)
    searchSintesiAccounting(tipo, dataPraticaDa, dataPraticaA)
      .then(response => {
        console.log("response:",response.data.body)
        setReportAccounting([response.data.body])
        setFetchingData(false)
      })
      .catch(error => {
        setFetchingData(false)
        console.error('Errore durante la ricerca:', error);
      });
  }

  return (
    <div>
      <Header
        showHome={true}
        showRichiesta
        showStatistiche={false}
        header={
          <>
            <OnlyWithFunction
              acceptedFunction="UTENTE_INTERNO">
              <Grid spacing={5}>
                <Grid item xs={6}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <div style={{ paddingTop: '20px', marginRight: '10px' }}>
                      {!props.onlySintesi && (<>
                        <div style={{ display: 'flex', flexdirection: 'row' }}>
                          <InputLabel>Stato</InputLabel>
                          <FormControlLabel
                            control={
                              <Switch checked={isStatoEnabled} size="small" onChange={handleToggleStato} />}
                            label={<Typography sx={{ color: theme.palette.black.main }} variant="caption">
                              {!isStatoEnabled ? 'OFF' : 'ON'}</Typography>}
                          />
                        </div>
                        <Select
                          value={stato}
                          onChange={handleSelectChangeStato}
                          sx={{ width: '190px', height: '30px', marginTop: '15px' }}
                          disabled={!isStatoEnabled}
                        >
                          {Object.entries(StatiPratica).map(([key, value]) => (
                            <MenuItem key={key} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </>)}
                    </div>
                    <div style={{ marginTop: '20px', marginRight: '15px' }}>
                      <div style={{ display: 'flex', flexdirection: 'row' }}>
                        <InputLabel sx={{ paddingBottom: 0, paddingRight: 0, }}>Tipo Inserimento</InputLabel>
                        <FormControlLabel
                          control={
                            <Switch checked={isTipoInserimentoEnabled} size="small" onChange={handleToggleTipoInserimento} />}
                          label={<Typography sx={{ color: theme.palette.black.main, size: 5 }} variant="caption">
                            {!isTipoInserimentoEnabled ? 'OFF' : 'ON'}</Typography>}
                        />
                      </div>
                      <Select
                        value={tipo}
                        onChange={handleSelectChangeTipo}
                        sx={{ width: '180px', height: '30px', marginTop: '15px' }}
                        disabled={!isTipoInserimentoEnabled}
                      >
                        {tipoInserimento.map((opzione, index) => (
                          <MenuItem key={index} value={opzione}>
                            {opzione}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <Grid sx={{ paddingBottom: 1 }}>
                      {!props.onlySintesi && (<>
                        <div style={{ display: 'flex', flexdirection: 'row' }}>
                          <InputLabel sx={{ paddingTop: '20px', paddingBottom: 1 }}>Data Chiusura</InputLabel>
                          <FormControlLabel sx={{ marginTop: '20px' }}
                            control={
                              <Switch checked={isChiusuraEnabled} size="small" onChange={handleToggleChiusura} />}
                            label={<Typography sx={{ color: theme.palette.black.main }} variant="caption">
                              {!isChiusuraEnabled ? 'OFF' : 'ON'}</Typography>}
                          />
                        </div>
                        <Grid container xs={12}>
                          <Grid item xs={11}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it"  >
                              <DatePicker
                                value={dataChiusuraDa}
                                onChange={handleDateChangeChiusuraDa}
                                renderInput={(props) => (
                                  <TextField {...props} label="Da" sx={{
                                    width: '160px',
                                    paddingRight: 2,
                                    '& .MuiSvgIcon-root': {
                                      marginRight: '5px',
                                      // Puoi regolare il valore in base alle tue esigenze
                                    },
                                  }} />
                                )}
                                format="DD/MM/YYYY" // Imposta il formato della data come "dd/mm/yyyy"
                                sx={{ width: '200px' }}
                                disabled={!isChiusuraEnabled}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={11}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                              <DatePicker
                                value={dataChiusuraA}
                                onChange={handleDateChangeChiusuraA}
                                renderInput={(props) => (
                                  <TextField {...props} label="A" sx={{
                                    width: '160px',
                                    paddingRight: 2,
                                    '& .MuiSvgIcon-root': {
                                      marginRight: '5px',
                                      // Puoi regolare il valore in base alle tue esigenze
                                    },
                                  }} />
                                )}
                                format="DD/MM/YYYY" // Imposta il formato della data come "dd/mm/yyyy"
                                sx={{ width: '100%' }}
                                disabled={!isChiusuraEnabled}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </>)}
                    </Grid>
                    <div style={props.onlySintesi ? dataAperturaStyleOnlySintesi : dataAperturaStyle}>
                      <Grid>
                        <div style={{ display: 'flex', flexdirection: 'row' }}>

                          <InputLabel sx={{ paddingTop: '20px', paddingBottom: 1 }}>Data Apertura</InputLabel>
                          <FormControlLabel sx={{ marginTop: '20px' }}
                            control={
                              <Switch checked={isDataPraticaEnabled} size="small" onChange={handleToggleDataPratica} />}
                            label={<Typography sx={{ color: theme.palette.black.main }} variant="caption">
                              {!isDataPraticaEnabled ? 'OFF' : 'ON'}</Typography>}
                          />
                        </div>
                        <Grid container xs={12}>
                          <Grid item xs={11}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                              <DatePicker
                                value={dataPraticaDa}
                                onChange={handleDateChangePraticaDa}
                                renderInput={(props) => (
                                  <TextField {...props} label="Da" sx={{
                                    width: '160px',
                                    paddingRight: 2,
                                    '& .MuiSvgIcon-root': {
                                      marginRight: '5px',
                                      // Puoi regolare il valore in base alle tue esigenze
                                    },
                                  }} />
                                )}
                                format="DD/MM/YYYY" // Imposta il formato della data come "dd/mm/yyyy"
                                sx={{ width: '100%' }}
                                disabled={!isDataPraticaEnabled}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={11}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                              <DatePicker
                                value={dataPraticaA}

                                onChange={handleDateChangePraticaA}
                                renderInput={(props) => (
                                  <TextField {...props} label="A" sx={{
                                    width: '160px',
                                    paddingRight: 2,
                                    '& .MuiSvgIcon-root': {
                                      marginRight: '5px',
                                      // Puoi regolare il valore in base alle tue esigenze
                                    },
                                  }} />
                                )}
                                format="DD/MM/YYYY" // Imposta il formato della data come "dd/mm/yyyy"

                                sx={{ width: '100%' }}
                                disabled={!isDataPraticaEnabled}

                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <Grid item xs={4}>
                      <div style={{ display: 'flex', flexdirection: 'row' }}>
                        {!props.onlySintesi && (<>
                          <FormControlLabel
                            control={
                              <Switch checked={mostraPratiche90gg} size="small" onChange={handleMostraPratiche90gg} />}
                            label={<InputLabel sx={{ color: theme.palette.black.main }} variant="caption">
                              Pratiche a 90gg</InputLabel>}
                          />
                        </>)}

                        <Box sx={{ m: 1, position: 'relative' }}>
                          <StyledButton
                            disabled={fetchingData}
                            onClick={e => {
                              props.onlySintesi ? handleCustomSearch(e, tipo, dataPraticaDa, dataPraticaA) : handleSearch(e, tipo, stato, dataChiusuraDa, dataChiusuraA, dataPraticaDa, dataPraticaA, mostraPratiche90gg
                              )
                            }
                            }
                            sx={{
                              backgroundColor: theme.palette.grey.light,
                              color: theme.palette.black.main,
                              border: '0px',
                              paddingRight: '10px',
                              mr: 4,
                              mt: 2,
                            }}
                          >
                            <Typography variant="caption">CERCA</Typography>
                          </StyledButton>
                          {fetchingData && (
                            <CircularProgress
                              size={24}
                              sx={{

                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-10px',
                                marginLeft: '-25px',
                              }}
                            />
                          )}
                        </Box>
                      </div>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </OnlyWithFunction>
          </>
        }
      />

      <Grid
        container
        mt={{ xs: 15, lg: 8, xl: 8 }}
        spacing={5}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyItems="space-between"
      >
        {!props.onlySintesi && (<>
          <Grid item xs={12} sx={{ marginTop: 10 }}>
            {/* Renderizza la tabella solo se la ricerca è stata effettuata */}
            {searchPerformed ? (
              <Box sx={{ padding: 5, width: '100%', height: { xs: '500px', xl: '650px' } }}>
                <ResultsTableAccounting
                  listaPratiche={listaPratiche}
                  //mostraChiuse={mostraChiuse}
                  isFetchedListaPratiche={isFetchedListaPratiche}
                  refetchListaPratiche={refetchListaPratiche}
                  isFetchingListaPratiche={fetchingData}
                />
              </Box>
            ) : (
              // Altrimenti, mostra la tabella con colonne vuote
              <Box sx={{ padding: 5, width: '100%', height: { xs: '500px', xl: '650px' } }}>

                <ResultsTableAccounting
                  listaPratiche={[]} // Inizializza con un array vuoto
                  //mostraChiuse={mostraChiuse}
                  isFetchedListaPratiche={false}
                  refetchListaPratiche={refetchListaPratiche}
                  isFetchingListaPratiche={false}
                />
              </Box>
            )}
          </Grid>
        </>)}
        {props.onlySintesi && (<>

          {!fetchingData && (
            <Grid item xs={12} sx={{ marginTop: 15, marginLeft: 5 }}>
              <ReportAccountingTable reportAccounting={reportAccounting} />
            </Grid>
          )}

        </>)}
      </Grid>
    </div>
  );
}

export default Accounting;

