import { customAxios } from "./customAxios";

export function saveMonitoraggio(data) {
    const config = {
        headers: {
            Authtoken: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json'
        }
    };

    const saveRequest = customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/adv/monitoraggio/save", JSON.stringify(data), config);
    return saveRequest;
}
