import React from 'react'
import { useState } from 'react'
import { Stack, IconButton, Tooltip } from '@mui/material'
import CustomModal from '../../UI/CustomModal'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { deleteNodeById } from '../../services/deleteNodeById'

function DeleteDocument({ idFile, disabled, fileName, onDelete }) {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [error, setError] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')

  const deleteDoc = (event, fileId) => {
    event.preventDefault()

    setOpenModal(true)
    setLoading(true)
    deleteNodeById(fileId)
      .then(response => {
        // console.log(response)
        setLoading(false)
        setInfoMessage(`Cancellazione effettuata`)
        setTimeout(function () {
          setOpenModal(false)
          onDelete()
          // setInfoMessage('')
        }, 2000)
        //window.location.reload()
      })
      .catch(error => {
        setLoading(false)
        setError(true)
        if (error.response) {
          setInfoMessage(`Errore nella cancellazione del file, riprova`)
          setTimeout(function () {
            setOpenModal(false)
            setInfoMessage('')
          }, 1000)
        }
      })
  }
  return (
    <Stack direction="row" alignItems="center">
      {/* {!loading && <Typography>{infoMessage}</Typography>} */}
      <Tooltip title={`Cancella ${fileName}`}>
        <IconButton
          onClick={event => deleteDoc(event, idFile)}
          disabled={disabled}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      {/* {(loading === true) &&
                    <CircularProgress size='1rem' />} */}
      <CustomModal
        open={openModal}
        loading={loading}
        error={error}
        message={infoMessage}
      />
    </Stack>
  )
}

export default DeleteDocument
