import { React, useState } from 'react'
import { IconButton, InputAdornment, TextField, Box } from '@mui/material'
import { createNewFolder } from '../../services/createNewFolder'
import AddIcon from '@mui/icons-material/Add'

const TextFieldCreateFolder = props => {
    const [disable, setDisable] = useState(true)
    const [textValue, setTextValue] = useState('')

    const submitCreateNewFolder = (event, folderId, folderName) => {
        event.preventDefault()
        if (disable) return

        createNewFolder(folderId, folderName).then(res => {
            props.onSubmit()
            setTextValue('')
        })
    }

    const changeTextValue = value => {
        setTextValue(value)

        if (
            value.trim() !== '' &&
            !props.listaDocs.map(doc => doc.name).includes(value.trim())
        )
            setDisable(false)
        else setDisable(true)
    }

    return (
        <Box
            component="form"
            onSubmit={event =>
                submitCreateNewFolder(event, props.folderId, textValue.trim())
            }
            sx={{ width: '100%' }}
        >
            <TextField
                value={textValue}
                onChange={event => changeTextValue(event.target.value)}
                variant="standard"
                sx={{ width: '100%', mt: 1 }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                disabled={disable}
                                variant="contained"
                                onClick={event =>
                                    submitCreateNewFolder(event, props.folderId, textValue.trim())
                                }
                            >
                                <AddIcon fontSize="medium"></AddIcon>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            ></TextField>
        </Box>
    )
}

export default TextFieldCreateFolder
