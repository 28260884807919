import { customAxios } from './customAxios'
export function getEsitoADV(idNodoPratica) {
    const config = {
        params: { idNodoPratica: idNodoPratica.toString() },
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }
    const getEsitoADVRequest =
        customAxios.get(process.env.REACT_APP_BACKEND_HOST + `/`+process.env.REACT_APP_PATH_NEW_PREFIX+`/getEsitoADV?`, config).then(response => response.data.body)
    return getEsitoADVRequest
}