import React from 'react';
import { useState, useEffect } from 'react'
import Header from '../UI/Header'
import LogoDividerContainer from '../containers/LogoDividerContainer'
import { isValidUser } from '../OnlyWithFunctions'
import { useQuery } from '@tanstack/react-query'
import { getListaPratiche } from '../services/getListaPratiche'
import { getListaPraticheInAttesa } from '../services/getListaPraticheInAttesa'
import { getListaPraticheOnboardingOrMonitoraggio } from '../services/getListaPraticheOnboardingOrMonitoraggio'
import { getListaUtenzeAssegnatari } from '../services/getListaUtenzeAssegnatari'
import { StatiPratica } from '../utils/StatiPratica'
import { search } from '../services/search'
import { StyledButton } from '../UI/StyledButton'

import {
    Stack,
    Box,
    TextField,
    Grid,
    Switch,
    FormControlLabel,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Container,
  } from '@mui/material'
  import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
  import { useTheme } from '@mui/material/styles'
  import { useNavigate } from 'react-router-dom'

  import GraficoPie from '../Charts/GraficoPie'
  import GraficoPie2 from '../Charts/GraficoPie2'
  import OnlyWithFunction from '../OnlyWithFunctions'

const StatistichePanel = () => {
    const theme = useTheme()
    const navigate = useNavigate()

    const [mostraChiuse, setMostraChiuse] = useState(false) // TO DO Implementare stato chiuse utilizzando stringa in funzione handle search
    const [mostraInAttesa, setMostraInAttesa] = useState(isValidUser('SUPPORTO_FUNZIONE_AML') || isValidUser('SUPPORTO_AML_DESK') ? true : false)
    const [mostraLeMiePratiche, setMostraLeMiePratiche] = useState(isValidUser('APPROVA_PRATICA_FIELD_ANALYST') || isValidUser('APPROVA_PRATICA_UTENTE_BASE') ? true : false)
    const [mostraAnnullate, setMostraAnnullate] = useState(false)
    const [mostraTutteLePratiche, setMostraTutteLePratiche] = useState(false)
    const [annulla, setAnnulla] = useState(false)
    const [searchString, setSearchString] = useState('')
    const [selectedId, setSelectedId] = useState(undefined)
    const [selectedRowData, setSelectedRowData] = useState(undefined)
    const [searchResult, setSearchResult] = useState(undefined)
    const [listaUtentiDaAssegnare, setListaUtentiDaAssegnare] = useState(false)
  
    const [fetchingData, setFetchingData] = useState(true)
  
    //fetch reperimento lista utenze assegnatari
    const {
      data: utenzeAssegnatari,
      isFetching: FetchingUtenzeAssegnatari,
      isLoading: LoadingUtenzeAssegnatari,
      isFetched: FetchedUtenzeAssegnatari,
    } = useQuery(['DaAssegnareA'], () => getListaUtenzeAssegnatari())
  
    useEffect(() => {
      if (listaUtentiDaAssegnare && localStorage.getItem('DaAssegnareA') && localStorage.getItem('DaAssegnareA').length === 0 &&
        localStorage.getItem('DaAssegnareA') !== undefined) {
        localStorage.setItem('DaAssegnareA', JSON.stringify(utenzeAssegnatari !== undefined ? utenzeAssegnatari : []))
      }
    }, [utenzeAssegnatari, listaUtentiDaAssegnare])
       // listaPratiche ha la lista visualizzata nella Table, sia della ricerca, sia ad apertura della pagina
  const [listaPratiche, setListaPratiche] = useState([])
  const [listaPraticheInAttesaToShow, setListaPraticheInAttesaToShow] = useState([])
  //chiamata al backend contente tutte le pratiche
  const {
    data: dataListaPratiche,
    isFetching: isFetchingListaPratiche,
    isFetched: isFetchedListaPratiche,
    status: statusListaPratiche,
    refetch: refetchListaPratiche,
  } = useQuery(['getListaPratiche'], () =>
    getListaPratiche().then(res => {
      setListaPratiche(resultByFlagChiusa(res, mostraChiuse))
      return res
    }),
  )
  //chiamata al backend contente le pratiche in attesa
  const {
    data: listaPraticheInAttesa,
    isFetching: isFetchinglistaPraticheInAttesa,
    isFetched: isFetchedlistaPraticheInAttesa,
    status: statuslistaPraticheInAttesa,
    refetch: refetchlistaPraticheInAttesa,
  } = useQuery(['getListaPraticheInAttesa'], () => getListaPraticheInAttesa())

  //chiamata backedn lista pratiche Monitoraggio o Onboarding
  const {
    data: monitoraggioOnBoardingListaPratiche,
    isFetching: isFetchingMonitoraggioOnBoardingListaPratiche,
    isFetched: isFetchedMonitoraggioOnBoardingListaPratiche,
    status: statusMonitoraggioOnBoardingListaPratiche,
  } = useQuery(['getListaPraticheOnboardingOrMonitoraggio()'], () => getListaPraticheOnboardingOrMonitoraggio())

  // **
  useEffect(() => {
    setAnnulla(false)
    // 
    if ((isValidUser('SUPPORTO_FUNZIONE_AML')) && listaPraticheInAttesa) {
      setFetchingData(isFetchinglistaPraticheInAttesa)
      if (mostraChiuse) {
        const listaPraticheChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.chiusa)
        setListaPraticheInAttesaToShow(listaPraticheChiuse)
      }
      else if (!mostraChiuse && mostraInAttesa) {
        const listaPraticheNonChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] !== StatiPratica.chiusa)
        setListaPraticheInAttesaToShow(listaPraticheNonChiuse)
      }
      else if (!mostraChiuse && !mostraInAttesa && mostraAnnullate) {
        const listaPraticheAnnullate = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.annullata)
        setListaPraticheInAttesaToShow(listaPraticheAnnullate)
      }
      else {
        setListaPraticheInAttesaToShow(listaPraticheInAttesa)
      }
    }
    if (isValidUser("UTENTE_INTERNO")) {
      setFetchingData(isFetchingListaPratiche)
      if (mostraChiuse) {
        const listaPraticheChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.chiusa)
        setListaPraticheInAttesaToShow(listaPraticheChiuse)
      }
      else if (!mostraChiuse && mostraAnnullate) {
        const listaPraticheAnnullate = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.annullata)
        setListaPraticheInAttesaToShow(listaPraticheAnnullate)
      }
      else {
        setListaPraticheInAttesaToShow(dataListaPratiche)
      }
    }
    else if ((isValidUser('SUPPORTO_AML_DESK') && listaPraticheInAttesa)) {
      setFetchingData(isFetchinglistaPraticheInAttesa)
      if (mostraChiuse) {
        const listaPraticheChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.chiusa)
        setListaPraticheInAttesaToShow(listaPraticheChiuse)
      }
      else if (!mostraChiuse && !mostraInAttesa) {
        const praticheAmlDeskNonChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] !== StatiPratica.chiusa
          //&& pratica.entry?.createdByUser.displayName === "Utente Interno"
          //|| userAssignedBelongToGroup(pratica.entry?.properties['folder_model:daAssegnare']) === 1
        )
        setListaPraticheInAttesaToShow(praticheAmlDeskNonChiuse)
      }
      else {
        setListaPraticheInAttesaToShow(listaPraticheInAttesa)
      }
    }

    else if (isValidUser('APPROVA_PRATICA_UTENTE_BASE') || isValidUser('APPROVA_PRATICA_FIELD_ANALYST')) {
      // 
      setFetchingData(isFetchingMonitoraggioOnBoardingListaPratiche)
      if (monitoraggioOnBoardingListaPratiche && mostraLeMiePratiche && !mostraTutteLePratiche) {
        const praticheVisualizzabiliDefaultDaApprovare = monitoraggioOnBoardingListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.daApprovare)
        setListaPraticheInAttesaToShow(praticheVisualizzabiliDefaultDaApprovare)
      }
      if (!mostraLeMiePratiche && !mostraTutteLePratiche) {
        const praticheDaApprovare = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.daApprovare)
        setListaPraticheInAttesaToShow(praticheDaApprovare)
        //console.log(praticheDaApprovare)
      }
      if (mostraTutteLePratiche && dataListaPratiche) {
        setListaPraticheInAttesaToShow(dataListaPratiche)
        //console.log(praticheVisualizzabiliDefault)
      }
    }

    else if (
      !isValidUser('APPROVA_PRATICA_UTENTE_BASE') &&
      !isValidUser('APPROVA_PRATICA_FIELD_ANALYST') &&
      !isValidUser('SUPPORTO_AML_DESK') &&
      !isValidUser('SUPPORTO_FUNZIONE_AML')) {
      setFetchingData(isFetchingListaPratiche)
      const praticheNonChiuse = dataListaPratiche?.filter(pratica =>
        pratica.entry?.properties['folder_model:statorichiesta'] !== StatiPratica.chiusa)
      setListaPraticheInAttesaToShow(praticheNonChiuse)
      if (mostraChiuse) {
        const listaPraticheChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.chiusa)
        setListaPraticheInAttesaToShow(listaPraticheChiuse)
      }
      else {
        setListaPraticheInAttesaToShow(praticheNonChiuse)
      }
    }
  }, [dataListaPratiche, listaPraticheInAttesa, mostraAnnullate, mostraChiuse, monitoraggioOnBoardingListaPratiche, mostraInAttesa, mostraLeMiePratiche, mostraTutteLePratiche, annulla])

  function handleMostraChiuse() {
    // 
    setMostraChiuse(!mostraChiuse)
  }

  function handleMostraInAttesa() {
    // 
    setMostraInAttesa(!mostraInAttesa)
  }

  function handleLeMiePratiche() {
    // 
    setMostraLeMiePratiche(!mostraLeMiePratiche)
  }

  function handleMostraAnnullate() {
    // 
    setMostraAnnullate(!mostraAnnullate)
  }

  function handleMostraTutteLePratiche() {
    //
    setMostraTutteLePratiche(!mostraTutteLePratiche)
  }

    
    
    
    function handleSubmit(event, key, statoPratica) {
        event.preventDefault()
        search(key, statoPratica).then(response => {
          setSearchResult(response.data.body)
          if (!monitoraggioOnBoardingListaPratiche) {
            setListaPratiche(resultByFlagChiusa(response.data.body, mostraChiuse))
          }
          else {
            setListaPraticheInAttesaToShow(resultByFlagChiusa(response.data.body, mostraChiuse))
          }
        })
      }
      function handleSearch(event, key, statoPratica) {
        event.preventDefault()
        if (key !== "") {
            
          search(key, statoPratica).then(response => {
            setSearchResult(response.data.body)
            if (!monitoraggioOnBoardingListaPratiche) {
              setListaPratiche(resultByFlagChiusa(response.data.body, mostraChiuse))
            }
            else {
              setListaPraticheInAttesaToShow(resultByFlagChiusa(response.data.body, mostraChiuse))
            }
          })
        }
      }
    
      function handleChange(event) {
        event.preventDefault()
        setSearchString(event.target.value)
        if(event?.target?.value==""||event?.target?.value==null){
          setListaPraticheInAttesaToShow(dataListaPratiche)
        }
      }
  return (
    
    <div>            
        <Header
        showHome={true}
        showRichiesta
        showStatistiche={false}
        header={
            <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            justifyContent="start"
            alignContent="start"
        >
            <Typography
                sx={{ flexGrow: 1 }}
                xs={12}
                item
                alignItems="center"
                mb={'1rem'}
                color={theme.palette.black.main}
                variant="h1"
            >
                STATISTICHE
            </Typography>
        </Grid>
        }
      />
       <Grid container spacing={2} mt={20}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            backgroundColor: '#C8C8C8',
            width: '100%',
            height: '110%',
            alignItems: 'center',
          }}
        >
          <Stack direction="column" alignItems="center">
            <GraficoPie
              colors={{ scheme: 'blue_purple' }}
              searchPraticheChiuse={mostraChiuse}
              display="flex"
              alignItems="center"
            />
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            backgroundColor: '#C8C8C8',
            width: '100%',
            height: '110%',
          }}
        >
          <Stack direction="column" alignItems="center">
            <GraficoPie2
              colors={{ scheme: 'blues' }}
              searchPraticheChiuse={mostraChiuse}
            />
          </Stack>
        </Box>
      </Grid>
    </Grid>

              


    </div>
    

  );
};

export default StatistichePanel;

export const resultByFlagChiusa = (result, mostraChiuse) => {
    const filteredResult = result?.filter(pratica => {
      const stato = pratica.entry?.properties['folder_model:statorichiesta']
      return mostraChiuse === true ? stato === 'Chiusa' : stato !== 'Chiusa'
    })
    return filteredResult
  }