import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, CircularProgress, Stack, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getMonitoraggioByAdv } from '../../services/getMonitoraggioByAdv';
import { styled } from '@mui/material/styles';

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  border: '4px solid red',
}));

const EvidenzaEsitoMonitoraggio = ({ idAdv }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMonitoraggioByAdv(idAdv)
      .then(responseData => {
        setData(responseData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Errore nel recupero dei dati:', error);
        setLoading(false);
      });
  }, [idAdv]);

  if (loading) {
    return <CircularProgress />;
  }

  // Verifica se ci sono dati da visualizzare
  if (data.length === 0) {
    return null; // Non visualizzare nulla se data è vuoto
  }

  return (
    <CustomAccordion>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        EVIDENZA ESITO MONITORAGGIO
      </AccordionSummary>
      <AccordionDetails>
        <Stack>
          {data.map((item, index) => (
            <div key={index}>
              <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                {'DATA APERTURA'}: {new Date(item.dataApertura).toLocaleDateString()}
              </Typography>
              <div></div>
              <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                {'INPUT INIZIALE'}
              </Typography>
              <ul style={{ marginLeft: '20px' }}>
                {item.inputInizialeList.map((input, idx) => (
                  <li key={idx}>{input}</li>
                ))}
              </ul>
            </div>
          ))}
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EvidenzaEsitoMonitoraggio;
