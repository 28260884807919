import { customAxios } from './customAxios';
export function closeAdvMr(datiPraticaAutomaticaMR) {
    //debugger
    const config = {
        headers: {
            "AuthToken": JSON.parse(localStorage.getItem('token')),
        },
    }
    const data = {
        //dati da passare nel body della chiamata 
        // datiPraticaAutomaticaMR-> dati da passare al BE
        "altro": datiPraticaAutomaticaMR.altro,
        "altroAnalisi": datiPraticaAutomaticaMR.altroAnalisi,
        "listAnalisi": datiPraticaAutomaticaMR.listAnalisi,
        "anno": datiPraticaAutomaticaMR.anno,
        "azioniDaPorreInEssere": datiPraticaAutomaticaMR.azioniDaPorreInEssere,
        "checkoutOperatore": datiPraticaAutomaticaMR.checkoutOperatore,
        "comportamentoSoggettoEsecutore": datiPraticaAutomaticaMR.comportamentoSoggettoEsecutore,
        "controlliAttivitaCliente": datiPraticaAutomaticaMR.controlliAttivitaCliente,
        "analisiSvolte":datiPraticaAutomaticaMR.analisiSvolte,
        "controlliSettore": datiPraticaAutomaticaMR.controlliSettore,
        "elevataProfessionalita": datiPraticaAutomaticaMR.elevataProfessionalita,
        "idNodoPratica": datiPraticaAutomaticaMR.idNodoPratica,
        //"infoAcquisite":datiPraticaAutomaticaMR.infoAcquisite,
        "noteAzioniDaPorreInEssere": datiPraticaAutomaticaMR.noteAzioniDaPorreInEssere,
        "noteCampo1": datiPraticaAutomaticaMR.noteCampo1,
        "noteCampo2": datiPraticaAutomaticaMR.noteCampo2,
        "noteCampo3": datiPraticaAutomaticaMR.noteCampo3,
        "noteCampo4": datiPraticaAutomaticaMR.noteCampo4,
        "radioAzioniDaPorreInEssere": datiPraticaAutomaticaMR.radioAzioniDaPorreInEssere,
        "risultaEssere": datiPraticaAutomaticaMR.risultaEssere,
        "documentiOpzionali": datiPraticaAutomaticaMR.documentiOpzionali,
        "aggiornaStato": true,
    }

    const closeAdvMrRequest = customAxios.post(
        process.env.REACT_APP_BACKEND_HOST + '/'+process.env.REACT_APP_PATH_NEW_PREFIX+'/closeAdvMRandAddAdvAR',
        data,
        config)
    return closeAdvMrRequest
}
