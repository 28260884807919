import { customAxios } from './customAxios';

export async function searchById(idMonitoraggio) {
    const config = {
        params: { idMonitoraggio: idMonitoraggio.toString() },
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token')),
        },
    };
    try {
        const response = await customAxios.get(`${process.env.REACT_APP_BACKEND_HOST}/adv/monitoraggio/searchbyid`, config);
        console.log('searchById response:', response); // Debug log
        return response.data;
    } catch (error) {
        console.error('Errore nella ricerca per ID:', error);
        throw error;
    }
}
