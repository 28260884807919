import { useState } from 'react'

import { StyledButton } from '../UI/StyledButton'

import { Typography, Stack, IconButton } from '@mui/material'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import { ZoomIn } from '@mui/icons-material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { ZoomOut } from '@mui/icons-material'
import DownloadIcon from '@mui/icons-material/Download';
export default function PDFViewer(props) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(0.5)
  const [openRegisterModal, setOpenRegisterModal] = useState(false)

  function handleZoomIn(event) {
    event.preventDefault()
    setScale(scale + 0.3)
  }

  function handleZoomOut(event) {
    event.preventDefault()
    setScale(scale - 0.3)
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    const newPageNumber = pageNumber + offset
    if (newPageNumber > 0 && newPageNumber <= numPages)
      setPageNumber(newPageNumber)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  function handleDownload(){
    const linkSource = `data:application/pdf;base64,${props.document}`
    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = props.title
    downloadLink.click()
  }
  function closeModal() {
    setOpenRegisterModal(false)
    // console.log(openRegisterModal)
  }
  return (
    <>
      <Stack sx={{ alignItems: 'center' }}>
        <Stack sx={{ alignItems: 'center' }}>
          <Typography>{props.title}</Typography>
          <Stack direction="row">
            <IconButton sx={{ paddingX: '5px' }} type="button">
              <PlayArrowIcon
                sx={{ transform: 'scaleX(-1)' }}
                onClick={previousPage}
                disabled={pageNumber <= 1}
              ></PlayArrowIcon>
            </IconButton>
            <IconButton>
              {' '}
              <ZoomOut onClick={handleZoomOut} />
            </IconButton>
            <IconButton>
              {' '}
              <DownloadIcon onClick={handleDownload} />
            </IconButton>
            <IconButton>
              {' '}
              <ZoomIn onClick={handleZoomIn} />
            </IconButton>
            <IconButton sx={{ paddingX: '5px' }} type="button">
              <PlayArrowIcon
                onClick={nextPage}
                disabled={pageNumber <= 1}
              ></PlayArrowIcon>
            </IconButton>
          </Stack>
          <Document
          
            // file={`data:application/pdf;base64,${CIAntonioBase64}`}
            file={`data:application/pdf;base64,${props.document}`}
            // file={props.document}
            onLoadSuccess={onDocumentLoadSuccess}
            // onLoadError={console.error}
          >
            <Page height={1000} pageNumber={pageNumber} scale={scale} />
          </Document>
        </Stack>
        <Stack>
          <Typography variant="caption">
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}
