import { customAxios } from './customAxios';

export function updateIndicatori(idMonitoraggio, idAzioneList, checkIndicatori, tipologiaDocumentiOpzionaliList, motivazione) {
  const config = {
    headers: {
      "AuthToken": JSON.parse(localStorage.getItem('token')), 
    }
  };

  const data = {
    idMonitoraggio: idMonitoraggio, 
    idAzioneList: idAzioneList, 
    checkIndicatori: checkIndicatori, 
    motivazione: motivazione, 
    tipologiaDocumentiOpzionaliList: tipologiaDocumentiOpzionaliList 
  };
debugger
  const updateIndicatoriRequest = customAxios.post(process.env.REACT_APP_BACKEND_HOST + '/adv/monitoraggio/update-indicatori', data, config);
  
  return updateIndicatoriRequest; 
}
