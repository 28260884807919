import { customAxios } from './customAxios'
export function getListaPraticheInAttesa() {
    const config = {
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }
    const listaPraticheInAttesa = customAxios.get(process.env.REACT_APP_BACKEND_HOST + "/"+process.env.REACT_APP_PATH_NEW_PREFIX+"/getFilteredListaPratiche", config).then(res => res.data.body)
    return listaPraticheInAttesa
}