import React, { useMemo, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Tooltip, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LOCALE_TEXT_ITA } from './LOCALE_TEXT_ITA';
import { AuthContext } from '../App';
import './ResultsTableMonitoring.css'; // Importa il CSS personalizzato


const ResultsTableMonitoring = ({ listaPratiche, isFetchingListaPratiche, onRowClick }) => {
  const { dispatch } = useContext(AuthContext);
  const theme = useTheme();

  const columns = useMemo(() => [
    {
      field: 'ndg',
      headerName: 'NDG',
      type: 'string',
      minWidth: 150,
      renderCell: (params) => (
        <Tooltip title={params.row.ndg} followCursor>
          <Typography className="table-cell-truncate">{params.row.ndg}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'codiceFiscale',
      headerName: 'Codice Fiscale',
      minWidth: 180,
      renderCell: (params) => (
        <Tooltip title={params.row.codiceFiscale} followCursor>
          <Typography className="table-cell-truncate">{params.row.codiceFiscale}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'ragioneSociale',
      headerName: 'Ragione Sociale',
      minWidth: 300,
      renderCell: (params) => (
        <Tooltip title={params.row.ragioneSociale} followCursor>
          <Typography className="table-cell-truncate">{params.row.ragioneSociale}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'livelloDiRischio',
      headerName: 'Livello di rischio',
      headerClassName: 'multi-line-header',

      minWidth: 100,
      renderCell: (params) => (
        <Tooltip title={params.row.livelloDiRischio} followCursor>
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%">

          <Typography className="table-cell-truncate">{params.row.livelloDiRischio}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'dataApertura',
      type: 'date',
      headerName: 'Data Apertura',
      minWidth: 180,
      renderCell: (params) => (
        <Tooltip title={params.row.dataApertura} followCursor>
          <Typography className="table-cell-truncate">{params.row.dataApertura ? new Date(params.row.dataApertura).toLocaleDateString() : ''}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'esitoSintetico',
      headerName: 'Presenza variazioni Cerved',
      headerClassName: 'multi-line-header',

      minWidth: 180,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.esitoSintetico} followCursor>
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <Typography className="table-cell-truncate">{params.row.esitoSintetico}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 220,
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params.row.status} followCursor>
          <Typography className="table-cell-truncate">{params.row.status.replace(/_/g, ' ')}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'dataStatus',
      headerName: 'Data Status',
      minWidth: 150,
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params.row.dataStatus} followCursor>
          <Typography className="table-cell-truncate">{params.row.dataStatus ? new Date(params.row.dataStatus).toLocaleDateString() : ''}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'giorniAperturaPratica',
      headerName: 'Giorni apertura pratica',
      headerClassName: 'multi-line-header',

      width: 80,
      renderCell: (params) => (
        <Tooltip title={params.row.giorniAperturaPratica} followCursor>
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <Typography className="table-cell-truncate" style={{ textAlign: 'center' }}>{params.row.giorniAperturaPratica}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'esitoMonitoraggioFondi',
      headerName: 'Presenza check uso fondi',
      headerClassName: 'multi-line-header',

      minWidth: 150,
      renderCell: (params) => (
        <Tooltip title={params.row.esitoMonitoraggioFondi} followCursor>
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%">

          <Typography className="table-cell-truncate">{params.row.esitoMonitoraggioFondi}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'inSofferenza',
      headerName: 'In sofferenza',
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const value = params.row.inSofferenza ? 'SI' : 'NO';
        return (
          <Tooltip title={value} followCursor>
            <Typography className="table-cell-truncate">{value}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'gestore',
      headerName: 'Gestore',
      minWidth: 250,
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params.row.gestore} followCursor>
          <Typography className="table-cell-truncate">{params.row.gestore}</Typography>
        </Tooltip>
      ),
    },

  ], []);

  return (
    <Box
      sx={{
        height: 526,
        width: '100%',
        overflowX: 'auto', // Abilita lo scrolling orizzontale
        '& .utenteBase': {
          backgroundColor: theme.palette.grey.light,
          color: theme.palette.black,
        },
        '& .fieldAnalyst': {
          backgroundColor: theme.palette.blue.light,
          color: theme.palette.black,
        },
      }}
    >
      <DataGrid
        disableSelectionOnClick
        sx={{ cursor: 'url(hand.cur), pointer' }}
        rows={listaPratiche || []}
        columns={columns}
        pageSize={10}
        loading={isFetchingListaPratiche}
        rowsPerPageOptions={[10]}
        getRowId={(row) => row.idMonitoraggio}
        localeText={LOCALE_TEXT_ITA}
        onRowClick={onRowClick}
        sortingOrder={['asc', 'desc']}
      />
    </Box>
  );
};

export default ResultsTableMonitoring;
