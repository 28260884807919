import { customAxios } from './customAxios';

export async function searchByCf(codiceFiscale) {
    const config = {
        params: { codice_fiscale: codiceFiscale },
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token')),
        },
    };
    try {
        const response = await customAxios.get(`${process.env.REACT_APP_BACKEND_HOST}/adv/monitoraggio/search-monitoraggio-by-cf`, config);
        console.log('searchByCf response:', response); // Debug log
        return response.data;
    } catch (error) {
        console.error('Errore nella ricerca per codice fiscale:', error);
        throw error;
    }
}
