import React from 'react'
import { useContext, useState, createContext } from 'react'
import {
    Box,
    FormControl,
    Grid,
    Typography,
    TextField,
    IconButton,
    Autocomplete,
    InputAdornment,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'


import LogoDividerContainer from '../containers/LogoDividerContainer'

import { AuthContext } from '../App'
import Header from '../UI/Header'

import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/system'

import { getListaPratiche } from '../services/getListaPratiche'
import { useEffect } from 'react'

export const redirectContext = createContext()

const NuovaRichiesta = () => {
    const theme = useTheme()
    const { state: AuthState } = useContext(AuthContext)

    const [redirectState, setRedirectState] = useState({
        richiestaPresente: false,
        richiestaMancante: false,
    })

    const [datiDettaglio, setDatiDettaglio] = useState({})
    // console.log(datiDettaglio)

    const [ricerca, setRicerca] = useState('')

    const navigate = useNavigate()

    // console.log(ricerca)

    const [nuovaRichiesta, setNuovaRichiesta] = useState('')

    const [beginDate, setBeginDate] = useState('')
    const [endDate, setEndDate] = useState('')

    function handleChangeRicerca(event) {
        event.preventDefault()
        setRicerca(event.target.value)
    }

    function handleChangeNuovaRichiesta(event) {
        event.preventDefault()
        setNuovaRichiesta(event.target.value)
    }

    function handleSearch(event) {
        event.preventDefault()
        if (ricerca.toLowerCase() === 'esquad') {
            setRedirectState({
                richiestaPresente: true,
                richiestaMancante: false,
            })
        } else {
            setRedirectState({
                richiestaPresente: false,
                richiestaMancante: true,
            })
        }
    }

    function handleNuovaRichiesta(event) {
        event.preventDefault()
        setRedirectState({
            richiestaPresente: false,
            richiestaMancante: true,
        })
    }

    const handleSubmit = event => {
        event.preventDefault()
        navigate('/nuova-richiesta-form', {
            state: { nomePratica: nuovaRichiesta },
        })
    }

    let selectedRowData = []
    const [listaPratiche, setListaPratiche] = useState([])
    useEffect(() => {
        getListaPratiche()
            .then(response => {
                setListaPratiche(response)
            })
            .catch(error => console.log(error))
    }, [])

    return (
        <>
            <Header
                showHome
                showRichiesta={false}
                header={
                    <Grid
                        container
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        justifyContent="start"
                        alignContent="start"
                    >
                        <Typography
                            sx={{ flexGrow: 1 }}
                            xs={12}
                            item
                            alignItems="center"
                            mb={'1rem'}
                            color={theme.palette.black.main}
                            variant="h1"
                        >
                            NUOVA RICHIESTA ADVR
                        </Typography>
                    </Grid>
                }
            />
            <LogoDividerContainer mt="10%" showLogo={false}>
                <Box
                    component="form"
                    noValidate
                    ml="20em"
                    sx={{ mt: 1, width: '100%' }}
                    onSubmit={handleSubmit}
                >
                    <FormControl sx={{ width: '100%', mt: '10%' }}>
                        <Autocomplete
                            freeSolo
                            id="nuova-richiesta-search-bar"
                            disableClearable
                            fullWidth
                            options={listaPratiche}
                            onChange={(e, value) => {
                                e.preventDefault()
                                selectedRowData = value
                                navigate('/dettaglio', {
                                    state: { selectedRowData: selectedRowData },
                                })
                            }}
                            getOptionLabel={option =>
                                option.entry.properties['folder_model:codiceFiscale'] +
                                ' - ' +
                                option.entry.properties['folder_model:ragioneSociale']
                            }
                            onSubmit={handleSubmit}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    sx={{ marginTop: '40px' }}
                                    margin="normal"
                                    required
                                    label="Cerca"
                                    fullWidth
                                    variant="standard"
                                    value={ricerca}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    type="button"
                                                    variant="contained"
                                                    onClick={handleSubmit}
                                                >
                                                    <ArrowForwardIcon fontSize="medium"></ArrowForwardIcon>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                    helperText="Codice Fiscale o Ragione Sociale"
                                    id="nuovaRichiesta"
                                    name="nuovaRichiesta"
                                    onChange={handleChangeNuovaRichiesta}
                                    autoFocus
                                />
                            )}
                        />
                    </FormControl>
                </Box>
            </LogoDividerContainer>
        </>
    )
}

export default NuovaRichiesta
