import { customAxios } from "./customAxios"
export function closeMonitoraggio(idMonitoraggio) {
    const config = {
        headers: {
            Authtoken: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json'
        }
    }

    const data = JSON.stringify({
        "idMonitoraggio": idMonitoraggio
    })
    const closeRequest = customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/adv/monitoraggio/close", data, config)
    return closeRequest
}