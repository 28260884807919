import React, { useMemo } from 'react'
import {
  Container,
  TextField,
  FormHelperText,
  RadioGroup,
  Radio,
  ListItemText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  TextareaAutosize,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  AccordionActions,
  FormControl,
  Button,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useState, useEffect } from 'react'
import CropSquareIcon from '@mui/icons-material/CropSquare'
import { StyledButton } from '../../UI/StyledButton'
import { saveEsitoAdv } from '../../services/saveEsitoAdv'
import CustomModal from '../../UI/CustomModal'
import OnlyWithFunction, { isValidUser } from '../../OnlyWithFunctions'
import { StatiPratica } from '../../utils/StatiPratica'
import SendModal from '../../UI/SendModal'
import { addDocumentiOpzionali } from '../../services/addDocumentiOpzionali'
import { sendRichiestaSupporto } from '../../services/sendRichiestaSupporto'
import { getFoldersTree } from '../../services/getFoldersTree'
import { Style } from '@mui/icons-material'
import { getAllElement } from '../../services/getAllElement'
import { saveMonitoraggio } from '../../services/saveMonitoraggio'
import { closeMonitoraggio } from '../../services/closeMonitoraggio'
import { approveDocs } from '../../services/approveDocs'
import { assignTo } from '../../services/assignTo'
import { getListaUtenzeAssegnatariMonitoraggio } from '../../services/getListaUtenzeAssegnatariMonitoraggio'
import { updateIndicatori } from '../../services/updateIndicatori'
import EditableAccordion from './EditableAccordion'
import EditableAccordionMonitoraggio from './EditableAccordionMonitoraggio'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function EsitoMonitoraggio(props) {
  const theme = useTheme()
  const navigate = useNavigate()

  const [checkIndicatori, setCheckIndicatori] = React.useState([])
  const [indicatorStatus, setIndicatorStatus] = useState({})
  const [inputIniziale, setInputIniziale] = React.useState([])
  const [esitoMonFondi, setEsitoMonFondi] = React.useState([])
  const [azioni, setAzioni] = React.useState([])
  const [listDocOpzionali, setListDocOpzionali] = React.useState([])
  const [praticaStatus, setPraticaStatus] = useState('')
  const [isPraticaChiusa, setIsPraticaChiusa] = useState(false)
  const [isPraticaAperta, setIsPraticaAperta] = useState(false)

  const [isPraticaAdvInCorso, setIsPraticaAdvInCorso] = useState(false)
  const [showRichiestaDocumenti, setShowRichiestaDocumenti] = useState(false)

  const [isPraticaAdvObbligata, setIsPraticaAdvObbligata] = useState(false)
  const [isChiusuraForzata, setIsChiusuraForzata] = useState(false)
  const [isPraticaAttesaDocumenti, setIsPraticaAttesaDocumenti] = useState(false)
  const [isPraticaAttesaApprovazione, setIsPraticaAttesaApprovazione] = useState(false)

  const [showDocButton, setShowDocButton] = useState(false)
  const [showDocSection, setShowDocSection] = useState(false)
  const [selectedDocs, setSelectedDocs] = useState([])
  const [selectedAzione, setSelectedAzione] = useState([])
  const [checkedState, setCheckedState] = useState([])
  const [showButtonChiudi, setShowButtonChiudi] = useState(false)
  const [showButtonSalva, setShowButtonSalva] = useState(false)
  const [motivazione, setMotivazione] = useState('')
  const [showMotivazione, setShowMotivazione] = useState(false)
  const [motivazioneObbligatoria, setMotivazioneObbligatoria] = useState(true)
  const [save, setSave] = useState(false)
  const [update, setUpdate] = useState(false)

  const [loading, setLoading] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [error, setError] = useState(false)
  const [showButtonAssegna, setShowButtonAssegna] = useState(false)
  const [showButtonApprova, setShowButtonApprova] = useState(false)
  const [showButtonRifiuta, setShowButtonRifiuta] = useState(false)
  const [showMenuAssegna, setShowMenuAssegna] = useState(false)
  const [utentiAssegnatari, setUtentiAssegnatari] = useState([])
  const [selectedUser, setSelectedUser] = useState('')
  const [showDocRifiutata, setShowDocRifiutata] = useState(false)
  const [shouldSave, setShouldSave] = useState(false)
  const [shouldSaveAndClose, setShouldSaveAndClose] = useState(false)
  const [isValidStateForUpdateIndicatori, setIsValidStateForUpdateIndicatori] = useState(false)
  const [isGestorePratica, setIsGestorePratica] = useState(false)

  const [formData, setFormData] = useState({
    checkIndicatori: [],
    idAzioneList: [],
    idMonitoraggio: null,
    motivazione: '',
    tipologiaDocumentiOpzionaliList: [],
    attivitaSvolteList:[],

  })

  const [documentiOpzionaliPresenti, setDocumentiOpzionaliPresenti] = useState(false)
  const [alreadyExist, setAlreadyExist] = useState(false)
  const [saved, setSaved] = useState(props.sezioneGiallaSalvata ? true : false)
  const [docName, setDocName] = React.useState([])
  const [openSubMenu, setOpenSubMenu] = React.useState(false)
  const [checked, setChecked] = React.useState(false)

  const [sendDocOpzionali, setSendDocOpzionali] = React.useState({
    idNodoPratica: props.idNodoPratica,
    documentiOpzionali: [''],
  })

  useEffect(() => {
    if (props.dettaglioPratica) {
      setPraticaStatus(props.dettaglioPratica.status)
      setIsPraticaChiusa(props.dettaglioPratica.status === 'CHIUSA')
      setInputIniziale(props.dettaglioPratica.inputInizialeList)
      setFormData(prevFormData => ({
        ...prevFormData,
        idMonitoraggio: props.dettaglioPratica.idMonitoraggio,
        motivazione: props.dettaglioPratica.motivazione,
        attivitaSvolteList: props.dettaglioPratica.attivitaSvolteList
      }))
      setMotivazione(props.dettaglioPratica.motivazione)
      setIsValidStateForUpdateIndicatori(props.dettaglioPratica.status === 'APERTA' || props.dettaglioPratica.status === 'ADV_OBBLIGATA' || props.dettaglioPratica.status === 'DA_VERIFICARE_OPERATORE' )

      const esitoMonFondiList = props.dettaglioPratica.esitoMonfondiList.map(item => ({
        statoDiLavorazione: item.statoDiLavorazione,
        numeroRapporto: item.numeroRapporto,
      }))
      setEsitoMonFondi(esitoMonFondiList)
      if (props.dettaglioPratica && props.dettaglioPratica.gestore === null) {

        setShowButtonAssegna(true)
      }
      if (props.dettaglioPratica && props.dettaglioPratica.status) {
        if (props.dettaglioPratica.status === 'ADV_IN_CORSO') {
          setShowButtonChiudi(false)
          setShowButtonSalva(false)
          setIsPraticaAdvInCorso(true)
          setShowButtonAssegna(false)
          setMotivazioneObbligatoria(false)
        } else if (props.dettaglioPratica.status === 'INFO_COMPLETE') {
          setShowButtonChiudi(true)
          setShowButtonSalva(true)
          setIsPraticaAdvInCorso(false)
          setShowMotivazione(true)
        } else if (props.dettaglioPratica.status === 'APERTA_IN_ATTESA_APPROVAZIONE_DOCUMENTI') {
          setShowRichiestaDocumenti(false)
          setShowMotivazione(false)
          setShowButtonApprova(true)
          setShowButtonRifiuta(true)
          setIsPraticaAttesaApprovazione(true)


        } else if (props.dettaglioPratica.status === 'APERTA_IN_ATTESA_DOCUMENTI') {
          setShowRichiestaDocumenti(false)
          setShowButtonApprova(false)
          setShowButtonRifiuta(false)
          setShowMotivazione(false)
          setIsPraticaAttesaDocumenti(true)
        } else if (props.dettaglioPratica.status === 'ADV_OBBLIGATA') {
          setIsPraticaAdvObbligata(true)
          setShowMotivazione(true)
          setMotivazioneObbligatoria(false)
        } else if (props.dettaglioPratica.status === 'APERTA') {
          setShowRichiestaDocumenti(true)
          setIsPraticaAperta(props.dettaglioPratica.status === 'APERTA')
          if (props.dettaglioPratica.monitoraggioAzioni.length > 0) {
            setShowButtonSalva(true)
            setShowButtonChiudi(true)
            setShowMotivazione(true)
          }
          if ((isApprovatore && !showDocRifiutata)) {

            setShowRichiestaDocumenti(false)
            setShowButtonApprova(false)
            setShowButtonRifiuta(false)
            if (props.dettaglioPratica.gestore === null) {
              setShowButtonAssegna(true)

            }
          }

        } else if (props.dettaglioPratica.status === 'CHIUSA') {
          setShowMotivazione(true)
        }

      }

      if (props.dettaglioPratica && props.dettaglioPratica.monitoraggioAzioni) {

        const idsAzioni = props.dettaglioPratica.monitoraggioAzioni.map(azione => azione.id)
        const rifiuto = props.dettaglioPratica.monitoraggioAzioni.some(azione => (azione.isApproved === false && azione.id === 9))
        if (rifiuto === true) {
          setShowDocRifiutata(true)

        } else {
          setShowDocRifiutata(false)
        }
        setSelectedAzione(idsAzioni)
        setCheckedState(idsAzioni)

        setFormData(prevFormData => ({
          ...prevFormData,
          idAzioneList: idsAzioni,
          //motivazione: motivazioniAzioni
        }))
        //setMotivazione(motivazioniAzioni);
      }

      if (props.dettaglioPratica && props.dettaglioPratica.checkIndicatoriList) {
        setCheckIndicatori(props.dettaglioPratica.checkIndicatoriList)
        const initialStatus = {}
        props.dettaglioPratica.checkIndicatoriList.forEach(indicator => {
          initialStatus[indicator.id] = indicator.status
        })
        setIndicatorStatus(initialStatus)
        setFormData(prevFormData => ({ ...prevFormData, checkIndicatori: props.dettaglioPratica.checkIndicatoriList }))
      }

      if (props.dettaglioPratica.status === 'ADV_OBBLIGATA') {
        setShowMotivazione(true)
      }


      if (props.dettaglioPratica && props.dettaglioPratica.documentoList) {
        const selectsDocs = props.dettaglioPratica.documentoList.map(doc => ({
          id: doc.id,
          label: doc.label,
        }))
        if (selectsDocs.length > 0) {
          setSelectedDocs(selectsDocs)
          setShowDocButton(true)
          setShowDocSection(true)
        }
      }

    }
  }, [props.dettaglioPratica])

  useEffect(() => {
    if (props.dettaglioPratica && props.dettaglioPratica.gestore !== null && props.dettaglioPratica.gestore !== '') {
      try {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser === props.dettaglioPratica.gestore) {
          setIsGestorePratica(true);
        } else {
          setIsGestorePratica(false);
        }
      } catch (error) {
        console.error("Errore nel parsing del user da localStorage:", error);
        setIsGestorePratica(false); // Imposta a false se c'è un errore
      }
    } else {
      setIsGestorePratica(false);
    }
  }, [props.dettaglioPratica.gestore]);

  useEffect(() => {
    const fetchAzioni = async () => {
      try {
        const response = await getAllElement()
        if (response && response.data.azioneList) {
          let filteredAzioni
          if (isPraticaAdvObbligata) {
            filteredAzioni = response.data.azioneList.filter(
              (azione) => azione.id === 8 || azione.id === 10,
            )
          } else if (isPraticaAdvInCorso) {
            filteredAzioni = response.data.azioneList.filter(
              (azione) => azione.id === 1,
            )
          } else if (isPraticaChiusa) {
            filteredAzioni = response.data.azioneList

          } else {
            filteredAzioni = response.data.azioneList.filter(
              (azione) => azione.id !== 8 && azione.id !== 10,
            )
          }

          setAzioni(filteredAzioni)
        }

      } catch (error) {
        console.error('Errore nel recupero delle azioni:', error)
      }
    }

    fetchAzioni()
  }, [isPraticaAdvObbligata, isPraticaAdvInCorso, isPraticaChiusa])

  useEffect(() => {
    const fetchDocumentiOpzionali = async () => {
      try {
        const response = await getAllElement()
        if (response && response.data.documentiOpzionaliList) {
          setListDocOpzionali(response.data.documentiOpzionaliList)
        }
      } catch (error) {
        console.error('Errore nel recupero documenti opzionali:', error)
      }
    }

    fetchDocumentiOpzionali()
  }, [])

  useEffect(() => {
    if (save && !loading) {
      const timer = setTimeout(() => {
        setSave(false)
      }, 3000) // Chiudi il modale dopo 3 secondi
      return () => clearTimeout(timer)
    }
  }, [save, loading])
  useEffect(() => {
    if (shouldSave) {
      handleSave()
      setShouldSave(false)
    }
  }, [formData, shouldSave])


  useEffect(() => {
    if (shouldSaveAndClose) {
      handleSaveAndClose()
      setShouldSaveAndClose(false)
    }
  }, [formData, shouldSaveAndClose])


  const handleCheckboxChange = (event, id) => {
    //debugger
    setCheckedState(prevCheckedState => {
      const updatedCheckedState = prevCheckedState ? [...prevCheckedState] : []

      if (updatedCheckedState.includes(id)) {
        // Deseleziona l'ID se già selezionato
        if (id === 9) {
          setShowDocButton(false)
          setShowDocSection(false)
          setShowMotivazione(false)
          setShowButtonSalva(false)
          setShowButtonChiudi(false)
        } else if (id !== 9 && id !== 10) {
          //setShowMotivazione(false)
          if (id === 8)
            setMotivazioneObbligatoria(true)
          else if (!motivazioneObbligatoria)
            setMotivazioneObbligatoria(true)

          setShowButtonSalva(false)
          setShowButtonChiudi(false)
        } else if (id === 10) {
          setIsChiusuraForzata(false)
          //setShowMotivazione(false)
          setShowButtonSalva(false)
          setShowButtonChiudi(false)
        }
        const newState = updatedCheckedState.filter(item => item !== id)
        setFormData(prevFormData => ({
          ...prevFormData,
          idAzioneList: newState,
        }))
        return newState
      } else if (praticaStatus === 'APERTA' && showDocRifiutata) {
        if (updatedCheckedState.includes(9)) {
          if (updatedCheckedState.length < 2) {
            setShowMotivazione(true)
            setShowButtonSalva(true)
            setShowButtonChiudi(true)
            const newState = [...updatedCheckedState, id]
            setFormData(prevFormData => ({
              ...prevFormData,
              idAzioneList: newState,
            }))
            return newState
          } else {
            return updatedCheckedState // Non aggiungere nuovi elementi se la lunghezza è già 2
          }
        } else if (id === 9) {
          const newState = [...updatedCheckedState, id]
          setShowButtonSalva(false)
          setShowDocButton(true)
          setFormData(prevFormData => ({
            ...prevFormData,
            idAzioneList: newState,
          }))
          return newState
        } else {
          if (updatedCheckedState.length === 0) {
            const newState = [...updatedCheckedState, id]
            setFormData(prevFormData => ({
              ...prevFormData,
              idAzioneList: newState,
            }))
            return newState
          } else {
            return updatedCheckedState // Non aggiungere nuovi elementi se la lunghezza è già 1 e non è 9
          }
        }
      } else if (praticaStatus !== 'INFO_COMPLETE' && updatedCheckedState.length === 0) {
        // Permetti di selezionare un checkbox qualsiasi quando lo stato non è INFO_COMPLETE e nessun checkbox è selezionato


        if (id === 9) {
          setShowDocButton(true)
          setShowMotivazione(false)
          setShowButtonSalva(false)
          setShowButtonChiudi(false)
        } else if (id === 8) {
          if (!showMotivazione)
            setShowMotivazione(true)
          setMotivazioneObbligatoria(false)
          setShowButtonSalva(false)
          setShowButtonChiudi(false)
        } else if (id === 10) {
          setShowButtonSalva(false)
          if (!showMotivazione)
            setShowMotivazione(true)
          setShowButtonChiudi(true)
          setIsChiusuraForzata(true)

        } else {
          if (!showMotivazione)
            setShowMotivazione(true)
          setShowButtonSalva(true)
          setShowButtonChiudi(true)
        }
        const newState = [...updatedCheckedState, id]
        setFormData(prevFormData => ({
          ...prevFormData,
          idAzioneList: newState,
        }))
        return newState
      } else if (praticaStatus === 'INFO_COMPLETE') {
        // Permetti di selezionare fino a due ID solo se uno di essi è 9 quando lo stato è INFO_COMPLETE
        if ((updatedCheckedState.includes(9) || id === 9) && updatedCheckedState.length < 2) {
          setShowMotivazione(true)
          if (id === 9 && event.target.checked) {
            setShowButtonSalva(false)
            setShowDocButton(true)
          } else if (updatedCheckedState.includes(9)) {
            setShowButtonSalva(true)
            setShowButtonChiudi(true)
            setShowDocButton(false)
          }
          const newState = [...updatedCheckedState, id]
          setFormData(prevFormData => ({
            ...prevFormData,
            idAzioneList: newState,
          }))
          return newState
        } else if (updatedCheckedState.length < 2) {
          const newState = [...updatedCheckedState, id]
          setFormData(prevFormData => ({
            ...prevFormData,
            idAzioneList: newState,
          }))
          return newState
        } else {
          return updatedCheckedState // Non aggiungere nuovi elementi se la lunghezza è già 2
        }
      } else {
        return updatedCheckedState // Non aggiungere nuovi elementi se la lunghezza è già 1 e non è INFO_COMPLETE
      }
    })
  }


  useEffect(() => {
    if (update) {
      const timer = setTimeout(() => {
        handleModalClose() // Chiudi il modale dopo 3 secondi
      }, 2000) // 3000 ms = 3 secondi

      return () => clearTimeout(timer) // Pulisci il timer quando il componente viene smontato
    }
  }, [update]) // Questo effetto si attiva quando `save` o `update` cambia


  const handleShow = () => {
    setShowDocSection(!showDocSection)
  }


  const handleUpdate = async () => {
    try {
      const response = await updateIndicatori(
        formData.idMonitoraggio,
        formData.idAzioneList,
        formData.checkIndicatori,
        formData.tipologiaDocumentiOpzionaliList,
        formData.motivazione,
      )

      console.log('Aggiornamento indicatori riuscito:', response.data)
      //debugger
      // Aggiorna i dettagli della pratica nel componente genitore
      props.onUpdatePratica(response.data)

      setInfoMessage('Aggiornamento indicatori riuscito')
      setUpdate(true)

    } catch (error) {
      console.error('Errore durante l\'aggiornamento degli indicatori:', error)
    }
  }

  const handleModalClose = () => {
    setSave(false)
    setUpdate(false)
  }


  const handleUserChange = (event) => {
    const user = event.target.value
    handleAssignTo(props.dettaglioPratica.idMonitoraggio, user)
  }
  const handleMotivazioneChange = (event) => {
    setMotivazione(event.target.value)
    setFormData(prevFormData => ({ ...prevFormData, motivazione: event.target.value }))
  }

  const handleDocChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedDocs(typeof value === 'string' ? value.split(',') : value)
    //setShowButtonSalva(true)
    //setShowButtonChiudi(true)
  }

  const handleRequestDocs = () => {

    setFormData(prevFormData => ({
      ...prevFormData,
      tipologiaDocumentiOpzionaliList: selectedDocs.map(doc => ({
        id: doc.id,
        label: doc.label,
      })),
    }))

    setShouldSave(true)

    //setShowButtonSalva(true)
    //setShowButtonChiudi(true)
  }

  const handleApproveDocumenti = async (approvazione) => {
    try {
      setLoading(true)
      setSave(true)

      const response = await approveDocs(formData.idMonitoraggio, approvazione)

      //const responseData = response.data; // Estrai response.data

      if (approvazione) {
        setInfoMessage('Approvazione riuscita')
        setError(false)
        setLoading(false)
        props.onUpdatePratica(response.data) // Aggiorna i dettagli della pratica nel componente genitore

      } else {
        setInfoMessage('Rifiuto riuscito')
        setError(false)
        setLoading(false)
        props.onUpdatePratica(response.data) // Aggiorna i dettagli della pratica nel componente genitore

      }

    } catch (error) {
      setInfoMessage('Errore nell\'operazione')
      setError(true)
      setLoading(false)
    }
  }

  const handleChangePresaVisione = (event) => {
    setChecked(event.target.checked)
    if (event.target.checked) {
      setShowButtonChiudi(true)
    } else {
      setShowButtonChiudi(false)
    }
  }

  const handleStatusChange = (event, id) => {
    const newStatus = event.target.value
    setIndicatorStatus(prevStatus => ({
      ...prevStatus,
      [id]: newStatus,
    }))
    setFormData(prevFormData => {
      const updatedIndicatori = prevFormData.checkIndicatori.map(indicator =>
        indicator.id === id ? { ...indicator, status: newStatus } : indicator,
      )
      return { ...prevFormData, checkIndicatori: updatedIndicatori }
    })
  }

  const filteredIndicatori = checkIndicatori.filter(indicator => {
    const status = indicatorStatus[indicator.id]
    return status === 'SI' || status === 'KO' || status === 'NO'
  })

  const handleSave = () => {
    setLoading(true)
    setSave(true)
    setInfoMessage('Salvataggio in corso...')

    return saveMonitoraggio(formData)
      .then(response => {
        setInfoMessage('Salvataggio riuscito')
        setError(false)
        setLoading(false)
        setTimeout(function(){
          props.onUpdatePratica(response.data)
          return response // Restituisci la risposta per la catena delle promesse
        },3000)

      })
      .catch(error => {
        const err = error.response?.data?.message || error.message
        setInfoMessage(`Errore nel salvataggio del Monitoraggio:\n${err}`)
        setError(true)
        setLoading(false)
        throw error // Rilancia l'errore per la catena delle promesse
      })
  }


  const handleGetAssegnatari = (monitoraggio) => {
    getListaUtenzeAssegnatariMonitoraggio(monitoraggio)
      .then(response => {
        const utentiMonitoraggio = response
        //localStorage.setItem('DaAssegnareMonitoraggio', utentiMonitoraggio)
        setUtentiAssegnatari(utentiMonitoraggio)
        setShowMenuAssegna(true)
      })
      .catch(error => {

      })
  }
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (loading) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [loading]);


  const handleAssignTo = (idMonitoraggio, username) => {
    debugger
    setLoading(true)
    setSave(true)
    setInfoMessage('Assegnazione in corso')
    assignTo(idMonitoraggio, username)
      .then(response => {
        setInfoMessage('Assegnazione effettuata')
        setLoading(false)
        setError(false)
        props.onUpdatePratica(response.data)
        setShowButtonAssegna(false)
        setTimeout(() => {
          //navigate('/monitoring')
        }, 3000)
      })
      .catch(error => {
        setInfoMessage('Errore assegnazione')
        setError(true)
        setLoading(false)
      })
  }
  const handleUpdateAttivita = (updatedList) => {
    setShowButtonSalva(true);

    setFormData((prevState) => ({
      ...prevState,
      attivitaSvolteList: updatedList,
    }));

    console.log('Updated attivitaSvolteList in formData:', updatedList);
  };
  const handleClose = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      // aggiungi qui altri dati se necessario
    }))
    setShouldSaveAndClose(true)
  }

  const handleSaveAndClose = () => {
    //debugger
    if (!isPraticaAdvObbligata || isChiusuraForzata) {
      handleSave()
        .then(() => {
          setLoading(true)
          setSave(true)
          setInfoMessage('Chiusura monitoraggio in corso...')

          return closeMonitoraggio(formData.idMonitoraggio)
        })
        .then(response => {
          //debugger
          setInfoMessage('Monitoraggio chiuso')
          setLoading(false)
          setTimeout(function(){
            props.onUpdatePratica(response.data) // Aggiorna i dettagli della pratica nel componente genitore
            navigate('/monitoring')
          },3000)

        })
        .catch(error => {
          const err = error.response?.data?.message || error.message
          setInfoMessage(`Errore nella chiusura del Monitoraggio:\n${err}`)
          setError(true)
          setLoading(false)
        })
    } else {
      // Azioni da eseguire quando isPraticaAdvObbligata è true
      setLoading(true)
      setSave(true)

      setInfoMessage('Chiusura monitoraggio in corso...')

      closeMonitoraggio(formData.idMonitoraggio)
        .then(response => {
          setInfoMessage('Chiusura monitoraggio completata')
          setLoading(false)
          props.onUpdatePratica(response.data) // Aggiorna i dettagli della pratica nel componente genitore
          navigate('/monitoring')
        })
        .catch(error => {
          const err = error.response?.data?.message || error.message
          setInfoMessage(`Errore nella chisura del monitoraggio:\n${err}`)
          setError(true)
          setLoading(false)
        })
    }
  }


  const isUserAml = isValidUser('FUNZIONE_AML')
  const isApprovatore = isValidUser('APPROVATORE_MONITORAGGIO')
  return (
    <Box>
      <Stack sx={{ justifyContent: 'flex-end', width: '100%' }}>

        {(isValidUser('FUNZIONE_AML') &&
          <Stack sx={{ marginLeft: 'auto' }}>

            {showButtonAssegna && (
              <StyledButton
                onClick={() => handleAssignTo(props.dettaglioPratica.idMonitoraggio, JSON.parse(localStorage.getItem('user')))}
                sx={{ borderRadius: '5px 0px 0px 0px', mt: 5 }}
              >
                ASSEGNA A ME
              </StyledButton>
            )}
          </Stack>


        )}
        {(props.dettaglioPratica.gestore === null || props.dettaglioPratica.gestore === '') && (isValidUser('APPROVATORE_MONITORAGGIO') &&
          <Stack sx={{ marginLeft: 'auto', alignItems: 'flex-end' }}>
            {showButtonAssegna && (
              <StyledButton
                onClick={() => handleGetAssegnatari(true)}
                sx={{ borderRadius: '5px 0px 0px 0px', mt: 5 }}
              >
                ASSEGNA A
              </StyledButton>
            )}

            {showMenuAssegna && (
              <FormControl sx={{ mt: 2, minWidth: 200 }}>
                <InputLabel>Seleziona Utente</InputLabel>
                <Select
                  value={selectedUser}
                  onChange={handleUserChange}
                  displayEmpty
                >
                  {utentiAssegnatari.map((utente, index) => (
                    <MenuItem key={index} value={utente}>
                      {utente}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}


          </Stack>


        )}
      </Stack>
      <Stack sx={{ display: 'flex' }}>
        <Container sx={{ width: '100%', ml: 0, mt: 4 }}>
          <Stack>
            <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
              {'INPUT INIZIALE'}
            </Typography>
            <ul style={{ marginLeft: '0px' }}>
              {inputIniziale.map((indicator, index) => (
                <li key={index}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                  <Stack sx={{ padding: 0, width: '250px' }}>
          <span style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
            <span style={{ marginRight: '8px' }}>•</span>
            {indicator}
          </span>
                  </Stack>
                </li>
              ))}
            </ul>
          </Stack>

          <Stack>
            <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
              INDICATORI RILEVATI
            </Typography>
            {checkIndicatori.length > 0 ? (
              <Stack sx={{ paddingLeft: '10px' }}>
                <ul>
                  {checkIndicatori.map((indicator, index) => (
                    <li key={index}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                      <Stack sx={{ padding: 0, width: '250px' }}>
                        <span style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                          <span style={{ marginRight: '8px' }}>•</span>
                          {indicator.nome}
                        </span>
                      </Stack>
                      {!(isPraticaChiusa || isUserAml || isApprovatore || isPraticaAdvInCorso || praticaStatus === 'INFO_COMPLETE') && (
                        <FormControl sx={{ minWidth: 80 }} size="small">
                          <Select
                            disabled={isPraticaChiusa || isUserAml || isApprovatore || isPraticaAdvInCorso || praticaStatus === 'INFO_COMPLETE' || praticaStatus === 'APERTA_IN_ATTESA_APPROVAZIONE_DOCUMENTI' || praticaStatus === 'APERTA_IN_ATTESA_DOCUMENTI' }
                            value={indicatorStatus[indicator.id] || ''}
                            onChange={(event) => handleStatusChange(event, indicator.id)}
                            displayEmpty
                          >
                            <MenuItem value="SI">SI</MenuItem>
                            <MenuItem value="NO">NO</MenuItem>
                            <MenuItem value="KO">KO</MenuItem>
                          </Select>
                        </FormControl>)}

                    </li>
                  ))}
                </ul>
                {isValidUser('UTENTE_INTERNO') && isValidStateForUpdateIndicatori && (
                  <StyledButton
                    onClick={handleUpdate}
                    sx={{
                      borderRadius: '5px 0px 0px 5px',
                      mt: '20px',
                      alignSelf: 'flex-end',
                      paddingBottom: '10px',
                      paddingTop: '10px',
                    }}
                  >
                    Aggiorna indicatori
                  </StyledButton>)}
              </Stack>
            ) : (
              <Typography variant="caption" sx={{ mt: 2, mb: 4 ,paddingLeft: '10px'  }}>
                Nessun indicatore rilevato
              </Typography>
            )}
          </Stack>
          <Stack sx={{ mb: 3 }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '1rem',mb: 2 }}>
              {'ATTIVITÀ SVOLTE'}
            </Typography>
            <EditableAccordionMonitoraggio
              //rowsData={rowsData}
              attivitaSvolteList={formData.attivitaSvolteList }
              onUpdateAttivita={handleUpdateAttivita}
              canAddAttivita={props.dettaglioPratica.status !== 'ADV_IN_CORSO' && props.dettaglioPratica.status !== 'CHIUSA' }
            />
          </Stack>


          {esitoMonFondi.length > 0 && (
            <Stack>
              <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                {'ESITO MONITORAGGIO FONDI'}
              </Typography>
              <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none', mb: 2 , paddingLeft: '10px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '50%', padding: '4px' }}><Typography variant="caption"
                                                                                   sx={{ fontWeight: 'bold' }}>Indicatore</Typography></TableCell>
                      <TableCell sx={{ width: '50%', padding: '4px' }}><Typography variant="caption"
                                                                                   sx={{ fontWeight: 'bold' }}>Numero
                        Rapporto</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {esitoMonFondi.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ padding: '4px' }}>{item.statoDiLavorazione}</TableCell>
                        <TableCell sx={{ padding: '4px' }}>{item.numeroRapporto}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          )}
        </Container>

        <Stack sx={{ ml: 3 }}>
          <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
            {'AZIONI'}
          </Typography>

          <Stack sx={{ paddingLeft: '10px' }}>
            {azioni.map((azione) => (
              isPraticaAdvInCorso || selectedAzione.includes(azione.id) || (!isPraticaChiusa && !isPraticaAdvInCorso) || (!isPraticaAdvInCorso || azione.id === 8) ? (
                <Stack direction="row" key={azione.id}>
                  <FormControlLabel
                    label={<Typography variant="caption" sx={{ fontSize: '1rem' }}>{azione.label}</Typography>}
                    control={
                      <Checkbox
                        value={azione.label}
                        checked={checkedState.includes(azione.id)}
                        onChange={(event) => handleCheckboxChange(event, azione.id)}
                        size="small"
                        disabled={isPraticaAdvInCorso || isPraticaChiusa /*|| praticaStatus === 'ADV_OBBLIGATA'*/ || praticaStatus === 'APERTA_IN_ATTESA_APPROVAZIONE_DOCUMENTI' || (selectedAzione.includes(azione.id) && azione.id === 9) || props.dettaglioPratica.gestore === null || !isGestorePratica }
                      />
                    }
                  />
                  {((azione.id === 8) && selectedAzione.includes(8) && !isPraticaChiusa && !isPraticaAperta) && (
                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleChangePresaVisione}
                            disabled={!isGestorePratica}
                            sx={{
                              mb: 1,
                              alignSelf: 'flex-start',
                              marginTop: '6px',
                            }}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              alignSelf: 'flex-end',
                              mb: 0.5,
                            }}
                          >
                            Presa visione
                          </Typography>
                        }
                      />
                    </Stack>
                  )}
                </Stack>
              ) : null
            ))}
            {showDocRifiutata && (
              <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '2rem', color: 'red' }}>
                {'DOCUMENTAZIONE RIFIUTATA'}
              </Typography>)}

            {showDocButton && (
              <StyledButton
                onClick={handleShow}
                sx={{
                  borderRadius: '5px 0px 0px 5px',
                  mt: '20px',
                  alignSelf: 'flex-end',
                  paddingBottom: '10px',
                  paddingTop: '0px',
                }}
              >
                ...
              </StyledButton>
            )}
            {showDocSection && (
              <Stack>
                <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  DOCUMENTI (OPZIONALI) VARI
                </Typography>
                {listDocOpzionali && listDocOpzionali.length > 0 ? (
                  <FormControl sx={{ mt: 2, minWidth: 200 }}>
                    <InputLabel sx={{ fontSize: '0.75 rem' }}>Seleziona Documenti</InputLabel>
                    <Select
                      multiple
                      value={selectedDocs}
                      onChange={handleDocChange}
                      renderValue={(selected) => selected.map(doc => doc.label).join(', ')}
                      disabled={isPraticaAttesaDocumenti || isPraticaChiusa || isPraticaAttesaApprovazione}
                    >
                      {listDocOpzionali.map((doc, index) => (
                        <MenuItem key={index} value={doc}>
                          <Checkbox checked={selectedDocs.indexOf(doc) > -1} />
                          <ListItemText primary={doc.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography variant="caption">Nessun documento disponibile</Typography>
                )}

                {showRichiestaDocumenti && (
                  <Stack>
                    {selectedDocs.length > 0 && (
                      <StyledButton
                        onClick={handleRequestDocs}
                        sx={{ borderRadius: '5px 0px 0px 0px', mt: 5 }}
                      >
                        RICHIESTA DOCUMENTI OPZIONALI
                      </StyledButton>
                    )}
                  </Stack>
                )}

                {(isValidUser('APPROVATORE_MONITORAGGIO') ) && (


                  <Stack>
                    {((selectedDocs.length > 0) && showButtonApprova) && (
                      <StyledButton
                        onClick={() => handleApproveDocumenti(true)}
                        sx={{ borderRadius: '5px 0px 0px 0px', mt: 5 }}
                      >
                        APPROVA DOCUMENTI
                      </StyledButton>
                    )}

                    {((selectedDocs.length > 0) && showButtonRifiuta) && (
                      <StyledButton
                        onClick={() => handleApproveDocumenti(false)}
                        sx={{ borderRadius: '5px 0px 0px 0px', mt: 5 }}
                      >
                        RIFIUTA DOCUMENTI
                      </StyledButton>
                    )}

                  </Stack>
                )}

              </Stack>
            )}

          </Stack>
        </Stack>

        {showMotivazione && (
          <Container sx={{ width: '100%', ml: -1, mt: 4 }}>
            <Stack>
              <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '1rem', ml: 1 }}>
                {'MOTIVAZIONE'}
              </Typography>
              <TextareaAutosize
                sx={{ paddingLeft: '10px',  }}
                onChange={handleMotivazioneChange}
                disabled={isPraticaChiusa || isPraticaAdvInCorso || isPraticaAttesaDocumenti || isPraticaAttesaApprovazione || !isGestorePratica}
                value={isPraticaChiusa && (motivazione === null || motivazione === '') ? 'Motivazione non presente' : motivazione}
                placeholder={motivazioneObbligatoria ? 'Motivazione obbligatoria' : 'Motivazione non obbligatoria'}
                minRows={3}
                style={{
/*
                  //margin: '10px',
*/
                  marginLeft: '16px',
                  marginTop: '5px',
                  minWidth: '100%',
                  maxWidth: '100%',
                  maxHeight: '150px',
                }} />
            </Stack>
          </Container>
        )}

        {!isPraticaChiusa &&  (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" marginTop={2} spacing={2}>
            {(showButtonSalva && isGestorePratica) && (
              <StyledButton
                onClick={handleSave}
                sx={{
                  backgroundColor: theme.palette.yellow.dark,
                  borderRadius: '5px 0px 0px 5px',
                  border: 'none',
                }}
              >
                Salva
              </StyledButton>
            )}

            {showButtonChiudi && isGestorePratica && (
              <StyledButton
                onClick={handleClose}
                sx={{
                  backgroundColor: theme.palette.yellow.dark,
                  borderRadius: '5px 0px 0px 5px',
                  border: 'none',
                }}
              >
                Chiudi
              </StyledButton>
            )}
          </Stack>
        )}
      </Stack>
      <CustomModal
        open={save || update}
        loading={loading}
        error={error}
        message={infoMessage}
        onClose={handleModalClose} // Funzione per chiudere il modale

      />
    </Box>
  )
}

export default EsitoMonitoraggio
