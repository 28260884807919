
export const LOCALE_TEXT_ITA = {
    // Root
    noRowsLabel: 'Nessuna riga',
    noResultsOverlayLabel: 'Nessun risultato.',
    errorOverlayDefaultLabel: 'Errore.',

    // Density selector toolbar button text
    toolbarDensity: 'Densità',
    toolbarDensityLabel: 'Densità',
    toolbarDensityCompact: 'Densità',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Comfortable',

    // Columns selector toolbar button text
    toolbarColumns: 'Colonne',
    toolbarColumnsLabel: 'Seleziona colonne',

    // Filters toolbar button text
    toolbarFilters: 'Filtri',
    toolbarFiltersLabel: 'Mostra filtri',
    toolbarFiltersTooltipHide: 'Nascondi filtri',
    toolbarFiltersTooltipShow: 'Mostra filtri',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtri attivi` : `${count} filtro attivo`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Ricerca…',
    toolbarQuickFilterLabel: 'Cerca',
    toolbarQuickFilterDeleteIconLabel: 'Cancella',

    // Export selector toolbar button text
    toolbarExport: 'Esporta',
    toolbarExportLabel: 'Esporta',
    toolbarExportCSV: 'Download come CSV',
    toolbarExportPrint: 'Stampa',
    toolbarExportExcel: 'Download come Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Trova colonna',
    columnsPanelTextFieldPlaceholder: 'Titolo Colonna',
    columnsPanelDragIconLabel: 'Riordina colonna',
    columnsPanelShowAllButton: 'Mostra Tutto',
    columnsPanelHideAllButton: 'Cancella Tutto',

    // Filter panel text
    filterPanelAddFilter: 'Aggiungi Filtro',
    filterPanelDeleteIconLabel: 'Cancella',
    filterPanelLinkOperator: 'Operatore Logico',
    filterPanelOperators: 'Operatore', // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: 'E',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Colonne',
    filterPanelInputLabel: 'Valore',
    filterPanelInputPlaceholder: 'Filtra valore',

    // Filter operators text
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'uguale a',
    filterOperatorStartsWith: 'inizia con',
    filterOperatorEndsWith: 'finisce con',
    filterOperatorIs: 'è',
    filterOperatorNot: 'non è',
    filterOperatorAfter: 'è dopo',
    filterOperatorOnOrAfter: 'è in o dopo',
    filterOperatorBefore: 'è prima',
    filterOperatorOnOrBefore: 'è prima o dopo',
    filterOperatorIsEmpty: 'è vuoto',
    filterOperatorIsNotEmpty: 'non è vuoto',
    filterOperatorIsAnyOf: 'qualsiasi tra',

    // Filter values text
    filterValueAny: 'qualsiasi',
    filterValueTrue: 'vero',
    filterValueFalse: 'falseo',

    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Mostra Colonne',
    columnMenuFilter: 'Filtra',
    columnMenuHideColumn: 'Nascondi',
    columnMenuUnsort: 'Elimina ordine',
    columnMenuSortAsc: 'Ordina ASC',
    columnMenuSortDesc: 'Ordina DESC',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtri attivi` : `${count} filtro attivo`,
    columnHeaderFiltersLabel: 'Mostra filtri',
    columnHeaderSortIconLabel: 'Ordina',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
            ? `${count.toLocaleString()} righe selezionate`
            : `${count.toLocaleString()} righe selezionate`,

    // Total row amount footer text
    footerTotalRows: 'Righe totali:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} di ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Selezione Checkbox',
    checkboxSelectionSelectAllRows: 'Seleziona tutte le righe',
    checkboxSelectionUnselectAllRows: 'Deseleziona tutte le righe',
    checkboxSelectionSelectRow: 'Seleziona riga',
    checkboxSelectionUnselectRow: 'Deseleziona riga',

    // Boolean cell text
    booleanCellTrueLabel: 'sì',
    booleanCellFalseLabel: 'no',

    // Actions cell more text
    actionsCellMore: 'più',

    // Column pinning text
    pinToLeft: 'Fissa a sx',
    pinToRight: 'Fissa a dx',
    unpin: 'rimuovi',

    // Tree Data
    treeDataGroupingHeaderName: 'Gruppo',
    treeDataExpand: 'mostra dettagli',
    treeDataCollapse: 'nascondi dettagli',

    // Grouping columns
    groupingColumnHeaderName: 'Gruppo',
    groupColumn: (name) => `Raggruppa per ${name}`,
    unGroupColumn: (name) => `Cancella raggruppamento per ${name}`,

    // Master/detail
    detailPanelToggle: 'Tasto pannello dettagli',
    expandDetailPanel: 'Espandi',
    collapseDetailPanel: 'Chiudi',

    // Used core components translation keys
    MuiTablePagination: {},

    // Row reordering text
    rowReorderingHeaderName: 'Riordina righe',

    // Aggregation
    aggregationMenuItemHeader: 'Aggrega',
    aggregationFunctionLabelSum: 'somma',
    aggregationFunctionLabelAvg: 'media',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'dimensioni',
};