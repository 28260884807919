
import { customAxios } from './customAxios'

export function uploadDocumentMonitoraggio(formData, idMonitoraggio, idTipologia) {
  const config = {
      headers: {
          'AuthToken': JSON.parse(localStorage.getItem('token')),
          'Content-Type': 'multipart/form-data',
      },
      params: {
          idMonitoraggio,
          idTipologia,
      },
  };

  return customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/adv/monitoraggio/documenti-opzionali/file", formData, config);
}