import { customAxios } from "./customAxios"
export function search(key, statoRichiesta) {
    const config = {
        headers: {
            Authtoken: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json'
        }
    }

    const data = JSON.stringify({
        "key": key,
        "statoRichiesta": statoRichiesta
    })
    const searchRequest = customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/"+process.env.REACT_APP_PATH_NEW_PREFIX+"/search", data, config)
    return searchRequest
}