import React, { useMemo } from 'react'

export const isValidUser = acceptedFunction =>
  JSON.parse(localStorage.getItem('functions')).includes(acceptedFunction)

export default function OnlyWithFunction({
  acceptedFunction,
  acceptedSecondFunction,
  fallbackComponent = <div />,
  children,
}) {
  const functions = JSON.parse(localStorage.getItem('functions'))
  // debugger
  // console.table(functions)
  const canAccess = useMemo(
    () => functions && functions.includes(acceptedFunction) || functions && functions.includes(acceptedSecondFunction)
  )
  // console.log('nuova funzione :',acceptedSecondFunction)
  // console.log('boolean per nuova funzione :',canAccess)


  return (
    <div>
      {canAccess && children}
      {!canAccess && fallbackComponent}
    </div>
  )
}
