import React, { useState,useRef } from 'react'
import {
    Box,
    Avatar,
    Badge
} from '@mui/material'
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import RichiesteSupportoContainer from '../pages/components/RichiesteSupportoContainer';
import './Footer.css'
import { useQuery } from '@tanstack/react-query';
import { getRichiesteSupporto } from '../services/getRichiesteSupporto'
import { setRichiestaSupportoAsViewed } from '../services/setRichiestaSupportoAsViewed'
import { useEffect } from 'react';

const Footer = ({idNodoPratica}) => {

  const [slideDownValue, setSlideDownValue] = useState(false)
  const [showRichiesteSupporto, setShowRichiesteSupporto] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0)
  const [userLogged, setUserLogged] = useState(JSON.parse(localStorage.getItem('user')))
  const [rispostaSupporto, setRispostaSupporto] = useState(false)

  const {
    data: listRichiesteSupporto,
    isFetched: isListRichiesteSupportoFetched,
        refetch: refetchListRichiestaSupporto
    } =  useQuery(['richiesteSupporto',idNodoPratica], () => getRichiesteSupporto(idNodoPratica))

  useEffect(() => {
    
    if(listRichiesteSupporto && listRichiesteSupporto.length > 0){
      const richiesteSupportoNotViewed = listRichiesteSupporto
        .filter(richiesta => richiesta.notificationStatus)
        .map(richiesta => richiesta.notificationStatus[userLogged])
            .filter(userValueNotification => userValueNotification === false);

        setNotificationCount(richiesteSupportoNotViewed.length);
    }
    
  }, [listRichiesteSupporto, userLogged])

  const openRichiesteSupporto = (rispostaSupporto) => {
    setSlideDownValue(true)
    setRispostaSupporto(rispostaSupporto)
    setTimeout(function () {
      setShowRichiesteSupporto(true)
    }, 250)

          if(notificationCount > 0){
            setRichiestaSupportoAsViewed(idNodoPratica, userLogged);
            setNotificationCount(0);
    }
  }
  const closeRichiesteSupporto = () => {
        setSlideDownValue(false);
    setShowRichiesteSupporto(false)
  }
    return(
    <>
        {isListRichiesteSupportoFetched && listRichiesteSupporto && listRichiesteSupporto.length > 0 &&
          <Box
                className={'footer ' + (slideDownValue === true ? 'animationSlideDownAndUp' : '')} 
                sx={{alignSelf: 'end', marginRight: '3%', position: 'fixed', bottom: '0px', right: '0px'}}
          >

                {showRichiesteSupporto && 
              <RichiesteSupportoContainer
                showRichiesteSupporto={showRichiesteSupporto}
                closeRichiesteSupporto={closeRichiesteSupporto}
                idNodoPratica={idNodoPratica}
                listRichiesteSupporto={listRichiesteSupporto}
                rispostaSupporto={rispostaSupporto}
              ></RichiesteSupportoContainer>
                }

                {!showRichiesteSupporto && 
                <Badge badgeContent={notificationCount ? notificationCount : 0} invisible={!notificationCount || notificationCount <= 0} sx={{ marginY: '2em'}} >
                <Avatar
                  onClick={openRichiesteSupporto}
                        sx={{ margin: 'auto', backgroundColor: '#0073B1', textAlign:'center', padding:'20px', width:'65px', height: '65px' }}
                >
                        
                        <ContactSupportIcon sx={{fontSize:'xxx-large'}}></ContactSupportIcon>
                </Avatar>
              </Badge>
                }   
                
          </Box>
        }
    </>
  )
}

export default Footer
