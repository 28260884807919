import { customAxios } from './customAxios'

export function getStatisticheMonitoraggio() {
  const config = {
    headers: {
      Accept: "*/*",
      'AuthToken': JSON.parse(localStorage.getItem('token'))
    },
  }

  return customAxios.get(`${process.env.REACT_APP_BACKEND_HOST}/${process.env.REACT_APP_PATH_NEW_PREFIX}/monitoraggio/get-statistiche-monitoraggio`, config)
    .then(response => {
      console.log(response.data)
      return response.data;
    })
    .catch(error => {
      console.error("Errore nella chiamata dell'API:", error);
      throw error;
    });
}