import { customAxios } from './customAxios'


export function changePassword(formValues){
    const config = {
        headers: {
            "AuthToken": JSON.parse(localStorage.getItem('token')),
        }
    }
    const data = {
        "oldpassword": formValues.oldpassword,
        "newpassword": formValues.newpassword,
        "confirmpassword": formValues.confirmpassword,
    }



const changePwdRequest =
        customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/"+process.env.REACT_APP_PATH_NEW_PREFIX+"/change-pwd", data, config)
    return changePwdRequest
}